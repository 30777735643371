import {connect} from "react-redux";
import JobUpdate from "~/pages/JobUpdate";
import {fetchJob, createOrUpdateJob} from "~/actions/jobs";

function mapStateToProps(state) {
    return {
        //
    };
}

const mapDispatchToProps = {
    fetchJob,
    createOrUpdateJob,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobUpdate);
