import objectPath from "object-path";

/**
 * Returns the value of a configuration item.
 *
 * If no default value is specified,
 * the item is considered required and a value MUST be set, or an Error is thrown.
 *
 * The first element in the path must be the name of the JSON configuration file
 * from the config/ directory, without the .json extension. The remaining elements
 * describe the path to the configuration item within the JSON. If only the first
 * element is provided, the entire configuration JSON will be returned.
 *
 * @param path         path to the configuration item, starting with the name of the configuration file
 * @param defaultValue default value for the item, if none is provided the item is required
 * @returns {*}
 */
export default function config(path, defaultValue) {
    const {configName, valuePath} = splitPath(path);
    const fileName = `./${configName}.json`;

    if (!CONFIG_DATA[fileName]) {
        console.trace(
            `Config "${configName}" is not available, check that the first path of the path is the config file name and that the config file exists.`
        );
        return defaultValue;
    }

    let value = CONFIG_DATA[fileName];

    if (valuePath !== undefined) {
        if (arguments.length < 2 && !objectPath.has(value, valuePath)) {
            throw new Error(`Config value "${path}" is required, but not set!`);
        }

        value = objectPath.get(value, valuePath, defaultValue);
    }

    return value;
}

config.set = function(path, value) {
    const {configName, valuePath} = splitPath(path);
    const fileName = `./${configName}.json`;

    if (!CONFIG_DATA[fileName]) {
        console.trace(
            `Config "${configName}" is not available, check that the first path of the path is the config file name and that the config file exists.`
        );
        return;
    }

    if (valuePath === undefined) {
        console.trace("Must provide a setting path");
        return;
    }

    objectPath.set(CONFIG_DATA[fileName], valuePath, value);
};

function splitPath(path) {
    const firstPeriodIndex = path.indexOf(".");

    if (firstPeriodIndex >= 0) {
        return {
            configName: path.substring(0, firstPeriodIndex),
            valuePath: path.substring(firstPeriodIndex + 1),
        };
    } else {
        return {configName: path, valuePath: undefined};
    }
}

// Load all config files into CONFIG_DATA
const context = require.context("~/config", true, /\.json$/);
const CONFIG_DATA = {};

context.keys().forEach(filename => {
    CONFIG_DATA[filename] = context(filename);
});
