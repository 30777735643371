export const MatchedTo = {
    UNKNOWN: "UNKNOWN",
    NOTHING: "NOTHING",
    ASPECT: "ASPECT",
    JOB_TITLE: "JOB_TITLE",
    JOB_TEXT: "JOB_TEXT",
    CV_TEXT: "CV_TEXT",
    WORK_EXPERIENCES: "WORK_EXPERIENCES",
    EDUCATIONS: "EDUCATIONS",
};

export const JOB_FUNCTIONS_GREEN = [MatchedTo.ASPECT, MatchedTo.JOB_TITLE];
export const HARD_SKILLS_GREEN = [MatchedTo.ASPECT];

export function isGreenMatch(match, matchRequest) {
    const jobFunctionsMatchedTo = getAspectMatchedTo(match, "jobFunctions");

    if (!JOB_FUNCTIONS_GREEN.includes(jobFunctionsMatchedTo)) {
        return false;
    }

    if (
        !match.details ||
        !match.details.hardSkills ||
        !Array.isArray(match.details.hardSkills) ||
        !matchRequest ||
        !matchRequest.matchProfile ||
        !matchRequest.matchProfile.aspects ||
        !matchRequest.matchProfile.aspects.hardSkills
    ) {
        return true;
    }

    for (const hardSkill of matchRequest.matchProfile.aspects.hardSkills.value) {
        if (!hardSkill.required) {
            continue;
        }

        const value = match.details.hardSkills.find(v => v.label === hardSkill.label);

        if (
            !value ||
            !value.found ||
            !HARD_SKILLS_GREEN.includes(getAspectValueMatchedTo(value, "hardSkills"))
        ) {
            return false;
        }
    }

    return true;
}

export function getAspectMatchedTo(match, aspect) {
    if (!match.details) {
        return MatchedTo.NOTHING;
    }

    if (!match.details[aspect]) {
        return MatchedTo.NOTHING;
    }

    if (!Array.isArray(match.details[aspect])) {
        return MatchedTo.NOTHING;
    }

    let matched = false;
    let result = undefined;

    for (const value of match.details[aspect]) {
        if (value.scoreType !== "FORWARD" || !value.found || !value.info) {
            continue;
        }

        matched = true;
        result = getAspectValueMatchedTo(value, aspect);

        if (JOB_FUNCTIONS_GREEN.includes(result)) {
            return result;
        }
    }

    if (!matched) {
        return MatchedTo.NOTHING;
    } else {
        return result || MatchedTo.UNKNOWN;
    }
}

export function getAspectValueMatchedTo(value, aspect) {
    if (value.scoreType !== "FORWARD") {
        return undefined;
    }

    if (!value.found) {
        return MatchedTo.NOTHING;
    }

    if (!value.info) {
        console.warn("[getAspectValueMatchedTo] Empty info value");
        return MatchedTo.UNKNOWN;
    }

    if (value.info.startsWith(aspect)) {
        return MatchedTo.ASPECT;
    } else if (value.info.startsWith("jobTitle")) {
        return MatchedTo.JOB_TITLE;
    } else if (value.info.startsWith("jobText")) {
        return MatchedTo.JOB_TEXT;
    } else if (value.info.startsWith("cvText")) {
        return MatchedTo.CV_TEXT;
    } else if (value.info.startsWith("workExperiences")) {
        return MatchedTo.WORK_EXPERIENCES;
    } else if (value.info.startsWith("educations")) {
        return MatchedTo.EDUCATIONS;
    } else {
        console.warn("[getAspectValueMatchedTo] Unsupported info value: " + value.info);
        return MatchedTo.UNKNOWN;
    }
}
