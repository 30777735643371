import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ReactResizeDetector from "react-resize-detector";
import config from "~/config";
import FilterValuePropType from "~/prop-types/filter-value";
import TermFilter from "./TermFilter";
import MultipleTermFilter from "./MultipleTermFilter";
import NumberComparisonFilter from "./NumberComparisonFilter";
import AutocompleteTermFilter from "./AutocompleteTermFilter";
import styles from "./styles.module.scss";

export default class FilterEditor extends React.PureComponent {
    static propTypes = {
        value: PropTypes.objectOf(FilterValuePropType).isRequired,
        availableFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            columns: 1,
        };
    }

    render() {
        const {columns} = this.state;

        return (
            <div className={c(styles.editor, `columns-${columns}`)}>
                {this.renderFilters()}
                <ReactResizeDetector handleWidth onResize={this.handleResize} />
            </div>
        );
    }

    renderFilters() {
        const {availableFilters} = this.props;

        return availableFilters.map(filterName => {
            const Component = FILTER_COMPONENTS[config(`filters.${filterName}.type`)];
            const value = this.findFilterValue(filterName);

            return (
                <div key={filterName} className={styles.filter}>
                    <Component
                        value={value}
                        filterName={filterName}
                        onChange={this.handleFilterValueChange}
                    />
                </div>
            );
        });
    }

    findFilterValue(filterName) {
        const {value} = this.props;
        return value[filterName] || defaultValueForFilter(filterName);
    }

    handleFilterValueChange = (key, nextFilterValue) => {
        const {value, onChange} = this.props;

        onChange({
            ...value,
            [key]: nextFilterValue,
        });
    };

    handleResize = width => {
        const columns = Math.min(Math.max(Math.floor(width / 400), 1), 6);
        this.setState(state => {
            if (state.columns !== columns) {
                return {columns};
            } else {
                return null;
            }
        });
    };
}

function defaultValueForFilter(filterName) {
    const type = config(`filters.${filterName}.type`);

    switch (type) {
        case "multipleTerm":
            return {
                enabled: false,
                value: [],
            };

        case "numberComparison":
            return {
                enabled: false,
                value: {
                    comparison: "lte",
                    comparand: null,
                },
            };

        default:
            return {
                enabled: false,
                value: null,
            };
    }
}

const FILTER_COMPONENTS = {
    term: TermFilter,
    multipleTerm: MultipleTermFilter,
    autocompleteTerm: AutocompleteTermFilter,
    numberComparison: NumberComparisonFilter,
};
