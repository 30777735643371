import config from "~/config";

/**
 * Parses an comma-separated input string which can be either a blacklist or a whitelist.
 *
 * The following cases are supported:
 *     - "*", all items are valid
 *     - "item1,item2,item3", whitelist of valid items
 *     - "!item1,item2,item3", blacklist of invalid items
 *
 * Only strings are currently supported.
 */
export class BlackWhiteList {
    static cache = {};

    constructor(input) {
        if (input === undefined || input === "" || input === "*") {
            this.exclusive = true;
            this.providedItems = [];
        } else if (!input.startsWith("!")) {
            this.exclusive = false;
            this.providedItems = input.split(",").map(a => a.trim());
        } else {
            this.exclusive = true;
            this.providedItems = input
                .substring(1)
                .split(",")
                .map(a => a.trim());
        }
    }

    static fromConfig(configPath) {
        if (!BlackWhiteList.cache[configPath]) {
            const input = config(configPath, "*");
            BlackWhiteList.cache[configPath] = new BlackWhiteList(input);
        }

        return BlackWhiteList.cache[configPath];
    }

    /**
     * Returns the result of filtering the provided list of items by this black/whitelist.
     *
     * @param items an array of strings
     * @return filtered array of strings
     */
    apply(items) {
        if (this.exclusive) {
            return items.filter(item => !this.providedItems.includes(item));
        } else {
            return items.filter(item => this.providedItems.includes(item));
        }
    }

    allows(item) {
        if (this.exclusive) {
            return !this.providedItems.includes(item)
        } else {
            return this.providedItems.includes(item)
        }
    }
}
