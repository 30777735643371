import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import emptyCandidate from "~/data/empty-candidate.json";
import {importCandidate} from "~/util/candidate";
import CandidateEditor from "~/components/CandidateEditor";

/**
 * This class expects to be remounted when the ID parameter in match changes!
 * It only loads data once.
 */
export default class CandidateUpdate extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        fetchCandidate: PropTypes.func.isRequired,
        createOrUpdateCandidate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            candidate: importCandidate(emptyCandidate),
            isLoaded: false,
            isUpdating: false,
        };
    }

    componentDidMount() {
        const {fetchCandidate, match} = this.props;

        fetchCandidate(match.params.id).then(candidate => {
            this.setState({candidate, isLoaded: true});
        }).catch(e => {
            console.log(e);
            this.setState({isLoaded: true});
        });
    }

    render() {
        const {candidate} = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col>
                            <CandidateEditor
                                candidate={candidate}
                                isEditing={true}
                                showMatchProfileEditor={true}
                                onChange={this.handleCandidateChange}
                            />
                            {this.renderButtons()}
                        </Col>
                    </Row>
                </Container>
                {this.renderModals()}
            </React.Fragment>
        );
    }

    renderButtons() {
        return (
            <div>
                <button type="button" onClick={this.handleSubmit}>
                    Update
                </button>
            </div>
        );
    }

    renderModals() {
        const {isLoaded, isUpdating} = this.state;

        if (!isLoaded) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Loading candidate...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please wait while the candidate is being loaded.</p>
                    </Modal.Body>
                </Modal>
            );
        } else if (isUpdating) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Updating candidate...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please wait while the candidate is being updated.</p>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }

    handleCandidateChange = candidate => {
        this.setState({candidate});
    };

    handleSubmit = () => {
        const {createOrUpdateCandidate, match} = this.props;
        const {candidate} = this.state;

        this.setState({isUpdating: true});

        createOrUpdateCandidate(match.params.id, candidate)
            .then(() => {
                this.setState({isUpdating: false});
            })
            .catch(e => {
                console.error(e);
                this.setState({isUpdating: false});
            });
    };
}
