import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import c from "classnames";
import {FormattedMessage, injectIntl} from "react-intl";
import ListGroup from "react-bootstrap/ListGroup";
import NumberInput from "~/components/NumberInput";
import styles from "./styles.module.scss";

class Range extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.shape({
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            from: PropTypes.number,
            to: PropTypes.number,
        }).isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    render() {
        const {isEditing} = this.props;

        return (
            <ListGroup.Item className={styles.listItem}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    {isEditing ? this.renderValueEdit() : this.renderValueDisplay()}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderValueDisplay() {
        const {value} = this.props;

        return (
            <span className={styles.description}>
                {value.from} to {value.to}
            </span>
        );
    }

    renderValueEdit() {
        const {intl, value} = this.props;

        return (
            <React.Fragment>
                <NumberInput
                    value={value.from}
                    placeholder={intl.formatMessage({id: "placeholder.fromMinimum"})}
                    onChange={this.handleFromChange}
                    className={c(styles.numberInput, "left")}
                />
                <FormattedMessage id="range.to"/>
                <NumberInput
                    value={value.to}
                    placeholder={intl.formatMessage({id: "placeholder.toMaximum"})}
                    onChange={this.handleToChange}
                    className={c(styles.numberInput, "right")}
                />
            </React.Fragment>
        );
    }

    handleFromChange = from => {
        const {value, onChange} = this.props;
        onChange({...value, from});
    };

    handleToChange = to => {
        const {value, onChange} = this.props;
        onChange({...value, to});
    };

    normalizeValue = () => {
        const {value, onChange} = this.props;

        if (value.to === undefined || value.from === undefined) {
            // Do nothing
        } if (value.to < value.from) {
            onChange({...value, to: value.from});
        }
    };
}

export default injectIntl(Range, {forwardRef: true});