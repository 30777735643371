import objectPath from "object-path";
import config from "~/config";
import Api from "~/api";

const AGGREGATION_SIZE = 10000;
const CACHE = {};

export function getValuesForFilter(filterName) {
    if (CACHE[filterName] === undefined) {
        CACHE[filterName] = loadValuesForFilter(filterName);
    }

    return CACHE[filterName];
}

function loadValuesForFilter(filterName) {
    const configuredValues = config(`filters.${filterName}.values`, undefined);

    if (configuredValues === undefined) {
        return Promise.resolve([]);
    } else if (Array.isArray(configuredValues)) {
        return Promise.resolve(configuredValues);
    } else if (typeof configuredValues === "string" || configuredValues instanceof String) {
        return loadValuesFromData(configuredValues);
    } else if (configuredValues.endpoint !== undefined) {
        return loadValuesFromApi(configuredValues);
    }
}

function loadValuesFromData(dataFilename) {
    return import(`~/data/autocomplete/${dataFilename}.json`).then(module => module.values);
}

function loadValuesFromApi(config) {
    return Api.post(config.endpoint, {
        size: 0,
        aggregations: {
            default: {
                terms: {
                    field: config.field,
                    size: AGGREGATION_SIZE,
                },
            },
        },
    }).then(({response}) => {
        return transformBuckets(response)
    }).catch(error => {
        console.error(error)
        return []
    });
}

function transformBuckets(response) {
    const buckets = objectPath.get(response, "aggregations.default.buckets");

    if (buckets === undefined) {
        console.error("No buckets in response")
        return []
    }

    return buckets.map(item => ({
        value: item.key,
        label: item.key,
        count: item.doc_count,
    }))
}
