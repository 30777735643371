import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";
import MatchComparisonTable from "~/components/MatchComparisonTable";
import styles from "./styles.module.scss";

export default class MatchComparisonDialog extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array, // TODO: More specific
        titleLabel: PropTypes.string.isRequired,
        reverseLabel: PropTypes.string.isRequired,
        getMatchDisplayValue: PropTypes.func.isRequired,
        extraPropertiesBlackWhiteList: PropTypes.object.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    render() {
        const {matches, titleLabel, reverseLabel, getMatchDisplayValue, extraPropertiesBlackWhiteList, onHide} = this.props;

        return (
            <Modal size="xl" show={true} centered onHide={onHide} scrollable={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <FormattedMessage id={titleLabel} values={{count: matches.length}} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.scrollContainer}>
                        <MatchComparisonTable
                            matches={matches}
                            reverseLabel={reverseLabel}
                            getMatchDisplayValue={getMatchDisplayValue}
                            extraPropertiesBlackWhiteList={extraPropertiesBlackWhiteList}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
