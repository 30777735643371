import uuid from "uuid/v4";
import abortableCall from "./abortable-call";

export default class Client {
    activePromises = {};

    call(method, endpoint, queryParameters = {}, body = null, headers = {}, dontDecode = false, responseType = null) {
        const promise = abortableCall(method, endpoint, queryParameters, body, headers, dontDecode, responseType);
        this.addActivePromise(promise);
        return promise;
    }

    get(endpoint, queryParameters = {}, headers = {}, dontDecode = false) {
        return this.call("get", endpoint, queryParameters, undefined, headers, dontDecode);
    }

    post(endpoint, body = null, queryParameters = {}, headers = {}, dontDecode = false, responseType = null) {
        return this.call("post", endpoint, queryParameters, body, headers, dontDecode, responseType);
    }

    put(endpoint, body = null, headers = {}, dontDecode = false) {
        return this.call("put", endpoint, undefined, body, headers, dontDecode);
    }

    delete(endpoint, headers = {}, dontDecode = false) {
        return this.call("delete", endpoint, undefined, undefined, headers, dontDecode);
    }

    addActivePromise(promise) {
        const id = this.uniqueId();
        this.activePromises[id] = promise;

        promise.then(() => {
            delete this.activePromises[id];
        }).catch(() => {
            delete this.activePromises[id];
        });
    }

    abortAllCalls() {
        const abortedPromises = [];

        for (const id in this.activePromises) {
            if (this.activePromises.hasOwnProperty(id)) {
                const promise = this.activePromises[id];
                promise.abort();
                abortedPromises.push(promise);
            }
        }

        return Promise.all(
            // Transform rejections into resolves so that Promise.all() waits for all
            // abortions to complete instead of rejecting on the first rejection.
            // This way calling code can properly wait for all abortions to finish.
            abortedPromises.map(promise => promise.catch(error => error))
        );
    }

    uniqueId() {
        for (;;) {
            const id = uuid();
            if (this.activePromises[id] === undefined) return id;
        }
    }
}
