import PropTypes from "prop-types";

export default PropTypes.shape({
    id: PropTypes.string.isRequired,
    field: PropTypes.string,
    degree: PropTypes.string,
    institute: PropTypes.string,
    info: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
});
