import {
    INITIALIZE_RESULT_SET,
    REQUESTING_PAGE,
    REQUEST_ERROR,
    RECEIVE_PAGE,
    INVALIDATE_ALL_PAGES,
} from "~/actions/match-results";

const INITIAL_STATE = {
    resultSets: {},
};

const EMPTY_PAGE_DATA = {
    ids: [],
    promise: null,
    fetching: false,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case INITIALIZE_RESULT_SET:
            return initializeResultSet(
                state,
                action.resultSetName,
                action.createPromise,
                action.convertResponse
            );

        case REQUESTING_PAGE:
            return requestingPage(state, action.resultSetName, action.page, action.promise);

        case REQUEST_ERROR:
            return requestError(state, action.resultSetName, action.page);

        case RECEIVE_PAGE:
            return receivePage(
                state,
                action.resultSetName,
                action.page,
                action.matches,
                action.ids,
                action.count,
                action.isNewQuery
            );

        case INVALIDATE_ALL_PAGES:
            return invalidateAllPages(state, action.resultSetName);

        default:
            return state;
    }
}

function initializeResultSet(state, resultSetName, createPromise, convertResponse) {
    if (state.resultSets[resultSetName] !== undefined) {
        return state;
    }

    return {
        ...state,
        resultSets: {
            ...state.resultSets,
            [resultSetName]: createEmptyResultSet(createPromise, convertResponse),
        },
    };
}

function requestingPage(state, resultSetName, page, promise) {
    return updatePageData(state, resultSetName, page, {
        promise,
        fetching: true,
    });
}

function requestError(state, resultSetName, page) {
    return updatePageData(state, resultSetName, page, {
        promise: null,
        fetching: false,
    });
}

function receivePage(state, resultSetName, page, matches, ids, count, isNewQuery) {
    let nextState = state;

    // If it's a new query we invalidate all other pages
    if (isNewQuery) {
        nextState = {
            ...state,
            resultSets: {
                ...state.resultSets,
                [resultSetName]: {
                    ...state.resultSets[resultSetName],
                    pages: {
                        [page]: state.resultSets[resultSetName].pages[page],
                    },
                },
            },
        };
    }

    nextState = updatePageData(nextState, resultSetName, page, {
        ids,
        fetching: false,
    });

    return {
        ...nextState,
        resultSets: {
            ...nextState.resultSets,
            [resultSetName]: {
                ...nextState.resultSets[resultSetName],
                matches: {
                    ...nextState.resultSets[resultSetName].matches,
                    ...matches,
                },
                totalCount: count,
            },
        },
    };
}

function invalidateAllPages(state, resultSetName) {
    return {
        ...state,
        resultSets: {
            ...state.resultSets,
            [resultSetName]: {
                ...state.resultSets[resultSetName],
                pages: {},
            },
        },
    };
}

function updatePageData(state, resultSetName, page, pageData) {
    return {
        ...state,
        resultSets: {
            ...state.resultSets,
            [resultSetName]: {
                ...state.resultSets[resultSetName],
                pages: {
                    ...state.resultSets[resultSetName].pages,
                    [page]: {
                        ...EMPTY_PAGE_DATA,
                        ...state.resultSets[resultSetName].pages[page],
                        ...pageData,
                    },
                },
            },
        },
    };
}

function createEmptyResultSet(createPromise, convertResponse) {
    return {
        createPromise,
        convertResponse,
        matches: {},
        pages: {},
        totalCount: null, // null indicates never searched
    };
}
