import React from "react";
import PropTypes from "prop-types";
import equal from "deep-equal";
import BasicPageLayout from "~/components/BasicPageLayout";
import MatchProfilePropType from "~/prop-types/match-profile";
import MatchRequestPropType from "~/prop-types/match-request";
import KeywordSearch from "./KeywordSearch";
import MatchProfile from "./MatchProfile";
import Results from "./Results";
import styles from "./styles.module.scss";
import config from "~/config";
import Alert from "react-bootstrap/Alert";
import {FormattedMessage} from "react-intl";

export default class HorizontalMatchPageLayout extends React.PureComponent {
    static propTypes = {
        children: PropTypes.any,
        additionalMenuContent: PropTypes.node,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        indexName: PropTypes.string.isRequired,
        keywordQuery: PropTypes.string.isRequired,
        matchProfile: MatchProfilePropType.isRequired,
        lastMatchRequest: MatchRequestPropType,
        availableFilters: PropTypes.array.isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        isMatching: PropTypes.bool.isRequired,
        resultCount: PropTypes.number,
        sortMode: PropTypes.string,
        sortModeGroup: PropTypes.string,
        showKeywordSearch: PropTypes.bool.isRequired,
        showSuggestions: PropTypes.bool.isRequired,
        showResetButton: PropTypes.bool.isRequired,
        profileLabel: PropTypes.string.isRequired,
        resultsLabel: PropTypes.string.isRequired,
        renderExtraResultsContent: PropTypes.func,
        onKeywordQueryChange: PropTypes.func,
        onMatchProfileChange: PropTypes.func.isRequired,
        onFiltersChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        onSortModeChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onMatchingStrategyChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        keywordQuery: "",
        showKeywordSearch: false,
        showResetButton: false,
        showSuggestions: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            tabs: {
                [Tabs.MATCH_PROFILE]: true,
                [Tabs.RESULTS]: false,
            },
        };

        this.matchProfileEditor = React.createRef();
    }

    openMatchProfileTab() {
        this.setState(state => ({
            tabs: {
                ...state.tabs,
                [Tabs.MATCH_PROFILE]: true,
                [Tabs.RESULTS]: false,
            },
        }));
    }

    toggleMatchProfileTab(displayed) {
        this.setState(state => ({
            tabs: {
                ...state.tabs,
                [Tabs.MATCH_PROFILE]: displayed,
                [Tabs.RESULTS]: !displayed,
            },
        }));
    }

    openResultsTab() {
        this.setState(state => ({
            tabs: {
                ...state.tabs,
                [Tabs.MATCH_PROFILE]: false,
                [Tabs.RESULTS]: true,
            },
        }));
    }

    toggleResultsTab(displayed) {
        this.setState(state => ({
            tabs: {
                ...state.tabs,
                [Tabs.MATCH_PROFILE]: !displayed,
                [Tabs.RESULTS]: displayed,
            },
        }));
    }

    resetMatchProfileEditor() {
        this.matchProfileEditor.current.reset();
    }

    render() {
        const {
            children,
            showKeywordSearch,
            additionalMenuContent,
            keywordQuery,
            indexName,
            language,
            matchingStrategy,
            matchProfile,
            availableFilters,
            filters,
            isLoading,
            isMatching,
            resultCount,
            profileLabel,
            resultsLabel,
            sortMode,
            sortModeGroup,
            showResetButton,
            showSuggestions,
            renderExtraResultsContent,
            onKeywordQueryChange,
            onMatchProfileChange,
            onMatch,
            onClear,
            onReset,
            onFiltersChange,
            onSortModeChange,
            onLanguageChange,
            onMatchingStrategyChange,
        } = this.props;

        const {tabs} = this.state;

        return (
            <BasicPageLayout additionalMenuContent={additionalMenuContent} isLoading={isLoading}>
                <div className={styles.page}>
                    {config("ui.displayNotFullyIndexed", false) && (
                        <Alert variant="warning"><FormattedMessage id="notFullyIndexedWarning"/></Alert>
                    )}
                    {showKeywordSearch && (
                        <KeywordSearch
                            keywordQuery={keywordQuery}
                            withMatchBlock={true}
                            language={language}
                            matchingStrategy={matchingStrategy}
                            matchRequestHasChanged={this.matchRequestHasChanged()}
                            onKeywordQueryChange={onKeywordQueryChange}
                            onMatch={onMatch}
                            onLanguageChange={onLanguageChange}
                            onMatchingStrategyChange={onMatchingStrategyChange}
                        />
                    )}
                    <MatchProfile
                        matchProfile={matchProfile}
                        availableFilters={availableFilters}
                        filters={filters}
                        profileLabel={profileLabel}
                        indexName={indexName}
                        language={language}
                        matchingStrategy={matchingStrategy}
                        matchRequestHasChanged={this.matchRequestHasChanged()}
                        expanded={tabs[Tabs.MATCH_PROFILE]}
                        withMatchBlock={!showKeywordSearch}
                        showResetButton={showResetButton}
                        showSuggestions={showSuggestions}
                        onToggleExpanded={this.handleToggleMatchProfileExpanded}
                        onMatchProfileChange={onMatchProfileChange}
                        onFiltersChange={onFiltersChange}
                        onMatch={onMatch}
                        onClear={onClear}
                        onReset={onReset}
                        onLanguageChange={onLanguageChange}
                        onMatchingStrategyChange={onMatchingStrategyChange}
                        matchProfileEditorRef={this.matchProfileEditor}
                    />
                    <Results
                        resultsLabel={resultsLabel}
                        isMatching={isMatching}
                        resultCount={resultCount}
                        expanded={tabs[Tabs.RESULTS]}
                        content={children}
                        sortMode={sortMode}
                        sortModeGroup={sortModeGroup}
                        renderExtraContent={renderExtraResultsContent}
                        onToggleExpanded={this.handleToggleResultsExpanded}
                        onSortModeChange={onSortModeChange}
                    />
                </div>
            </BasicPageLayout>
        );
    }

    matchRequestHasChanged() {
        const {matchProfile, filters, lastMatchRequest} = this.props;

        if (!lastMatchRequest) {
            return true;
        }

        return !equal(
            {
                matchProfile,
                filters,
            },
            {
                matchProfile: lastMatchRequest.matchProfile,
                filters: lastMatchRequest.filters,
            },
            {
                strict: true,
            }
        );
    }

    handleToggleMatchProfileExpanded = () => {
        this.setState(state => {
            const displayed = !state.tabs[Tabs.MATCH_PROFILE];

            return {
                tabs: {
                    ...state.tabs,
                    [Tabs.MATCH_PROFILE]: displayed,
                    [Tabs.RESULTS]: !displayed,
                },
            }
        });
    };

    handleToggleResultsExpanded = () => {
        this.setState(state => {
            const displayed = !state.tabs[Tabs.RESULTS];

            return {
                tabs: {
                    ...state.tabs,
                    [Tabs.MATCH_PROFILE]: !displayed,
                    [Tabs.RESULTS]: displayed,
                },
            }
        });
    };
}

const Tabs = {
    MATCH_PROFILE: "matchProfile",
    RESULTS: "results",
};
