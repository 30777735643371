let extractedMetadata = false;
let jwtMetadataContents;

export function jwtMetadata() {
    if (!extractedMetadata) {
        extractMetadata();
    }

    return jwtMetadataContents;
}

function extractMetadata() {
    extractedMetadata = true;
    const metatags = document.getElementsByTagName("meta");

    for (const metatag of metatags) {
        if (metatag.getAttribute("name") === "tmb:jwt") {
            if (metatag.getAttribute("content")) {
                jwtMetadataContents = JSON.parse(metatag.getAttribute("content"));
            }

            break;
        }
    }
}
