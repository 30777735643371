import React from "react";
import PropTypes from "prop-types";
import JobPropType from "~/prop-types/job";
import JobEditor from "~/components/JobEditor";
import SimplePage from "~/components/SimplePage";
import {siblingPath} from "~/util/paths";
import JobToCandidatesBreadcrumbs from "~/containers/components/JobToCandidatesBreadcrumbs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import config from "~/config";

export default class JobToCandidatesVerify extends React.PureComponent {
    static propTypes = {
        job: JobPropType.isRequired,
        isParsing: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        match: PropTypes.object.isRequired,
        updateJob: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        setFurthestStep: PropTypes.func.isRequired,
    };

    render() {
        const {job, updateJob, isParsing, language} = this.props;

        return (
            <SimplePage
                isLoading={isParsing}
                fullHeight={true}
                subMenu={() => <JobToCandidatesBreadcrumbs />}
            >
                <Row className="h-100">
                    <Col className={styles.mainColumn}>
                        <JobEditor
                            job={job}
                            isEditing={true}
                            language={language}
                            index={config("features.jobToCandidates.candidateIndex")}
                            className={styles.jobEditor}
                            onChange={updateJob}
                        />
                        <div className={styles.buttons}>
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={this.handleMatchClick}
                            >
                                <FormattedMessage id="action.match" />
                            </button>
                        </div>
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    handleMatchClick = () => {
        const {requestPage, match, history, setFurthestStep} = this.props;
        requestPage(0, true);
        setFurthestStep(3);
        history.push(siblingPath(match.path, "match"));
    };
}
