import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Api from "~/api";
import config from "~/config";
import MatchRequestPropType from "~/prop-types/match-request";
import MatchDetailsDialog from "~/components/MatchDetailsDialog";
import JobMatchList from "~/components/JobMatchList";
import {mergeProfiles} from "~/util/match-profile";
import {getJobMatchDisplayValue} from "~/util/match-display-values";
import HorizontalMatchPageLayout from "~/components/HorizontalMatchPageLayout";
import {getNameForJobMatch} from "~/components/JobMatchList/JobMatchList";
import {combineSelection, hasEnabledFilters} from "~/util/misc";
import {BlackWhiteList} from "~/util/black-white-list";
import {isGreenMatch} from "~/util/match";
import OnDemandEntity from "~/entities/OnDemandEntity";
import MatchComparisonDialog from "~/components/MatchComparisonDialog/MatchComparisonDialog";
import styles from "./styles.module.scss";
import MatchResultsPaginationBar from "~/components/MatchResultsPaginationBar";
import equal from "deep-equal";

class ProfileToJobs extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        query: PropTypes.string.isRequired,
        selection: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        candidateMatchingStrategy: PropTypes.string,
        matchRequest: MatchRequestPropType.isRequired,
        matches: PropTypes.object.isRequired,
        displayedMatches: PropTypes.array.isRequired,
        displayedPage: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalMatchCount: PropTypes.number,
        match: PropTypes.object.isRequired, // react-router
        isMatching: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        updateMatchProfile: PropTypes.func.isRequired,
        updateFilters: PropTypes.func.isRequired,
        updateSortMode: PropTypes.func.isRequired,
        updateQuery: PropTypes.func.isRequired,
        clearMatchProfile: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        setDisplayedPage: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        updateSelection: PropTypes.func.isRequired,
        clearSelection: PropTypes.func.isRequired,
        updateFixedSelection: PropTypes.func.isRequired,
        clearFixedSelection: PropTypes.func.isRequired,
        updateLanguage: PropTypes.func.isRequired,
        updateMatchingStrategy: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            displayedMatch: null,
            comparedMatches: null,
            parsingQuery: false,
            matchingEntity: undefined,
            defaultMatchProfile: undefined,
            matchClicked: false,
        };

        this.matchPageRef = React.createRef();
        this.matchListRef = React.createRef();
    }

    componentDidMount() {
        this.useMatchingEntity(this.createMatchingEntity());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateMatchingEntity(prevProps);
    }

    // TODO: This matchingEntity stuff should probably go into a HOC or something?
    createMatchingEntity() {
        const {match, matchingStrategy, candidateMatchingStrategy} = this.props;

        return OnDemandEntity.fromMatchParams(
            {
                [OnDemandEntity.Type.JOB]: config("features.profileToJobs.jobIndex"),
                [OnDemandEntity.Type.CANDIDATE]: config(
                    "features.profileToCandidates.candidateIndex"
                ),
            },
            OnDemandEntity.Type.CANDIDATE,
            match.params,
            {
                jobMatchingStrategy: matchingStrategy,
                candidateMatchingStrategy,
            }
        );
    }

    useMatchingEntity(matchingEntity) {
        const {updateMatchProfile, clearFilters} = this.props;

        if (matchingEntity !== undefined) {
            this.matchPageRef.current.toggleMatchProfileTab(false);
            this.matchPageRef.current.toggleResultsTab(true);
            this.setState({matchingEntity});

            matchingEntity.documentPromise
                .then(document => {
                    this.setState({defaultMatchProfile: document.matchProfile});
                    updateMatchProfile(document.matchProfile);
                    clearFilters();
                    this.handleMatch();
                })
                .catch(error => {
                    this.clearMatchingEntity();
                    throw error;
                });
        } else {
            this.clearMatchingEntity();
        }
    }

    clearMatchingEntity() {
        const {clearMatchProfile, clearFilters} = this.props;

        this.setState({
            matchingEntity: undefined,
            defaultMatchProfile: undefined,
        });

        clearMatchProfile();
        clearFilters();
    }

    updateMatchingEntity(prevProps) {
        const {location} = this.props;

        if (location === prevProps.location) {
            return;
        }

        const {matchingEntity} = this.state;
        const nextMatchingEntity = this.createMatchingEntity();

        if (!OnDemandEntity.areSame(matchingEntity, nextMatchingEntity)) {
            this.useMatchingEntity(nextMatchingEntity);
        }
    }

    render() {
        const {
            intl,
            query,
            language,
            matchingStrategy,
            matchRequest,
            lastMatchRequest,
            totalMatchCount,
            isMatching,
            updateQuery,
            updateLanguage,
            updateMatchingStrategy,
        } = this.props;
        const {parsingQuery, defaultMatchProfile, matchingEntity} = this.state;
        const availableFilters = config("features.profileToJobs.filters", []);
        const hasKeywordSearch = config("features.profileToJobs.keywordSearch", false);

        const entityDisplayName = matchingEntity ? matchingEntity.displayName : undefined;
        const profileLabel = intl.formatMessage({id: "match.jobProfile"}) + (this.isUsingDefaultMatchProfile() && entityDisplayName ? ` (${entityDisplayName})` : "");

        return (
            <HorizontalMatchPageLayout
                keywordQuery={query}
                indexName={config("features.profileToJobs.jobIndex")}
                language={language}
                matchingStrategy={matchingStrategy}
                matchProfile={matchRequest.matchProfile}
                lastMatchRequest={lastMatchRequest}
                availableFilters={availableFilters}
                filters={matchRequest.filters}
                isMatching={isMatching || parsingQuery}
                resultCount={totalMatchCount}
                showResetButton={defaultMatchProfile !== undefined}
                showKeywordSearch={hasKeywordSearch}
                showSuggestions={config("features.profileToJobs.keywordSuggestions", false)}
                sortMode={matchRequest.sortMode}
                sortModeGroup={config("features.profileToJobs.sortModeGroup", undefined)}
                profileLabel={profileLabel}
                resultsLabel={intl.formatMessage({id: "match.jobResults"})}
                onKeywordQueryChange={updateQuery}
                onMatchProfileChange={this.handleProfileChange}
                onFiltersChange={this.handleFiltersChange}
                onMatch={this.handleMatch}
                onClear={this.handleClear}
                onReset={this.handleReset}
                onSortModeChange={this.handleSortModeChange}
                onLanguageChange={updateLanguage}
                onMatchingStrategyChange={updateMatchingStrategy}
                ref={this.matchPageRef}
            >
                {this.renderResults()}
            </HorizontalMatchPageLayout>
        );
    }

    renderResults() {
        const {
            selection,
            fixedSelection,
            lastMatchRequest,
            displayedMatches,
            isMatching,
            updateSelection,
        } = this.props;
        const {matchClicked} = this.state;
        const showMatchButton = config("features.profileToJobs.showMatchButton", false);

        return (
            <div className={styles.resultsWrapper}>
                <JobMatchList
                    ref={this.matchListRef}
                    className={styles.matchList}
                    matches={displayedMatches}
                    allowSelection={config("features.profileToJobs.allowSelection", false)}
                    wasSearched={matchClicked && !isMatching}
                    selection={selection}
                    fixedSelection={fixedSelection}
                    extraColumns={config("features.profileToJobs.extraColumns", [])}
                    noResultsLabel={
                        !hasEnabledFilters(lastMatchRequest.filters)
                            ? "match.noResults"
                            : "match.noResultsFiltersHint"
                    }
                    isGreenMatch={this.isGreenMatch}
                    onView={this.handleView}
                    onMatch={showMatchButton ? this.handleMatchToOthers : undefined}
                    onSelectionChange={updateSelection}
                />
                {this.renderPaginationBar()}
                {this.renderDetailsDialog()}
                {this.renderComparisonDialog()}
            </div>
        );
    }

    renderPaginationBar() {
        const {displayedPage, selection, pageSize, totalMatchCount} = this.props;
        const {makingSelection} = this.state;

        const allowSelection = config("features.profileToJobs.allowSelection", false);
        const allowComparison = config("features.profileToJobs.allowComparison", false);
        const selectionSize = Object.keys(selection).length;

        return (
            <MatchResultsPaginationBar
                page={displayedPage}
                pageSize={pageSize}
                totalItems={totalMatchCount || 0}
                selectionSize={selectionSize}
                makingSelection={makingSelection}
                canSelect={allowSelection && this.isMatchingCandidate()}
                canCompare={allowComparison}
                selectLabel="temporary.selectJobs"
                compareLabel="compareJobs"
                onPageChange={this.handlePageChange}
                onDownload={this.handleDownload}
                onSelect={this.handleSelection}
                onCompare={this.handleCompare}
            />
        );
    }

    renderDetailsDialog() {
        const {intl} = this.props;
        const {displayedMatch, matchingEntity} = this.state;

        if (!displayedMatch) {
            return null;
        }

        const forwardLabel =
            matchingEntity && this.wasUsingDefaultMatchProfile()
                ? matchingEntity.displayName
                : undefined;
        const forwardSubLabel = forwardLabel
            ? intl.formatMessage({id: "matchQuality.searchProfile"})
            : undefined;

        return (
            <MatchDetailsDialog
                match={displayedMatch}
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.profileToJobs.extraProperties"
                )}
                onHide={this.handleHideDetailsDialog}
                title={intl.formatMessage(
                    {id: "detailsModal.title"},
                    {subject: getNameForJobMatch(displayedMatch)}
                )}
                forwardLabel={
                    forwardLabel || intl.formatMessage({id: "matchQuality.searchProfile"})
                }
                forwardSubLabel={forwardSubLabel}
                reverseLabel={intl.formatMessage({id: "matchQuality.jobColumn"})}
                getMatchDisplayValue={this.getMatchDisplayValue}
            />
        );
    }

    renderComparisonDialog = () => {
        const {comparedMatches} = this.state;

        if (!comparedMatches) {
            return null;
        }

        return (
            <MatchComparisonDialog
                matches={comparedMatches}
                titleLabel="compareJobs"
                reverseLabel="reverse.jobToProfile"
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.profileToJobs.extraProperties"
                )}
                getMatchDisplayValue={this.getMatchDisplayValue}
                onHide={this.handleHideComparisonDialog}
            />
        );
    };

    isGreenMatch = match => {
        const {lastMatchRequest} = this.props;
        return isGreenMatch(match, lastMatchRequest);
    };

    getMatchDisplayValue = (match, section) => {
        if (section === "additionalText") {
            // const {candidateText} = this.state;
            //
            // return candidateText
            //     ? {
            //           label: "detailsModal.candidateText",
            //           value: candidateText,
            //       }
            //     : undefined;
            return undefined;
        } else if (section === "header") {
            return getNameForJobMatch(match);
        } else {
            return getJobMatchDisplayValue(match, section);
        }
    };

    isMatchingCandidate() {
        const {matchingEntity} = this.state;
        return matchingEntity && matchingEntity.type === OnDemandEntity.Type.CANDIDATE;
    }

    isUsingDefaultMatchProfile() {
        const {defaultMatchProfile} = this.state;
        const {matchRequest} = this.props;

        if (defaultMatchProfile) {
            return equal(matchRequest.matchProfile, defaultMatchProfile, {strict: true});
        } else {
            return false;
        }
    }

    wasUsingDefaultMatchProfile() {
        const {defaultMatchProfile} = this.state;
        const {lastMatchRequest} = this.props;

        if (defaultMatchProfile) {
            return equal(lastMatchRequest.matchProfile, defaultMatchProfile, {strict: true});
        } else {
            return false;
        }
    }

    fetchSelectedJobs(ensureSelection) {
        const {matchingEntity} = this.state;
        const selectionEndpoint = config("api.selectionEndpoints.candidate.get", undefined);

        if (!selectionEndpoint || !this.isMatchingCandidate()) {
            this.props.clearFixedSelection();
            return;
        }

        return Api.fetchSelectedJobs(matchingEntity.id, selectionEndpoint).then(selection =>
            this.props.updateFixedSelection(combineSelection(selection, ensureSelection))
        );
    }

    handleMatch = parseAndMatch => {
        if (parseAndMatch === true) {
            // TODO: Make sure no event is passed here?
            this.handleParseAndMatch();
        } else {
            const {setDisplayedPage, requestPage, clearSelection} = this.props;

            requestPage(0, true).then(() => {
                setDisplayedPage(0);
            });

            this.setState({matchClicked: true});
            clearSelection();
            this.fetchSelectedJobs();

            this.matchPageRef.current.openResultsTab();
        }
    };

    handleReset = () => {
        const {updateMatchProfile, clearFilters} = this.props;
        const {defaultMatchProfile} = this.state;

        updateMatchProfile(defaultMatchProfile);
        clearFilters(); // TODO
    };

    handleParseAndMatch = () => {
        const {query, language, matchRequest, updateMatchProfile, updateQuery} = this.props;

        if (query.trim() !== "") {
            updateQuery("");
            this.setState({parsingQuery: true});

            Api.parseQuery(query, language).then(matchProfile => {
                updateMatchProfile(mergeProfiles(matchRequest.matchProfile, matchProfile));
                this.handleMatch();
                this.setState({parsingQuery: false});
            });
        } else {
            updateQuery("");
            this.handleMatch();
        }
    };

    handleClear = () => {
        this.props.clearMatchProfile();
        this.props.clearFilters();
        this.matchPageRef.current.resetMatchProfileEditor();
        this.matchPageRef.current.openMatchProfileTab();
    };

    handleProfileChange = matchProfile => {
        this.props.updateMatchProfile(matchProfile);
    };

    handleFiltersChange = filters => {
        this.props.updateFilters(filters);
    };

    handleSortModeChange = nextSortMode => {
        const {totalMatchCount} = this.props;
        this.props.updateSortMode(nextSortMode);
        if (totalMatchCount !== null) this.handleMatch();
    };

    handleView = id => {
        const {matches} = this.props;
        this.setState({displayedMatch: matches[id]});
    };

    handleMatchToOthers = (type, id) => {
        const {history} = this.props;

        switch (type) {
            case "jobs":
                history.push(`/profile-to-jobs/job/${id}`);
                break;

            case "candidates":
                history.push(`/profile-to-candidates/job/${id}`);
                break;

            default:
                return;
        }
    };

    handlePageChange = page => {
        const {setDisplayedPage, requestPage} = this.props;

        requestPage(page).then(() => {
            setDisplayedPage(page);
        });

        this.matchListRef.current.scrollToTop();
    };

    handleHideDetailsDialog = () => {
        this.setState({displayedMatch: null});
    };

    handleHideComparisonDialog = () => {
        this.setState({comparedMatches: null});
    };

    handleSelection = () => {
        const {matchingEntity} = this.state;
        const {selection, clearSelection} = this.props;
        const selectionSize = Object.keys(selection).length;
        const setSelectionEndpoint = config("api.selectionEndpoints.candidate.set", undefined);

        if (setSelectionEndpoint && this.isMatchingCandidate() && selectionSize > 0) {
            this.setState({makingSelection: true});

            Api.selectJobsForCandidate(matchingEntity.id, selection, setSelectionEndpoint)
                .then(result => {
                    if (!result.hasErrors) {
                        this.fetchSelectedJobs(selection).then(() => {
                            this.setState({makingSelection: false});
                            clearSelection();
                        });
                    } else {
                        // Todo Show error somewhere
                        console.error(result.message);
                        this.setState({makingSelection: false});
                        clearSelection();
                    }
                })
                .catch(error => {
                    this.setState({makingSelection: false});
                    throw error;
                });
        }
    };

    handleCompare = () => {
        const {selection, matches} = this.props;

        this.setState({
            comparedMatches: Object.keys(selection)
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score),
        });
    };
}

export default injectIntl(ProfileToJobs, {forwardRef: true});
