import {connect} from "react-redux";
import config from "~/config";
import JobToCandidatesMatch from "~/pages/job-to-candidates/JobToCandidatesMatch";
import {
    updateJob,
    requestPage,
    clearFilters,
    clearMatchProfile,
    invalidateAllPages,
    setDisplayedPage,
    updateFilters,
    updateSortMode,
    updateMatchingStrategy,
    clearSelection,
    updateSelection,
} from "~/actions/job-to-candidates";
import {displayedMatchesSelector, isMatchingSelector} from "~/selectors/job-to-candidates-matching";
import {updateLanguage} from "~/actions/ui";

function mapStateToProps(state) {
    return {
        language: state.ui.language,
        job: state.jobToCandidates.job,
        matchingStrategy: state.jobToCandidates.matchingStrategy,
        selection: state.jobToCandidates.selection,
        matchRequest: state.jobToCandidates.matchRequest,
        lastMatchRequest: state.jobToCandidates.lastMatchRequest,
        displayedPage: state.jobToCandidates.displayedPage,
        pageSize: state.jobToCandidates.pageSize,
        totalMatchCount: state.matchResults.resultSets.jobToCandidates.totalCount,
        matches: state.matchResults.resultSets.jobToCandidates.matches,
        displayedMatches: displayedMatchesSelector(state),
        isParsing: state.jobToCandidates.jobParseState !== config("enums.apiCallState.idle"),
        isMatching: isMatchingSelector(state),
    };
}

const mapDispatchToProps = {
    updateJob,
    updateFilters,
    updateSortMode,
    updateSelection,
    clearSelection,
    clearMatchProfile,
    clearFilters,
    setDisplayedPage,
    requestPage,
    invalidateAllPages,
    updateLanguage,
    updateMatchingStrategy,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobToCandidatesMatch);
