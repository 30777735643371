import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import emptyCandidate from "~/data/empty-candidate.json";
import {importCandidate} from "~/util/candidate";
import CandidateEditor from "~/components/CandidateEditor";
import Modal from "react-bootstrap/Modal";

export default class CandidateCreate extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        createOrUpdateCandidate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            candidate: importCandidate(emptyCandidate),
            isParsing: false,
            wasParsed: false,
            isCreating: false,
        };

        this.candidateEditorRef = React.createRef();
        this.idInputRef = React.createRef();
    }

    render() {
        const {id, candidate, wasParsed} = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                ID:{" "}
                                <input
                                    value={id}
                                    onChange={this.handleIdChange}
                                    ref={this.idInputRef}
                                />
                            </div>
                            <CandidateEditor
                                candidate={candidate}
                                isEditing={true}
                                showMatchProfileEditor={wasParsed}
                                onChange={this.handleCandidateChange}
                                ref={this.candidateEditorRef}
                            />
                            {this.renderButtons()}
                        </Col>
                    </Row>
                </Container>
                {this.renderModals()}
            </React.Fragment>
        );
    }

    renderButtons() {
        const {wasParsed} = this.state;

        if (!wasParsed) {
            return (
                <div>
                    <button type="button" onClick={this.handleParse}>
                        Parse
                    </button>
                </div>
            );
        } else {
            return (
                <div>
                    <button type="button" onClick={this.handleSubmit}>
                        Add to index
                    </button>
                </div>
            );
        }
    }

    renderModals() {
        const {isParsing, isCreating} = this.state;

        if (isParsing) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Parsing candidate...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Please wait while the candidate details are parsed by
                            theMatchBox.
                        </p>
                    </Modal.Body>
                </Modal>
            );
        } else if (isCreating) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Adding candidate to index...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Please wait while the new candidate is being added to the
                            candidates index.
                        </p>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }

    handleIdChange = e => {
        this.setState({id: e.target.value});
    };

    handleCandidateChange = candidate => {
        this.setState({candidate});
    };

    handleParse = () => {
        const {candidate} = this.state;

        if (candidate.title === "") {
            this.candidateEditorRef.current.focusTitle();
            return;
        } else if (candidate.description === "") {
            this.candidateEditorRef.current.focusDescription();
            return;
        }

        // TODO: Actual candidate parsing
        this.setState({wasParsed: true});
    };

    handleSubmit = () => {
        const {history} = this.props;
        const {id, candidate} = this.state;
        const finalId = id.trim();

        if (finalId === "") {
            this.idInputRef.current.focus();
            return;
        }

        const {createOrUpdateCandidate} = this.props;

        this.setState({isCreating: true});

        createOrUpdateCandidate(finalId, candidate)
            .then(() => {
                history.push(`/candidates/update/${finalId}`);
                this.setState({isCreating: false});
            })
            .catch(e => {
                console.error(e);
                this.setState({isCreating: false});
            });
    };
}
