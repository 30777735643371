import React from "react";
import PropTypes from "prop-types";
import ValidatedInput from "~/components/ValidatedInput";

export default class DateInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        defaultValue: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        defaultValue: "",
    };

    render() {
        const {value, defaultValue, onChange} = this.props;

        return (
            <ValidatedInput
                value={value}
                defaultValue={defaultValue}
                valueToInput={valueToInput}
                inputToValue={inputToValue}
                isValidAsInput={isValidAsInput}
                isValidForConversion={isValidForConversion}
                onChange={onChange}
            />
        );
    }
}

const INPUT_REGEX = new RegExp(
    "^([0-9]{0,4}-?|[0-9]{0,4}-[0-9]{0,2}-?|[0-9]{0,4}-[0-9]{0,2}-[0-9]{0,2})$"
);

const VALIDATION_REGEX = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$");

function isValidAsInput(input) {
    return INPUT_REGEX.test(input);
}

function isValidForConversion(input) {
    return input === "" || VALIDATION_REGEX.test(input);
}

function valueToInput(value) {
    return value || "";
}

function inputToValue(input) {
    return input;
}
