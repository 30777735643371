import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import objectPath from "object-path";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import Button from "react-bootstrap/Button";
import {getMatchDetailsTitleSubject} from "~/pages/ProfileToCandidates/ProfileToCandidates";
import Select from "~/components/Select";

export default class SmsCandidatesDialog extends React.PureComponent {
    static propTypes = {
        job: PropTypes.object.isRequired,
        matches: PropTypes.array,
        getMatchDisplayValue: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            text: TEMPLATE,
            wasSent: {},
            displayedMatch: null,
        };
    }

    getJobTemplateData() {
        const {job} = this.props;

        return {
            jobtitle: objectPath.get(job, "title"),
            location: objectPath.get(job, "matchProfile.aspects.locations.value.0.label"),
        };
    }

    getCandidateTemplateData(match) {
        return {
            name: getMatchDetailsTitleSubject(match),
        };
    }

    getTemplateData(match) {
        return {
            ...this.getJobTemplateData(),
            ...this.getCandidateTemplateData(match),
        };
    }

    render() {
        const {matches, onHide} = this.props;

        return (
            <Modal size="lg" show={true} centered onHide={onHide} scrollable={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <FormattedMessage
                            id="temporary.smsCandidates"
                            values={{count: matches.length}}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.container}>
                        <div className={styles.part} style={{flexGrow: 1.5}}>
                            {this.renderTextSelect()}
                            {this.renderTextArea()}
                        </div>
                        <div className={c(styles.part, styles.names)}>
                            {matches.map(this.renderMatch)}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    renderTextSelect() {
        const {matches} = this.props;
        const {displayedMatch} = this.state;
        const options = [{label: "Template", value: null}];

        for (const match of matches) {
            options.push({label: getMatchDetailsTitleSubject(match), value: match});
        }

        return (
            <Select
                className={styles.select}
                value={displayedMatch}
                onChange={this.handleSelectChange}
                options={options}
            />
        );
    }

    renderTextArea() {
        const {displayedMatch, text} = this.state;
        let result = text;

        if (displayedMatch !== null) {
            const templateData = this.getTemplateData(displayedMatch);

            for (const key in templateData) {
                if (!templateData[key]) continue;
                result = result.split("#" + key + "#").join(templateData[key]);
            }
        }

        return (
            <Form.Control
                as="textarea"
                rows="10"
                value={result}
                readOnly={displayedMatch !== null}
                onChange={this.handleChange}
            />
        );
    }

    renderMatch = match => {
        const {getMatchDisplayValue} = this.props;
        const {wasSent} = this.state;

        return (
            <div key={match.id} className={styles.match}>
                <span>{getMatchDisplayValue(match, "header")}</span>
                <Button
                    disabled={!!wasSent[match.id]}
                    onClick={() => this.handleSend(match.id)}
                    className="ml-auto"
                    variant={!!wasSent[match.id] ? "success" : "danger"}
                >
                    {!!wasSent[match.id] && (
                        <React.Fragment>
                            <i className="fas fa-check" />{" "}
                        </React.Fragment>
                    )}
                    {!!wasSent[match.id] ? "Verstuurd" : "Verstuur"}
                </Button>
            </div>
        );
    };

    handleSend = (id, b = true) => {
        this.setState(state => ({wasSent: {...state.wasSent, [id]: true}}));
    };

    handleChange = e => {
        const {displayedMatch} = this.state;
        if (displayedMatch === null) this.setState({text: e.target.value});
    };

    handleSelectChange = ({value}) => {
        this.setState({displayedMatch: value});
    };
}

const TEMPLATE =
    "Hoi #name#,\n\nIk ben Jan van theMatchBox en ik contacteer je voor de vacature van #jobtitle# te #location#. Heb je interesse, antwoord gewoon op deze SMS.\n\nGroeten,\nJan";
