import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import {injectIntl} from "react-intl";
import uuid from "uuid/v4";
import config from "~/config";
import AspectPropType from "~/prop-types/aspect";
import ListGroup from "react-bootstrap/ListGroup";
import Weight from "~/components/Weight";
import updateById from "~/util/update-by-id";
import styles from "./styles.module.scss";
import EnumValue from "./EnumValue";
import Checkbox from "~/components/Checkbox";

class EnumValuesAspectEditor extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        enumValues: PropTypes.array.isRequired,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            newValue: createNewValue(),
        };

        this.newValueRef = React.createRef();
    }

    reset() {
        this.setState({newValue: createNewValue()})
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                {this.renderValues()}
            </Card>
        );
    }

    renderHeader() {
        const {intl, title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderValues() {
        const {intl, aspect, enumValues, name, isEditing} = this.props;

        const values = aspect.value.map(value => (
            <EnumValue
                key={value.id}
                value={value}
                placeholder={intl.formatMessage({id: "input.removeValue"})}
                isPlaceholderDisabled={false}
                enumValues={enumValues}
                enumName={name}
                isEditing={isEditing}
                onChange={this.handleValueChange}
                onDelete={this.handleValueDelete}
            />
        ));

        this.renderNewValue(values);

        return <ListGroup variant="flush">{values}</ListGroup>;
    }

    renderNewValue(values) {
        const {intl, enumValues, name, isEditing} = this.props;
        if (!isEditing) return;

        const {newValue} = this.state;

        values.push(
            <EnumValue
                key={newValue.id}
                value={newValue}
                placeholder={intl.formatMessage({id: "input.addValue"})}
                isPlaceholderDisabled={true}
                enumValues={enumValues}
                enumName={name}
                isEditing={true}
                onChange={this.handleNewValueChange}
                ref={this.newValueRef}
            />
        );
    }

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };

    handleValueChange = (id, value) => {
        const {name, aspect, onChange} = this.props;
        const nextValue = updateById(aspect.value, id, value);
        const nextRequired = value.required ? true : aspect.required;
        onChange(name, {...aspect, required: nextRequired, value: nextValue});
    };

    handleNewValueChange = (id, value) => {
        if (value.label === "") {
            this.setState({newValue: value});
            return;
        }

        const {name, aspect, onChange} = this.props;
        const nextValue = [...aspect.value];
        nextValue.push(value);
        onChange(name, {...aspect, value: nextValue});
        this.setState({newValue: createNewValue()});
    };

    handleValueDelete = id => {
        const {name, aspect, onChange} = this.props;
        const nextValue = aspect.value.filter(value => value.id !== id);
        onChange(name, {...aspect, value: nextValue});
    };
}

function createNewValue() {
    return {
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        label: "",
    };
}

export default injectIntl(EnumValuesAspectEditor, {forwardRef: true});