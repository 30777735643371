import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Card from "react-bootstrap/Card";
import uuid from "uuid/v4";
import config from "~/config";
import AspectPropType from "~/prop-types/aspect";
import Weight from "~/components/Weight";
import updateById from "~/util/update-by-id";
import styles from "./styles.module.scss";
import Location from "./Location";
import ListGroup from "react-bootstrap/ListGroup";
import Checkbox from "~/components/Checkbox";

class LocationsAspectEditor extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            newLocation: createNewLocation(),
        };

        this.newLocationRef = React.createRef();
    }

    reset() {
        this.setState({newLocation: createNewLocation()})
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                <ListGroup variant="flush">{this.renderLocation()}</ListGroup>
            </Card>
        );
    }

    renderHeader() {
        const {intl, title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderLocation() {
        const {aspect, isEditing} = this.props;

        if (aspect.value.length > 0) {
            return (
                <Location
                    value={aspect.value[0]}
                    isEditing={isEditing}
                    onChange={this.handleLocationChange}
                    onDelete={this.handleLocationDelete}
                />
            );
        } else if (isEditing) {
            const {newLocation} = this.state;

            return (
                <Location
                    value={newLocation}
                    isEditing={true}
                    onChange={this.handleNewLocationChange}
                    ref={this.newLocationRef}
                />
            );
        } else {
            return null;
        }
    }

    handleLocationChange = (id, location) => {
        const {name, aspect, onChange} = this.props;
        const nextLocations = updateById(aspect.value, id, location);
        onChange(name, {...aspect, value: nextLocations});
    };

    handleNewLocationChange = (id, location) => {
        if (location.label === "" || location.range === undefined) {
            this.setState({newLocation: location});
            return;
        }

        const {name, aspect, onChange} = this.props;
        const nextLocations = [...aspect.value];
        nextLocations.push(location);
        onChange(name, {...aspect, value: nextLocations});
        this.setState({newLocation: createNewLocation()});
    };

    handleLocationDelete = id => {
        const {name, aspect, onChange} = this.props;
        const nextValue = aspect.value.filter(location => location.id !== id);
        onChange(name, {...aspect, value: nextValue});

        // We use setTimeout to place this block of code at the very end of the
        // event queue, because the blur event is processed before the next
        // active element is focused. And we only want to focus the new value
        // input if there's no other element focused.
        setTimeout(() => {
            if (
                document.activeElement !== null &&
                document.activeElement !== document.body
            ) {
                return;
            }

            if (this.newLocationRef.current) {
                this.newLocationRef.current.focus();
            }
        }, 0);
    };

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };
}

function createNewLocation() {
    return {
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        label: "",
        range: undefined,
        rangeUnit: config("enums.rangeUnit.0"),
    };
}

export default injectIntl(LocationsAspectEditor, {forwardRef: true});