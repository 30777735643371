import Api from "~/api";
import config from "~/config";
import applyJobParseResults from "~/util/apply-job-parse-results";
import {clearFilters, requestPage, updateMatchProfile} from "~/actions/profile-to-candidates";

export const UPDATE_JOB = "jobs/UPDATE_JOB";
export const SET_FETCHING = "jobs/SET_FETCHING";
export const ADD_RESULTS = "jobs/ADD_RESULTS";
export const SET_PAGE = "jobs/SET_PAGE";

export function fetchJob(id) {
    return dispatch => {
        const jobIndex = "TODO";
        return Api.fetchJob(jobIndex, id);
    };
}

export function fetchJobs(from, size) {
    return dispatch => {
        const jobIndex = config("features.jobList.jobIndex");
        dispatch(setFetching(true));

        return Api.fetchJobs(jobIndex, from, size)
            .then(({metadata, jobs}) => {
                dispatch(setFetching(false));
                dispatch(addResults(metadata, jobs));
            })
            .catch(e => {
                dispatch(setFetching(false));
                throw e;
            });
    };
}

export function matchJob(id) {
    return dispatch => {
        const jobIndex = config("features.jobList.jobIndex");
        return Api.fetchJob(jobIndex, id)
            .then(job => {
                dispatch(updateMatchProfile(job.matchProfile))
                dispatch(clearFilters());
                dispatch(requestPage(0, true));
            });
    };
}

export function createOrUpdateJob(id, job) {
    return dispatch => {
        const jobIndex = "TODO";
        const promise = Api.createOrUpdateJob(jobIndex, id, job);

        promise.then(() => {
            dispatch(updateJob(id, job));
        });

        return promise;
    };
}

export function parseJob(job) {
    return () => {
        return Api.parseJob(job).then(({response}) => {
            return {
                ...job,
                matchProfile: applyJobParseResults(
                    job.matchProfile,
                    response.parsingAspects
                ),
            };
        });
    };
}

function setFetching(isFetching) {
    return {
        type: SET_FETCHING,
        isFetching,
    };
}

function addResults(metadata, jobs) {
    return {
        type: ADD_RESULTS,
        count: metadata.count,
        jobs,
    }
}

function updateJob(id, job) {
    return {
        type: UPDATE_JOB,
        id,
        job,
    };
}

export function setPage(page) {
    return {
        type: SET_PAGE,
        page,
    }
}