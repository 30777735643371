import {enumMethods} from "~/enums/index";

const enumValues = {
    JOB: "JOB",
    CANDIDATE: "CANDIDATE",
};

export default {
    ...enumValues,
    ...enumMethods("ProfileType", enumValues),
};
