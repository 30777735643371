import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {createSelector} from "reselect";
import styles from "./styles.module.scss";

export default class Select extends React.PureComponent {
    static OptionPropType = PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    });

    static propTypes = {
        selected: PropTypes.oneOfType([Select.OptionPropType, PropTypes.any]),
        options: PropTypes.arrayOf(Select.OptionPropType).isRequired,
        variant: PropTypes.oneOf(["normal", "small"]),
        className: PropTypes.string,
        unstyled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        variant: "normal",
        unstyled: false,
    };

    render() {
        const {className, variant, unstyled} = this.props;

        const finalClassName = unstyled
            ? className
            : c("custom-select", className, styles.select, styles[variant]);

        return (
            <select
                value={this.getSelectedJson()}
                onChange={this.handleChange}
                className={finalClassName}
            >
                {this.renderOptions()}
            </select>
        );
    }

    renderOptions() {
        const {options} = this.props;
        const optionsWithJson = optionsWithJsonSelector(options);

        return optionsWithJson.map(option => {
            return (
                <option key={option.json} value={option.json} disabled={option.disabled}>
                    {option.label}
                </option>
            );
        });
    }

    getSelectedJson() {
        const {selected} = this.props;

        if (selected !== undefined) {
            if (selected !== null && selected.value) {
                return JSON.stringify(selected.value);
            } else {
                return JSON.stringify(selected);
            }
        } else {
            return undefined;
        }
    }

    handleChange = e => {
        const {options, onChange} = this.props;
        const optionsWithJson = optionsWithJsonSelector(options);
        const selectedJson = e.target.value;

        for (const option of optionsWithJson) {
            if (option.json === selectedJson) {
                onChange(option.originalOption);
                break;
            }
        }
    };
}

const optionsWithJsonSelector = createSelector(
    options => options,
    options => {
        return options.map(option => ({
            ...option,
            json: JSON.stringify(option.value),
            originalOption: option,
        }));
    }
);
