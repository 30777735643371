import config from "~/config";
import {getSortMode} from "~/util/sort-config";

export function convertJobToApi(job) {
    const aspects = {};
    const customAspects = {};

    for (const name in job.matchProfile.aspects) {
        let aspect = job.matchProfile.aspects[name];
        const converted = convertAspectToApi(aspect);

        if (converted === null) {
            continue;
        }

        if (aspect.custom) {
            customAspects[name] = converted;
        } else {
            aspects[name] = converted;
        }
    }

    return {
        jobTitle: job.title,
        jobText: job.description,
        ...aspects,
        extraProperties: customAspects
    };
}

export function convertCandidateToApi(candidate) {
    const aspects = {};
    const customAspects = {};

    for (const name in candidate.matchProfile.aspects) {
        let aspect = candidate.matchProfile.aspects[name];
        const converted = convertAspectToApi(aspect);

        if (converted === null) {
            continue;
        }

        if (aspect.custom) {
            customAspects[name] = converted;
        } else {
            aspects[name] = converted;
        }
    }

    return {
        cvText: candidate.description,
        ...aspects,
        workExperiences: candidate.workExperiences.map(convertWorkExperience),
        educations: candidate.educations.map(convertEducation),
        extraProperties: customAspects
    };
}

export function convertMatchRequestToApi(matchRequest, language, sortModeGroup) {
    const result = {
        matchProfile: convertMatchProfileToApi(matchRequest.matchProfile),
    };

    const matchFilter = convertFiltersToApi(matchRequest.filters);
    if (matchFilter) result.matchFilter = matchFilter;

    if (sortModeGroup) {
        const sortMode = getSortMode(sortModeGroup, matchRequest.sortMode);
        if (sortMode) result.sort = sortMode.sort;
    }

    if (language && result.matchProfile.language === undefined) {
        result.matchProfile.language = language;
    }

    return result;
}

export function convertMatchProfileToApi(matchProfile) {
    const aspects = {};
    const customAspects = {};

    for (const name in matchProfile.aspects) {
        let aspect = matchProfile.aspects[name];
        const converted = convertAspectToApi(aspect);

        if (converted === null) {
            continue;
        }

        if (aspect.custom) {
            customAspects[name] = converted;
        } else {
            aspects[name] = converted;
        }
    }

    return {
        ...aspects,
        extraProperties: customAspects
    };
}

export function convertAspectToApi(aspect) {
    switch (aspect.type) {
        case "concepts":
            return convertConceptsAspect(aspect);

        case "keywords":
            return convertKeywordsAspect(aspect);

        case "locations":
            return convertLocationsAspect(aspect);

        case "range":
            return convertRangeAspect(aspect);

        case "integer":
            return convertIntegerAspect(aspect);
            
        case "float":
            return convertFloatAspect(aspect);

        default:
            return null;
    }
}

function convertConceptsAspect(aspect) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            labels: [value.label],
        })),
    };
}

function convertKeywordsAspect(aspect) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            value: value.label,
        })),
    };
}

function convertLocationsAspect(aspect) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            label: value.label,
            range: value.range,
            rangeUnit: value.rangeUnit,
        })),
    };
}

function convertRangeAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            from: aspect.value.from,
            to: aspect.value.to,
        },
    };
}

function convertIntegerAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    let number = aspect.value.value;
    if (!Number.isInteger(number)) {
        number = Math.round(number);
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            value: number,
        },
    };
}

function convertFloatAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    let number = aspect.value.value;
    if (Number.isInteger(number)) {
        // Pass integers as a string with decimals
        number = Number(number).toFixed(1);
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            value: number,
        },
    };
}

function convertWorkExperience(workExperience) {
    return {
        title: workExperience.title,
        company: workExperience.company,
        text: workExperience.text,
        dateFrom: workExperience.dateFrom,
        dateTo: workExperience.dateTo,
    };
}

function convertEducation(education) {
    return {
        field: education.field,
        degree: education.degree,
        institute: education.institute,
        info: education.info,
        dateFrom: education.dateFrom,
        dateTo: education.dateTo,
    };
}

function convertFiltersToApi(filters) {
    const queries = [];

    for (const filterName in filters) {
        const filterData = filters[filterName];
        if (!filterData.enabled) continue;

        const filterConfig = config(`filters.${filterName}`);
        if (!filterConfig) continue;

        const property = config(`filters.${filterName}.property`);

        switch (filterConfig.type) {
            case "term":
            case "autocompleteTerm":
                if (typeof filterData.value === 'object' && filterData.value !== null) {
                    // Value is a full query, so add it as is
                    queries.push(filterData.value);
                } else {
                    queries.push({term: {[property]: filterData.value}});
                }

                break;

            case "multipleTerm":
                queries.push({
                    bool: {should: filterData.value.map(value => ({term: {[property]: value}}))},
                });
                break;

            case "numberComparison":
                queries.push({range: {[property]: {[filterData.value.comparison]: filterData.value.comparand}}});
                break;

            default:
                break;
        }
    }

    if (queries.length > 0) {
        return {bool: {must: queries}};
    } else {
        return undefined;
    }
}
