import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import MatchProfilePropType from "~/prop-types/match-profile";
import styles from "./styles.module.scss";
import MatchProfileEditor from "~/components/MatchProfileEditor";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import FilterEditor from "~/components/FilterEditor";
import MatchBlock from "~/containers/components/MatchBlock";

export default class MatchProfile extends React.PureComponent {
    static propTypes = {
        matchProfile: MatchProfilePropType.isRequired,
        availableFilters: PropTypes.array.isRequired,
        filters: PropTypes.object.isRequired,
        profileLabel: PropTypes.string.isRequired,
        indexName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        expanded: PropTypes.bool.isRequired,
        withMatchBlock: PropTypes.bool.isRequired,
        showResetButton: PropTypes.bool.isRequired,
        showSuggestions: PropTypes.bool.isRequired,
        onToggleExpanded: PropTypes.func.isRequired,
        onMatchProfileChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        onFiltersChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onMatchingStrategyChange: PropTypes.func.isRequired,
        matchProfileEditorRef: PropTypes.any,
    };

    render() {
        const {
            profileLabel,
            expanded,
            withMatchBlock,
            language,
            matchingStrategy,
            matchRequestHasChanged,
            showResetButton,
            onLanguageChange,
            onMatchingStrategyChange,
            onToggleExpanded,
            onMatch,
            onClear,
            onReset,
        } = this.props;

        return (
            <div className={c(styles.main, {expanded})}>
                <div className={styles.titleBar}>
                    <div className={styles.profileLabel} onClick={onToggleExpanded}>
                        <i className={c("fas", expanded ? "fa-caret-down" : "fa-caret-right")} />
                        <span>{profileLabel}</span>
                    </div>
                    <div>
                        <Button size="sm" variant="secondary" onClick={onClear}>
                            <i className="fas fa-ban" />
                        </Button>
                        {showResetButton && (
                            <Button
                                className="ml-2"
                                size="sm"
                                variant="outline-secondary bg-white"
                                onClick={onReset}
                            >
                                <FormattedMessage id="action.reset" />
                            </Button>
                        )}
                    </div>
                    {withMatchBlock && (
                        <MatchBlock
                            className={styles.matchBlock}
                            language={language}
                            matchingStrategy={matchingStrategy}
                            matchRequestHasChanged={matchRequestHasChanged}
                            onLanguageChange={onLanguageChange}
                            onMatchingStrategyChange={onMatchingStrategyChange}
                            onMatch={onMatch}
                        />
                    )}
                </div>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const {
            expanded,
            indexName,
            availableFilters,
            filters,
            language,
            matchProfile,
            showSuggestions,
            onFiltersChange,
            onMatchProfileChange,
            matchProfileEditorRef,
        } = this.props;

        return (
            <div className={c(styles.content, {expanded})}>
                <MatchProfileEditor
                    profile={matchProfile}
                    index={indexName}
                    language={language}
                    isEditing={true}
                    isHorizontal={true}
                    showSuggestions={showSuggestions}
                    onChange={onMatchProfileChange}
                    ref={matchProfileEditorRef}
                />
                {availableFilters.length > 0 && (
                    <React.Fragment>
                        <h5>
                            <FormattedMessage id="match.filters" />
                        </h5>
                        <FilterEditor
                            value={filters}
                            availableFilters={availableFilters}
                            onChange={onFiltersChange}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}
