import queryString from "query-string";
import config from "~/config";

export function setConfigFromQuery() {
    const query = queryString.parse(window.location.search);
    setCandidateIndexFromQuery(query);
    setJobIndexFromQuery(query);
    setDisplayNotFullyIndexed(query);
}

function setCandidateIndexFromQuery(query) {
    const candidateIndex = query.candidateIndex;

    if (!candidateIndex) {
        return;
    }

    setValueIfNull("features.profileToCandidates.candidateIndex", candidateIndex);
    setValueIfNull("features.jobToCandidates.candidateIndex", candidateIndex);
    setValueIfNull("features.candidateList.candidateIndex", candidateIndex);
}

function setJobIndexFromQuery(query) {
    const jobIndex = query.jobIndex;

    if (!jobIndex) {
        return;
    }

    setValueIfNull("features.profileToJobs.jobIndex", jobIndex);
    setValueIfNull("features.jobList.jobIndex", jobIndex);
}

function setDisplayNotFullyIndexed(query) {
    if (query.fullyIndexed) {
        config.set("ui.displayNotFullyIndexed", query.fullyIndexed <= 0)
    }
}

function setValueIfNull(path, value) {
    if (config(path, undefined) === null) {
        config.set(path, value)
    }
}
