import {enumMethods} from "~/enums/index";

const enumValues = {
    ACCEPT: "ACCEPT",
    REJECT: "REJECT",
    UNDECIDED: "UNDECIDED",
};

export default {
    ...enumValues,
    ...enumMethods("MatchDecisionType", enumValues),
};
