import React from "react";
import PropTypes from "prop-types";
import MatchDecisionType from "~/enums/MatchDecisionType";
import OkNokSwitch from "~/components/OkNokSwitch";

export default class MatchDecisionTypeSwitch extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOf(MatchDecisionType.values()),
        onChange: PropTypes.func.isRequired,
    };

    render() {
        return <OkNokSwitch value={this.value()} onChange={this.handleChange} />;
    }

    value() {
        switch (this.props.value) {
            case MatchDecisionType.ACCEPT:
                return true;

            case MatchDecisionType.REJECT:
                return false;

            default:
            case MatchDecisionType.UNDECIDED:
                return null;
        }
    }

    handleChange = value => {
        const {onChange} = this.props;

        switch (value) {
            case true:
                onChange(MatchDecisionType.ACCEPT);
                break;

            case false:
                onChange(MatchDecisionType.REJECT);
                break;

            default:
            case null:
                onChange(MatchDecisionType.UNDECIDED);
                break;
        }
    };
}
