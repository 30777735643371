export const INITIALIZE_RESULT_SET = "match-results/INITIALIZE_RESULT_SET";
export const REQUESTING_PAGE = "match-results/REQUESTING_PAGE";
export const REQUEST_ERROR = "match-results/REQUEST_ERROR";
export const RECEIVE_PAGE = "match-results/RECEIVE_PAGE";
export const INVALIDATE_ALL_PAGES = "match-results/INVALIDATE_ALL_PAGES";

export function initializeResultSet(resultSetName, createPromise, convertResponse) {
    return {
        type: INITIALIZE_RESULT_SET,
        resultSetName,
        createPromise,
        convertResponse,
    };
}

export function requestResultSetPage(resultSetName, page, isNewQuery = false) {
    return (dispatch, getState) => {
        if (!isNewQuery) {
            const existingPromise = getExistingPromise(getState, resultSetName, page);

            if (existingPromise !== undefined) {
                return existingPromise;
            }
        }

        const resultSet = getResultSet(getState, resultSetName);

        if (!resultSet) {
            return;
        }

        if (!resultSet.createPromise || !resultSet.convertResponse) {
            console.error(
                `Cannot request page for result set "${resultSetName}", missing callback(s)!`
            );
            return;
        }

        const promise = resultSet
            .createPromise(getState, page, isNewQuery, dispatch)
            .then(response => {
                const {matches, ids, count} = resultSet.convertResponse(response);
                dispatch(receivePage(resultSetName, page, matches, ids, count, isNewQuery));
            })
            .catch(reason => {
                dispatch(requestError(resultSetName, page));
                throw reason;
            });

        dispatch(requestingPage(resultSetName, page, promise));
        return promise;
    };
}

export function invalidateAllResultSetPages(resultSetName) {
    return {
        type: INVALIDATE_ALL_PAGES,
        resultSetName,
    };
}

function requestingPage(resultSetName, page, promise) {
    return {
        type: REQUESTING_PAGE,
        resultSetName,
        page,
        promise,
    };
}

function requestError(resultSetName, page) {
    return {
        type: REQUEST_ERROR,
        resultSetName,
        page,
    };
}

function receivePage(resultSetName, page, matches, ids, count, isNewQuery) {
    return {
        type: RECEIVE_PAGE,
        resultSetName,
        page,
        matches,
        ids,
        count,
        isNewQuery,
    };
}

function getExistingPromise(getState, resultSetName, page) {
    const resultSet = getResultSet(getState, resultSetName);

    if (!resultSet) {
        return undefined;
    }

    const pageData = resultSet.pages[page];
    return pageData ? pageData.promise : undefined;
}

function getResultSet(getState, resultSetName) {
    const resultSet = getState().matchResults.resultSets[resultSetName];

    if (resultSet === undefined) {
        console.error(`Uninitialized result set "${resultSetName}" being used!`);
    }

    return resultSet;
}
