import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import JobPropType from "~/prop-types/job";
import MatchList from "~/components/MatchList";
import styles from "./job-match-list.module.scss";
import MatchActions from "./MatchActions";

export default class JobMatchList extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                score: PropTypes.number.isRequired,
                job: JobPropType.isRequired,
                details: PropTypes.object.isRequired,
                explanations: PropTypes.array,
            })
        ).isRequired,
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                property: PropTypes.string.isRequired,
            })
        ),
        selection: PropTypes.object,
        fixedSelection: PropTypes.object,
        noResultsLabel: PropTypes.string.isRequired,
        allowSelection: PropTypes.bool.isRequired,
        wasSearched: PropTypes.bool.isRequired,
        isGreenMatch: PropTypes.func.isRequired,
        className: PropTypes.string,
        onView: PropTypes.func.isRequired,
        onMatch: PropTypes.func,
        onSelectionChange: PropTypes.func,
    };

    static defaultProps = {
        allowSelection: false,
        wasSearched: false,
        fixedSelection: {},
    };

    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
    }

    scrollToTop() {
        this.mainRef.current.scrollToTop();
    }

    render() {
        const {
            matches,
            allowSelection,
            wasSearched,
            selection,
            fixedSelection,
            extraColumns,
            noResultsLabel,
            isGreenMatch,
            className,
            onView,
            onSelectionChange,
        } = this.props;

        return (
            <MatchList
                ref={this.mainRef}
                className={className}
                matches={matches}
                allowSelection={allowSelection}
                wasSearched={wasSearched}
                selection={selection}
                fixedSelection={fixedSelection}
                extraColumns={extraColumns}
                noResultsLabel={noResultsLabel}
                renderDetailsColumnHeader={renderDetailsColumnHeader}
                renderDetailsColumn={renderDetailsColumn}
                renderMatchActions={this.renderMatchActions}
                extraColumnPropertyRoot={extraColumnPropertyRoot}
                isGreenMatch={isGreenMatch}
                onView={onView}
                onSelectionChange={onSelectionChange}
            />
        );
    }

    renderMatchActions = match => {
        const {onMatch} = this.props;

        if (!onMatch) {
            return null;
        }

        return <MatchActions match={match} onMatch={onMatch} />;
    };
}

function renderDetailsColumnHeader() {
    return <div><FormattedMessage id="column.jobTitleFunction"/></div>;
}

function renderDetailsColumn(match) {
    const jobFunction = getJobFunction(match);

    return (
        <React.Fragment>
            {match.job.title && (
                <div className={styles.ellipsis} title={match.job.title}>
                    {match.job.title}
                </div>
            )}
            {jobFunction && (
                <div className={styles.ellipsis} title={jobFunction}>
                    {jobFunction}
                </div>
            )}
            {!match.job.title && !jobFunction && <div>—</div>}
        </React.Fragment>
    );
}

export function getNameForJobMatch(match) {
    return match.job.title || getJobFunction(match);
}

function getJobFunction(match) {
    const jobFunctions = match.job.matchProfile.aspects.jobFunctions;

    if (jobFunctions === undefined) {
        return undefined;
    }

    const jobFunction = jobFunctions.value[0];

    if (jobFunction === undefined || jobFunction.label === undefined) {
        return undefined;
    }

    return jobFunction.label;
}

function extraColumnPropertyRoot(match) {
    return match.job;
}
