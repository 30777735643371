import Api from "~/api";
import config from "~/config";
import {
    initializeResultSet,
    requestResultSetPage,
    invalidateAllResultSetPages,
} from "~/actions/match-results";
import {INITIAL_MATCH_PROFILE, INITIAL_FILTERS} from "~/reducers/profile-to-candidates";

export const UPDATE_MATCH_PROFILE = "p2cm/UPDATE_MATCH_PROFILE";
export const UPDATE_FILTERS = "p2cm/UPDATE_FILTERS";
export const UPDATE_SORT_MODE = "p2cm/UPDATE_SORT_MODE";
export const UPDATE_QUERY = "p2cm/UPDATE_QUERY";
export const SET_DISPLAYED_PAGE = "p2cm/SET_DISPLAYED_PAGE";
export const UPDATE_SELECTION = "p2cm/UPDATE_SELECTION";
export const UPDATE_MATCHING_STRATEGY = "p2cm/UPDATE_MATCHING_STRATEGY";
export const UPDATE_FIXED_SELECTION = "p2cm/UPDATE_FIXED_SELECTION";
export const SET_LAST_MATCH_REQUEST = "p2cm/SET_LAST_MATCH_REQUEST";

export function initialize() {
    return initializeResultSet(
        "profileToCandidates",
        (getState, page, isNewQuery, dispatch) => {
            const matchRequest = getState().profileToCandidates.matchRequest;
            const lastMatchRequest = getState().profileToCandidates.lastMatchRequest;
            const size = getState().profileToCandidates.pageSize;
            const matchingStrategy = getState().profileToCandidates.matchingStrategy;
            const from = page * size;
            const language = getState().ui.language;
            const candidateIndex = config("features.profileToCandidates.candidateIndex");
            const sortModeGroup = config("features.profileToCandidates.sortModeGroup", undefined);
            const scoreType = config("features.profileToCandidates.scoreType", "MUTUAL");
            const expansionType = config("features.profileToCandidates.expansionType", "FULL");

            if (isNewQuery) {
                dispatch(setLastMatchRequest(matchRequest));
            }

            return Api.matchProfileToCandidates({
                candidateIndex,
                matchRequest: isNewQuery ? matchRequest : lastMatchRequest,
                from,
                size,
                language,
                sortModeGroup,
                scoreType,
                expansionType,
                matchingStrategy,
            });
        },
        ({matches, metadata}) => {
            const matchesById = {};
            const ids = [];

            for (const match of matches) {
                matchesById[match.id] = match;
                ids.push(match.id);
            }

            return {
                matches: matchesById,
                ids,
                count: metadata.count,
            };
        }
    );
}

export function updateMatchProfile(matchProfile) {
    return {
        type: UPDATE_MATCH_PROFILE,
        matchProfile,
    };
}

export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        filters,
    };
}

export function clearMatchProfile() {
    return updateMatchProfile(INITIAL_MATCH_PROFILE);
}

export function clearFilters() {
    return updateFilters(INITIAL_FILTERS);
}

export function setDisplayedPage(displayedPage) {
    return {
        type: SET_DISPLAYED_PAGE,
        displayedPage,
    };
}

export function requestPage(page, isNewQuery = false) {
    return requestResultSetPage("profileToCandidates", page, isNewQuery);
}

export function invalidateAllPages() {
    return invalidateAllResultSetPages("profileToCandidates");
}

export function updateSortMode(sortMode) {
    return {
        type: UPDATE_SORT_MODE,
        sortMode,
    };
}

export function updateQuery(query) {
    return {
        type: UPDATE_QUERY,
        query,
    };
}

export function updateSelection(selection) {
    return {
        type: UPDATE_SELECTION,
        selection,
    };
}

export function updateFixedSelection(fixedSelection) {
    return {
        type: UPDATE_FIXED_SELECTION,
        fixedSelection,
    };
}

export function clearSelection() {
    return updateSelection({});
}

export function clearFixedSelection() {
    return updateFixedSelection({});
}

export function setLastMatchRequest(matchRequest) {
    return {
        type: SET_LAST_MATCH_REQUEST,
        matchRequest,
    };
}

export function updateMatchingStrategy(matchingStrategy) {
    return {
        type: UPDATE_MATCHING_STRATEGY,
        matchingStrategy,
    };
}

