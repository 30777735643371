import React from "react";
import PropTypes from "prop-types";
import CandidatePropType from "~/prop-types/candidate";
import Description from "./Description";
import MatchProfileEditor from "~/components/MatchProfileEditor";
import WorkExperienceEditor from "~/components/WorkExperienceEditor";
import EducationEditor from "~/components/EducationEditor";
import styles from "./candidate-editor.module.scss";

export default class CandidateEditor extends React.PureComponent {
    static propTypes = {
        candidate: CandidatePropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        showMatchProfileEditor: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditing: true,
        showMatchProfileEditor: true,
    };

    constructor(props) {
        super(props);

        this.descriptionRef = React.createRef();
    }

    focusDescription() {
        this.focusRef(this.descriptionRef);
    }

    focusRef(ref) {
        if (ref.current) {
            ref.current.focus();
        }
    }

    render() {
        const {candidate, isEditing, showMatchProfileEditor, language} = this.props;

        return (
            <div>
                <div className={styles.wrapper}>
                    <Description
                        value={candidate.description}
                        isEditing={isEditing}
                        onChange={this.handleDescriptionChange}
                        ref={this.descriptionRef}
                    />
                    {showMatchProfileEditor && (
                        <MatchProfileEditor
                            profile={candidate.matchProfile}
                            isEditing={isEditing}
                            language={language}
                            onChange={this.handleMatchProfileChange}
                        />
                    )}
                </div>
                {showMatchProfileEditor && (
                    <WorkExperienceEditor
                        value={candidate.workExperiences}
                        isEditing={isEditing}
                        onChange={this.handleWorkExperiencesChange}
                    />
                )}
                {showMatchProfileEditor && (
                    <EducationEditor
                        value={candidate.educations}
                        isEditing={isEditing}
                        onChange={this.handleEducationsChange}
                    />
                )}
            </div>
        );
    }

    handleCandidatePropertyChange(property, value) {
        const {candidate, onChange} = this.props;
        onChange({...candidate, [property]: value});
    }

    handleDescriptionChange = description => {
        this.handleCandidatePropertyChange("description", description);
    };

    handleMatchProfileChange = matchProfile => {
        this.handleCandidatePropertyChange("matchProfile", matchProfile);
    };

    handleWorkExperiencesChange = workExperiences => {
        this.handleCandidatePropertyChange("workExperiences", workExperiences);
    };

    handleEducationsChange = educations => {
        this.handleCandidatePropertyChange("educations", educations);
    };
}
