import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {IntlProvider} from "react-intl";
import Api from "~/api";
import configureStore from "~/store/configure";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import "~/components/QuietFormattedMessage";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {initialize as initializeProfileToCandidates} from "~/actions/profile-to-candidates";
import {initialize as initializeJobToCandidates} from "~/actions/job-to-candidates";
import {initialize as initializeProfileToJobs} from "~/actions/profile-to-jobs";
import {initialize as initializeMatchingStrategies} from "~/actions/matching-strategy";
import {Auth0Provider, Auth0Context} from "~/util/auth0-provider";
import TEXT_MINING_INPUT from "!raw-loader!~/data/text-mining-input.txt"; // eslint-disable-line
import TEXT_MINING_API_RESULT from "~/data/text-mining-api-result";
import {updateResults} from "~/actions/text-mining";
import config from "~/config";
import {initializeLocalization} from "~/util/localization.js";
import {grabInternalTokenFromQuery} from "~/util/security.js";
import {setConfigFromQuery} from "~/util/config-from-query";

initializeLocalization();
grabInternalTokenFromQuery();
setConfigFromQuery();

const store = configureStore();
store.dispatch(initializeProfileToCandidates());
store.dispatch(initializeJobToCandidates());
store.dispatch(initializeProfileToJobs());
store.dispatch(initializeMatchingStrategies());

if (config("debug.enabled", false)) {
    store.dispatch(updateResults(TEXT_MINING_INPUT, TEXT_MINING_API_RESULT));
    window.Api = Api;
}

const {locale, messages} = initializeLocalization();

if (config("features.auth0.enabled", false)) {
    ReactDOM.render(
        <IntlProvider locale={locale} messages={messages} defaultLocale="en">
            <Auth0Provider
                audience={config("features.auth0.audience")}
                scope={config("features.auth0.scopes")}
                domain={config("features.auth0.domain")}
                client_id={config("features.auth0.clientId")}
                redirect_uri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                <Provider store={store}>
                    <Auth0Context.Consumer>
                        {auth0Context => <App auth0Context={auth0Context} />}
                    </Auth0Context.Consumer>
                </Provider>
            </Auth0Provider>
        </IntlProvider>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <IntlProvider locale={locale} messages={messages} defaultLocale="en">
            <Provider store={store}>
                <Auth0Context.Consumer>
                    {auth0Context => <App auth0Context={auth0Context} />}
                </Auth0Context.Consumer>
            </Provider>
        </IntlProvider>,
        document.getElementById("root")
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// A function that routes the user to the right place
// after login
function onRedirectCallback(appState) {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
    );
}
