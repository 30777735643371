import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import emptyJob from "~/data/empty-job.json";
import {importJob} from "~/util/job";
import JobEditor from "~/components/JobEditor";

/**
 * This class expects to be remounted when the ID parameter in match changes!
 * It only loads data once.
 */
export default class JobUpdate extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        fetchJob: PropTypes.func.isRequired,
        createOrUpdateJob: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            job: importJob(emptyJob),
            isLoaded: false,
            isUpdating: false,
        };
    }

    componentDidMount() {
        const {fetchJob, match} = this.props;

        fetchJob(match.params.id).then(job => {
            this.setState({job, isLoaded: true});
        }).catch(e => {
            console.log(e);
            this.setState({isLoaded: true});
        });
    }

    render() {
        const {job} = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col>
                            <JobEditor
                                job={job}
                                isEditing={true}
                                showMatchProfileEditor={true}
                                onChange={this.handleJobChange}
                            />
                            {this.renderButtons()}
                        </Col>
                    </Row>
                </Container>
                {this.renderModals()}
            </React.Fragment>
        );
    }

    renderButtons() {
        return (
            <div>
                <button type="button" onClick={this.handleSubmit}>
                    Update
                </button>
            </div>
        );
    }

    renderModals() {
        const {isLoaded, isUpdating} = this.state;

        if (!isLoaded) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Loading job...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please wait while the job is being loaded.</p>
                    </Modal.Body>
                </Modal>
            );
        } else if (isUpdating) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Updating job...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please wait while the job is being updated.</p>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }

    handleJobChange = job => {
        this.setState({job});
    };

    handleSubmit = () => {
        const {createOrUpdateJob, match} = this.props;
        const {job} = this.state;

        this.setState({isUpdating: true});

        createOrUpdateJob(match.params.id, job)
            .then(() => {
                this.setState({isUpdating: false});
            })
            .catch(e => {
                console.error(e);
                this.setState({isUpdating: false});
            });
    };
}
