import {enumMethods} from "~/enums/index";

const enumValues = {
    SCHEDULED: "SCHEDULED",
    RUNNING: "RUNNING",
    FINISHED: "FINISHED",
    ERROR: "ERROR",
};

export default {
    ...enumValues,
    ...enumMethods("TaskStatus", enumValues),
};
