import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import SimplePagination from "~/components/SimplePagination";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import ProcessButton from "~/components/ProcessButton";

export default class MatchResultsPaginationBar extends React.PureComponent {
    static propTypes = {
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        selectionSize: PropTypes.number.isRequired,
        makingSelection: PropTypes.bool.isRequired,
        canDownload: PropTypes.bool.isRequired,
        canSelect: PropTypes.bool.isRequired,
        canCompare: PropTypes.bool.isRequired,
        fakeButtons: PropTypes.arrayOf(PropTypes.string).isRequired,
        selectLabel: PropTypes.string.isRequired,
        compareLabel: PropTypes.string.isRequired,
        onPageChange: PropTypes.func,
        onDownload: PropTypes.func,
        onSelect: PropTypes.func,
        onCompare: PropTypes.func,
        onSms: PropTypes.func,
    };

    static defaultProps = {
        canDownload: false,
        canSelect: false,
        canCompare: false,
        makingSelection: false,
        fakeButtons: [],
    };

    render() {
        const {
            page,
            pageSize,
            totalItems,
            selectionSize,
            makingSelection,
            canDownload,
            canSelect,
            canCompare,
            fakeButtons,
            selectLabel,
            compareLabel,
            onPageChange,
            onDownload,
            onSelect,
            onCompare,
            onSms,
        } = this.props;

        return (
            <div className={c("d-flex p-1", styles.main)}>
                {canSelect && (
                    <ProcessButton
                        label={
                            <FormattedMessage id={selectLabel} values={{count: selectionSize}} />
                        }
                        className="mr-1"
                        size="sm"
                        onClick={onSelect}
                        processing={makingSelection}
                        disabled={selectionSize < 1}
                    />
                )}
                {canCompare && (
                    <Button
                        className="mr-1"
                        size="sm"
                        onClick={onCompare}
                        disabled={selectionSize < 2}
                    >
                        <FormattedMessage id={compareLabel} values={{count: selectionSize}} />
                    </Button>
                )}
                {fakeButtons.map(id => (
                    <Button
                        key={id}
                        className="mr-1"
                        variant="danger"
                        size="sm"
                        onClick={id.includes("sms") ? onSms : undefined}
                    >
                        <FormattedMessage id={id} values={{count: selectionSize}} />
                    </Button>
                ))}
                {canDownload && (
                    <Button className="mr-1" size="sm" onClick={onDownload}>
                        <FormattedMessage id="download.matchResults" />
                    </Button>
                )}
                <SimplePagination
                    className="ml-auto"
                    page={page}
                    itemsPerPage={pageSize}
                    totalItems={totalItems}
                    onChange={onPageChange}
                    radius={2}
                />
            </div>
        );
    }
}
