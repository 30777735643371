import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Value from "./Value";
import baseStyles from "../styles.module.scss";

export default class ExtraPropertyRow extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        matches: PropTypes.array,
        getMatchDisplayValue: PropTypes.func.isRequired,
    };

    render() {
        const {name} = this.props;

        return (
            <tr>
                <td className={baseStyles.stickyColumn}>
                    <FormattedMessage id={`extraProperties.${name}`} defaultMessage={name} />
                </td>
                {this.renderMatches()}
            </tr>
        );
    }

    renderMatches() {
        const {matches} = this.props;
        return matches.map((match, index) => (
            <td key={index} className={baseStyles.matchColumn}>
                {this.renderMatch(match)}
            </td>
        ));
    }

    renderMatch(match, index) {
        const {name, getMatchDisplayValue} = this.props;
        const extraProperties = getMatchDisplayValue(match, "extraProperties");
        return <Value value={extraProperties[name]} />;
    }
}
