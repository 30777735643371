import {connect} from "react-redux";
import profileToCandidates from "~/pages/ProfileToCandidates";
import {
    updateMatchProfile,
    updateFilters,
    updateQuery,
    updateFixedSelection,
    updateSelection,
    updateMatchingStrategy,
    clearFixedSelection,
    clearSelection,
    clearMatchProfile,
    clearFilters,
    updateSortMode,
    setDisplayedPage,
    requestPage,
    invalidateAllPages,
} from "~/actions/profile-to-candidates";
import {updateLanguage} from "~/actions/ui";
import {
    displayedMatchesSelector,
    isMatchingSelector,
} from "~/selectors/profile-to-candidates-matching";

function mapStateToProps(state) {
    return {
        query: state.profileToCandidates.query,
        language: state.ui.language,
        matchingStrategy: state.profileToCandidates.matchingStrategy,
        jobMatchingStrategy: state.profileToJobs.matchingStrategy,
        selection: state.profileToCandidates.selection,
        fixedSelection: state.profileToCandidates.fixedSelection,
        matchRequest: state.profileToCandidates.matchRequest,
        lastMatchRequest: state.profileToCandidates.lastMatchRequest,
        displayedPage: state.profileToCandidates.displayedPage,
        pageSize: state.profileToCandidates.pageSize,
        totalMatchCount: state.matchResults.resultSets.profileToCandidates.totalCount,
        matches: state.matchResults.resultSets.profileToCandidates.matches,
        displayedMatches: displayedMatchesSelector(state),
        isMatching: isMatchingSelector(state),
    };
}

const mapDispatchToProps = {
    updateMatchProfile,
    updateFilters,
    updateQuery,
    updateSelection,
    updateFixedSelection,
    clearSelection,
    clearFixedSelection,
    clearMatchProfile,
    clearFilters,
    updateSortMode,
    setDisplayedPage,
    requestPage,
    invalidateAllPages,
    updateLanguage,
    updateMatchingStrategy,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(profileToCandidates);
