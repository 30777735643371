import {UPDATE_LANGUAGE} from "~/actions/ui";
import config from "~/config";

const INITIAL_STATE = {
    language: config("api.defaultLanguage"),
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return updateLanguage(state, action.language);

        default:
            return state;
    }
}

function updateLanguage(state, language) {
    return {...state, language};
}
