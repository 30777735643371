import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";

export default class MatchActions extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        onMatch: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Dropdown as={ButtonGroup} size="sm" className="ml-1" onClick={this.handleMainClick}>
                <Button size="sm" variant="success" onClick={this.handleMatchJobs}>
                    <FormattedMessage id="match.matchJobs" />
                </Button>

                <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                <Dropdown.Menu>
                    <Dropdown.Item onClick={this.handleMatchCandidates}>
                        <FormattedMessage id="match.matchCandidates" />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    handleMainClick = e => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleMatchCandidates = e => {
        const {match, onMatch} = this.props;
        e.preventDefault();
        e.stopPropagation();
        onMatch("candidates", match.id);
    }

    handleMatchJobs = e => {
        const {match, onMatch} = this.props;
        e.preventDefault();
        e.stopPropagation();
        onMatch("jobs", match.id);
    }
}
