import {
    UPDATE_JOB,
    UPDATE_JOB_PARSE_STATE,
    UPDATE_FILTERS,
    UPDATE_SORT_MODE,
    SET_DISPLAYED_PAGE,
    SET_FURTHEST_STEP,
    UPDATE_SELECTION,
    SET_LAST_MATCH_REQUEST, UPDATE_MATCHING_STRATEGY,
} from "~/actions/job-to-candidates";
import matchingDemoJob from "~/data/matching-demo-job.json";
import {importJob} from "~/util/job";
import {importProfile} from "~/util/match-profile";
import emptyMatchProfile from "~/data/empty-match-profile";
import {getDefaultSort} from "~/util/sort-config";
import config from "~/config";
import {getInitialFilterState} from "~/util/filters-config";

export const INITIAL_MATCH_PROFILE = importProfile(emptyMatchProfile);
export const INITIAL_FILTERS = getInitialFilterState(
    config("features.jobToCandidates.filters", [])
);

const INITIAL_STATE = {
    job: importJob(matchingDemoJob),
    jobParseState: config("enums.apiCallState.idle"),
    matchRequest: {
        // matchProfile is taken from job
        filters: INITIAL_FILTERS,
        sortMode: getDefaultSort(config("features.jobToCandidates.sortModeGroup", undefined)),
    },
    lastMatchRequest: {
        matchProfile: importProfile(emptyMatchProfile),
        filters: {},
        sortMode: getDefaultSort(config("features.jobToCandidates.sortModeGroup", undefined)),
    },
    selection: {},
    matchingStrategy: null,
    displayedPage: 0,
    pageSize: 10,
    furthestStep: 1,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_JOB:
            return updateJob(state, action.job);

        case UPDATE_JOB_PARSE_STATE:
            return updateJobParseState(state, action.jobParseState);

        case UPDATE_FILTERS:
            return updateFilters(state, action.filters);

        case UPDATE_SORT_MODE:
            return updateSortMode(state, action.sortMode);

        case SET_DISPLAYED_PAGE:
            return setDisplayedPage(state, action.displayedPage);

        case SET_FURTHEST_STEP:
            return setFurthestStep(state, action.furthestStep);

        case UPDATE_SELECTION:
            return updateSelection(state, action.selection);

        case SET_LAST_MATCH_REQUEST:
            return setLastMatchRequest(state, action.matchRequest);

        case UPDATE_MATCHING_STRATEGY:
            return updateMatchingStrategy(state, action.matchingStrategy);

        default:
            return state;
    }
}

function updateJob(state, job) {
    return {...state, job};
}

function updateJobParseState(state, jobParseState) {
    return {...state, jobParseState};
}

function updateFilters(state, filters) {
    return {
        ...state,
        matchRequest: {
            ...state.matchRequest,
            filters,
        },
    };
}

function updateSortMode(state, sortMode) {
    return {
        ...state,
        matchRequest: {
            ...state.matchRequest,
            sortMode,
        },
    };
}

function setDisplayedPage(state, displayedPage) {
    return {
        ...state,
        displayedPage,
    };
}

function setFurthestStep(state, furthestStep) {
    return {
        ...state,
        furthestStep,
    };
}

function updateSelection(state, selection) {
    return {
        ...state,
        selection,
    };
}

function setLastMatchRequest(state, matchRequest) {
    return {
        ...state,
        lastMatchRequest: matchRequest,
    };
}

function updateMatchingStrategy(state, matchingStrategy) {
    return {...state, matchingStrategy};
}
