import {connect} from "react-redux";
import profileToJobs from "~/pages/ProfileToJobs";
import {
    updateMatchProfile,
    updateFilters,
    updateQuery,
    updateMatchingStrategy,
    clearMatchProfile,
    setDisplayedPage,
    requestPage,
    invalidateAllPages,
    updateSortMode,
    clearSelection,
    updateSelection,
    clearFixedSelection,
    updateFixedSelection,
    clearFilters,
} from "~/actions/profile-to-jobs";
import {displayedMatchesSelector, isMatchingSelector} from "~/selectors/profile-to-jobs-matching";
import {updateLanguage} from "~/actions/ui";

function mapStateToProps(state) {
    return {
        query: state.profileToJobs.query,
        language: state.ui.language,
        matchingStrategy: state.profileToJobs.matchingStrategy,
        candidateMatchingStrategy: state.profileToCandidates.matchingStrategy,
        selection: state.profileToJobs.selection,
        fixedSelection: state.profileToJobs.fixedSelection,
        matchRequest: state.profileToJobs.matchRequest,
        lastMatchRequest: state.profileToJobs.lastMatchRequest,
        displayedPage: state.profileToJobs.displayedPage,
        pageSize: state.profileToJobs.pageSize,
        totalMatchCount: state.matchResults.resultSets.profileToJobs.totalCount,
        matches: state.matchResults.resultSets.profileToJobs.matches,
        displayedMatches: displayedMatchesSelector(state),
        isMatching: isMatchingSelector(state),
    };
}

const mapDispatchToProps = {
    updateMatchProfile,
    updateFilters,
    updateSortMode,
    updateSelection,
    updateQuery,
    clearSelection,
    clearMatchProfile,
    clearFilters,
    setDisplayedPage,
    requestPage,
    invalidateAllPages,
    updateLanguage,
    clearFixedSelection,
    updateFixedSelection,
    updateMatchingStrategy,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(profileToJobs);
