import uuid from "uuid/v4";
import config from "~/config";
import {rescaleWeight} from "~/util/weights-calculations";
import {importJob} from "~/util/job";
import {importCandidate} from "~/util/candidate";
import {importProfile} from "~/util/match-profile";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";

function gatherAspects(object, aspectWeightStats, overwriteWeights, aspects) {
    for (const name in object) {
        if (name === 'extraProperties') {
            let extraProperties = object[name];
            gatherAspects(extraProperties, aspectWeightStats,  overwriteWeights, aspects);
            continue;
        }

        if (!EnabledAspectsBlackWhiteList.allows(name)) {
            continue;
        }

        let configAspect = config(`aspects.${name}`, undefined);
        if (configAspect === undefined) {
            continue;
        }

        const aspect = object[name];

        if (aspect === null) {
            continue;
        }

        let converted;

        try {
            converted = convertAspectFromApi(
                configAspect,
                aspect,
                aspectWeightStats
            );
        } catch (e) {
            console.error("Couldn't convert aspect (possibly inside extraProperties)");
            console.error(e);
            continue;
        }

        if (converted === null) {
            continue;
        }

        if (overwriteWeights) {
            let weight = configAspect["weight"];
            converted = {...converted, "weight": weight}
        }

        if (name === "jobFunctions") {
            converted.required = true;
        }

        aspects[name] = converted;
    }
}

export function convertJobFromApi(job) {
    const aspects = {};

    const overwriteWeights = config("ui.weight.overwrite", false);
    let aspectWeightStats = calculateAspectWeightStats(job, overwriteWeights);

    gatherAspects(job, aspectWeightStats, overwriteWeights, aspects);

    return importJob({
        title: job.jobTitle,
        description: job.jobText,
        matchProfile: {
            aspects,
        },
        extraProperties: job.extraProperties,
    });
}

export function convertCandidateFromApi(candidate) {
    const aspects = {};

    const overwriteWeights = config("ui.weight.overwrite", false);
    let aspectWeightStats = calculateAspectWeightStats(candidate, overwriteWeights);

    for (const name in candidate) {
        if (!EnabledAspectsBlackWhiteList.allows(name)) {
            continue;
        }

        let configAspect = config(`aspects.${name}`, undefined);
        if (configAspect === undefined) {
            continue;
        }

        const aspect = candidate[name];

        if (aspect === null) {
            continue;
        }

        let converted = convertAspectFromApi(
            configAspect,
            aspect,
            aspectWeightStats
        );

        if (converted === null) {
            continue;
        }

        if (overwriteWeights) {
            let weight = configAspect["weight"];
            converted = {...converted, "weight": weight}
        }

        if (name === "jobFunctions") {
            converted.required = true;
        }

        aspects[name] = converted;
    }

    let description = [candidate.cvText, candidate.preferredJobTitle, candidate.preferredJobInfo]
        .filter(v => v !== null)
        .join("\n\n");

    if (description === "") {
        if (candidate.jobFunctions !== null && candidate.jobFunctions.value !== null) {
            description = candidate.jobFunctions.value
                .flatMap(concept => concept.labels)
                .join("\n");
        }
    }

    return importCandidate({
        description,
        matchProfile: {
            aspects,
        },
        workExperiences: candidate.workExperiences.map(convertWorkExperience),
        educations: candidate.educations.map(convertEducation),
        extraProperties: candidate.extraProperties,
    });
}

export function convertAspectFromApi(aspectConfig, aspect, aspectWeightStats) {
    switch (aspectConfig.type) {
        case "concepts":
            return convertConceptsAspect(aspectConfig, aspect, aspectWeightStats);

        case "keywords":
            return convertKeywordsAspect(aspectConfig, aspect, aspectWeightStats);

        case "locations":
            return convertLocationsAspect(aspectConfig, aspect, aspectWeightStats);

        case "range":
            return convertRangeAspect(aspectConfig, aspect, aspectWeightStats);

        case "integer":
        case "float":
            return convertNumberAspect(aspectConfig, aspect,  aspectWeightStats);

        default:
            return null;
    }
}

function convertConceptsAspect(aspectConfig, aspect, aspectWeightStats) {
    let aspectWeight = rescaleWeight(aspect.weight, aspectWeightStats);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.labels ? value.labels.sort((a, b) => b.length - a.length)[0] : "",
        })),
        custom: aspect.custom,
    };
}

function convertKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.value,
        })),
        custom: aspect.custom,
    };
}

function convertLocationsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);

    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.label,
            range: value.range,
            rangeUnit: value.rangeUnit,
        })),
        custom: aspect.custom,
    };
}

function convertRangeAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: {
            weight: aspect.value.weight * config("ui.weight.scale"),
            required: aspect.value.required,
            from: aspect.value.from,
            to: aspect.value.to,
        },
        custom: aspect.custom,
    };
}

function convertNumberAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: {
            weight: aspect.value.weight * config("ui.weight.scale"),
            required: aspect.value.required,
            value: aspect.value.value,
        },
        custom: aspect.custom,
    };
}

function convertWorkExperience(workExperience) {
    return {
        id: uuid(),
        title: workExperience.title,
        company: workExperience.company,
        text: workExperience.text,
        dateFrom: workExperience.dateFrom,
        dateTo: workExperience.dateTo,
    };
}

function convertEducation(education) {
    return {
        id: uuid(),
        field: education.field,
        degree: education.degree,
        institute: education.institute,
        info: education.info,
        dateFrom: education.dateFrom,
        dateTo: education.dateTo,
    };
}

function calculateAspectWeightStats(aspects, overwriteWeights) {
    let sumOfAspectWeights = 0;
    let maxAspectWeight = 0;
    for (const name in aspects) {
        let configAspect = config(`aspects.${name}`, undefined);
        if (configAspect === undefined) {
            continue;
        }
        const aspect = aspects[name];
        if (aspect === null) {
            continue;
        }

        let weight = overwriteWeights ? configAspect.weight : aspect.weight;
        sumOfAspectWeights += weight;
        maxAspectWeight = Math.max(weight, maxAspectWeight);
    }

    return {
        sum: sumOfAspectWeights,
        max: maxAspectWeight,
    };
}

export function convertParsedQueryItemsMatchProfile(keywords) {
    let matchProfile = importProfile({aspects: {}});
    if (!keywords) return matchProfile;

    for (const item of keywords) {
        switch (item.type) {
            case "FUNCTION":
                matchProfile = addValueToAspect(matchProfile, "jobFunctions", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "HARD_SKILL":
            case "COMPANY":
            case "TEXT":
                matchProfile = addValueToAspect(matchProfile, "hardSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "SOFT_SKILL":
                matchProfile = addValueToAspect(matchProfile, "softSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "LOCATION":
                matchProfile = addValueToAspect(matchProfile, "locations", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                    range: 10,
                    rangeUnit: "KILOMETER",
                });
                break;

            default:
                break;
        }
    }

    return matchProfile;
}

function addValueToAspect(matchProfile, aspect, value) {
    return {
        ...matchProfile,
        aspects: {
            ...matchProfile.aspects,
            [aspect]: {
                ...matchProfile.aspects[aspect],
                value: [...matchProfile.aspects[aspect].value, {...value, id: uuid()}],
            },
        },
    };
}
