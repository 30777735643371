import "./App.scss";
import React from "react";
import PropTypes from "prop-types";
import {HashRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import config from "~/config";
import JobCreate from "~/containers/pages/JobCreate";
import JobUpdate from "~/containers/pages/JobUpdate";
import JobList from "~/containers/pages/JobList";
import CandidateCreate from "~/containers/pages/CandidateCreate";
import CandidateUpdate from "~/containers/pages/CandidateUpdate";
import CandidateList from "~/containers/pages/CandidateList";
import profileToCandidates from "~/containers/pages/ProfileToCandidates";
import profileToJobs from "~/containers/pages/ProfileToJobs";
import TextMining from "~/pages/text-mining/TextMining";
import JobToCandidates from "~/containers/pages/job-to-candidates/JobToCandidates";
import MatchingStrategy from "~/containers/pages/MatchingStrategy";
import AppLoading from "~/components/AppLoading";
import {jwtMetadata} from "~/util/jwt-meta";
import MatchingTest from "~/containers/pages/MatchingTest";

export default class App extends React.PureComponent {
    static propTypes = {
        auth0Context: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            requestedLogin: false,
        };
    }

    componentDidMount() {
        this.loginIfNeeded();
    }

    componentDidUpdate() {
        this.loginIfNeeded();
    }

    loginIfNeeded() {
        if (config("features.auth0.enabled", false)) {
            const {loading, isAuthenticated, loginWithRedirect} = this.props.auth0Context;
            const {requestedLogin} = this.state;

            if (!loading && !isAuthenticated && !requestedLogin) {
                this.setState({requestedLogin: true});
                loginWithRedirect({someCustomOption: 'hi'});
            }
        }
    }

    render() {
        const {auth0Context} = this.props;

        if (config("features.auth0.enabled", false)) {
            const {loading, isAuthenticated} = auth0Context;

            if (loading) {
                return <AppLoading />;
            } else if (!isAuthenticated) {
                return <AppLoading label="Redirecting to login..." />;
            }
        }

        return (
            <Router>
                <Switch>
                    <Route exact path="/profile-to-candidates" component={profileToCandidates} />
                    <Route path="/profile-to-candidates/:type/:id" component={profileToCandidates} />
                    <Route path="/profile-to-candidates/:id" component={profileToCandidates} />
                    <Route exact path="/profile-to-jobs" component={profileToJobs} />
                    <Route path="/profile-to-jobs/:type/:id" component={profileToJobs} />
                    <Route path="/profile-to-jobs/:id" component={profileToJobs} />
                    <Route path="/job-to-candidates" component={JobToCandidates} />
                    <Route path="/text-mining" component={TextMining} />

                    <Route path="/jobs/create" component={JobCreate} />
                    <Route
                        path="/jobs/update/:id"
                        render={props => <JobUpdate key={props.match.params.id} {...props} />}
                    />
                    <Route path="/jobs/list" component={JobList} />

                    <Route path="/candidates/create" component={CandidateCreate} />
                    <Route
                        path="/candidates/update/:id"
                        render={props => <CandidateUpdate key={props.match.params.id} {...props} />}
                    />
                    <Route path="/candidates/list" component={CandidateList} />

                    {/* TODO: Better solution for default redirect, using features config? */}
                    <Redirect
                        exact
                        from="/"
                        to={this.defaultRedirect()}
                    />

                    <Route path="/matching-strategy" component={MatchingStrategy} />
                    <Route path="/matching-test" component={MatchingTest} />
                </Switch>
            </Router>
        );
    }

    defaultRedirect() {
        const metadata = jwtMetadata();

        if (metadata && metadata.entity && metadata.entityID) {
            if (metadata.entity === "CREmployee") {
                return `/profile-to-jobs/${metadata.entityID}`;
            } else if (metadata.entity === "CRPublication" || metadata.entity === "CRVacancy") {
                return `/profile-to-candidates/${metadata.entityID}`;
            }
        }

        return config("ui.defaultRedirect", "/profile-to-candidates");
    }
}
