import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import {FormattedMessage, injectIntl} from "react-intl";
import uuid from "uuid/v4";
import config from "~/config";
import AspectPropType from "~/prop-types/aspect";
import ListGroup from "react-bootstrap/ListGroup";
import Weight from "~/components/Weight";
import Checkbox from "~/components/Checkbox";
import updateById from "~/util/update-by-id";
import styles from "./styles.module.scss";
import Value from "./Value";
import Suggestion from "./Suggestion";

class ValuesAspectEditor extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        language: PropTypes.string.isRequired,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onRemoveSuggestion: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
        suggestions: [],
        onRemoveSuggestion: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            newValue: createNewValue(),
            showSuggestions: false,
        };

        this.newValueRef = React.createRef();
    }

    reset() {
        this.setState({newValue: createNewValue()});
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                <ListGroup variant="flush">
                    {this.renderValues()}
                    {this.renderSuggestions()}
                </ListGroup>
            </Card>
        );
    }

    renderHeader() {
        const {intl, title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderValues() {
        const {intl, aspect, isEditing, language} = this.props;

        const values = aspect.value.map(value => (
            <Value
                key={value.id}
                value={value}
                placeholder={intl.formatMessage({id: "input.enterValue"})}
                isEditing={isEditing}
                language={language}
                autocompleteConceptTypes={aspect.autocomplete}
                onChange={this.handleValueChange}
                onFinish={this.handleValueFinish}
                onDelete={this.handleValueDelete}
            />
        ));

        this.renderNewValue(values);

        return values;
    }

    renderNewValue(values) {
        const {intl, aspect, isEditing, language} = this.props;
        if (!isEditing) return;

        const {newValue} = this.state;

        values.push(
            <Value
                key={newValue.id}
                value={newValue}
                placeholder={intl.formatMessage({id: "input.enterValue"})}
                isEditing={true}
                language={language}
                autocompleteConceptTypes={aspect.autocomplete}
                onChange={this.handleNewValueChange}
                ref={this.newValueRef}
            />
        );
    }

    renderSuggestions() {
        const {suggestions} = this.props;
        const {showSuggestions} = this.state;

        if (!showSuggestions) {
            if (suggestions.length === 0) {
                return null;
            }

            return (
                <ListGroup.Item className={styles.suggestion} onClick={this.handleShowSuggestions}>
                    <span>
                        <FormattedMessage
                            id="suggestions.available"
                            values={{count: suggestions.length}}
                        />
                    </span>
                </ListGroup.Item>
            );
        }

        return suggestions.map((suggestion, index) => (
            <Suggestion
                key={suggestion}
                value={suggestion}
                withLabel={index === 0}
                onAccept={this.handleAcceptSuggestion}
            />
        ));
    }

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };

    handleValueChange = (id, value) => {
        const {name, aspect, onChange} = this.props;
        const nextValue = updateById(aspect.value, id, value);
        const nextRequired = value.required ? true : aspect.required;
        onChange(name, {...aspect, required: nextRequired, value: nextValue});
    };

    handleNewValueChange = (id, value) => {
        if (value.label === "") {
            this.setState({newValue: value});
            return;
        }

        const {name, aspect, onChange} = this.props;
        const nextValue = [...aspect.value];
        nextValue.push(value);
        onChange(name, {...aspect, value: nextValue});
        this.setState({newValue: createNewValue()});
    };

    handleValueFinish = () => {
        if (this.newValueRef.current) {
            this.newValueRef.current.focus();
        }
    };

    handleValueDelete = id => {
        const {name, aspect, onChange} = this.props;
        const nextValue = aspect.value.filter(value => value.id !== id);
        onChange(name, {...aspect, value: nextValue});

        // We use setTimeout to place this block of code at the very end of the
        // event queue, because the blur event is processed before the next
        // active element is focused. And we only want to focus the new value
        // input if there's no other element focused.
        setTimeout(() => {
            if (document.activeElement !== null && document.activeElement !== document.body) {
                return;
            }

            if (this.newValueRef.current) {
                this.newValueRef.current.focus();
            }
        }, 0);
    };

    handleAcceptSuggestion = suggestion => {
        const {name, aspect, onChange, onRemoveSuggestion} = this.props;
        const value = createNewValue();
        value.label = suggestion;

        const nextValue = [...aspect.value];
        nextValue.push(value);
        onChange(name, {...aspect, value: nextValue});
        onRemoveSuggestion(name, suggestion);
    };

    handleShowSuggestions = () => {
        this.setState({showSuggestions: true});
    };
}

function createNewValue() {
    return {
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        label: "",
    };
}

export default injectIntl(ValuesAspectEditor, {forwardRef: true});
