import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";
import MatchQualityTable from "./MatchQualityTable";
import {FormattedMessage, injectIntl} from "react-intl";
import config from "~/config";

class MatchDetailsDialog extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        extraPropertiesBlackWhiteList: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        forwardLabel: PropTypes.string.isRequired,
        forwardSubLabel: PropTypes.string,
        reverseLabel: PropTypes.string.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        getMatchDisplayValue: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forwardLabel: "",
        hideForwardColumn: false,
    };

    constructor(props) {
        super(props);

        this.additionalTextRef = React.createRef();
    }

    render() {
        const {onHide, title} = this.props;

        return (
            <Modal size="lg" show={true} centered onHide={onHide} scrollable={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderDetailsTable()}
                    {this.renderProfileDisplay()}
                </Modal.Body>
            </Modal>
        );
    }

    renderDetailsTable() {
        const {match, getMatchDisplayValue, forwardLabel, forwardSubLabel, reverseLabel, hideForwardColumn} = this.props;

        const additionalTextExists = Boolean(getMatchDisplayValue(match, "additionalText"));

        return (
            <MatchQualityTable
                details={match.details}
                otherAspects={getMatchDisplayValue(match, "aspects")}
                forwardLabel={forwardLabel}
                forwardSubLabel={forwardSubLabel}
                reverseLabel={reverseLabel}
                hideForwardColumn={hideForwardColumn}
                onForwardLabelClick={
                    additionalTextExists ? this.handleForwardLabelClick : undefined
                }
                className={styles.qualityTable}
            />
        );
    }

    renderProfileDisplay() {
        const {match, getMatchDisplayValue} = this.props;
        const sections = config("ui.profileDisplay");

        return sections.map(section => {
            const value = getMatchDisplayValue(match, section);

            switch (section) {
                case "text":
                    return this.renderText(value);

                case "extraProperties":
                    return this.renderExtraProperties(value);

                case "educations":
                    return this.renderEducations(value);

                case "workExperiences":
                    return this.renderWorkExperiences(value);

                case "additionalText":
                    return this.renderText(value, this.additionalTextRef);

                default:
                    return this.renderUnknownSection(value);
            }
        });
    }

    renderText(text, ref) {
        if (!text) {
            return null;
        }

        return (
            <div key="text" ref={ref} className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id={text.label} />
                </h5>
                <p className={styles.jobDescription}>
                    {text.value || <FormattedMessage id="detailsModal.noText" />}
                </p>
            </div>
        );
    }

    renderExtraProperties(extraProperties) {
        if (!extraProperties) {
            return null;
        }

        const {extraPropertiesBlackWhiteList} = this.props;
        const displayedProperties = extraPropertiesBlackWhiteList.apply(
            Object.keys(extraProperties)
        );

        return (
            <div key="extraProperties" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.extraProperties" />
                </h5>
                <Table size="sm" borderless={true}>
                    <tbody>
                        {displayedProperties.sort().map(property => (
                            <tr key={property}>
                                <td className={styles.propertyColumn}>
                                    <FormattedMessage.Quiet
                                        id={`extraProperties.${property}`}
                                        defaultMessage={property}
                                    />
                                </td>
                                <td className={styles.valueColumn}>
                                    {renderPropertyValue(extraProperties[property])}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderEducations(educations) {
        if (!educations || !educations.length) {
            return null;
        }

        return (
            <div key="educations" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.educations" />
                </h5>
                <Table size="sm" bordered={true}>
                    <tbody>
                        {educations.map(education => (
                            <tr key={education.id}>
                                <td>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "education.dateFrom",
                                            education.dateFrom
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.dateTo",
                                            education.dateTo
                                        )}
                                    </div>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "education.field",
                                            education.field
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.degree",
                                            education.degree
                                        )}
                                        {this.renderSimpleDetail(
                                            "education.institute",
                                            education.institute
                                        )}
                                    </div>
                                    {education.info && <div>{education.info}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderWorkExperiences(workExperiences) {
        if (!workExperiences || !workExperiences.length) {
            return null;
        }

        return (
            <div key="workExperiences" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.workExperiences" />
                </h5>
                <Table bordered={true}>
                    <tbody>
                        {workExperiences.map(education => (
                            <tr key={education.id}>
                                <td>
                                    <div className={styles.simpleDetailList}>
                                        {this.renderSimpleDetail(
                                            "workExperience.dateFrom",
                                            education.dateFrom
                                        )}
                                        {this.renderSimpleDetail(
                                            "workExperience.dateTo",
                                            education.dateTo
                                        )}
                                        {this.renderSimpleDetail(
                                            "workExperience.company",
                                            education.company
                                        )}
                                    </div>
                                    {this.renderSimpleDetail(
                                        "workExperience.title",
                                        education.title
                                    )}
                                    {education.text && <div>{education.text}</div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderSimpleDetail(label, value) {
        return (
            value && (
                <div className={styles.simpleDetail}>
                    <div className={styles.simpleDetailTitle}>
                        <FormattedMessage id={label} />
                    </div>
                    <div className={styles.simpleDetailValue}>{value}</div>
                </div>
            )
        );
    }

    renderUnknownSection() {
        return null;
    }

    handleForwardLabelClick = () => {
        if (this.additionalTextRef.current) {
            this.additionalTextRef.current.scrollIntoView();
        }
    };
}

function renderPropertyValue(value) {
    if (Array.isArray(value)) {
        if (value.length > 0 && value[0] instanceof Object) {
            return value.map(renderObjectPropertyValue);
        } else {
            return value.map(renderPropertyValue).join(", ");
        }
    } else if (value instanceof Object) {
        return renderObjectPropertyValue(value);
    } else if (value === true) {
        return <i className="fas fa-check" />;
    } else if (value === false) {
        return <i className="fas fa-times" />;
    } else {
        return value;
    }
}

function renderObjectPropertyValue(value) {
    return (
        <Table bordered={true}>
            <tbody>
                {Object.keys(value)
                    .filter(key => !(value[key] instanceof Object))
                    .map(key => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td style={{width: "100%"}}>{value[key]}</td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
}

export default injectIntl(MatchDetailsDialog, {forwardRef: true});
