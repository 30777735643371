import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import WorkExperiencePropType from "~/prop-types/work-experience";
import ListGroup from "react-bootstrap/ListGroup";
import DateInput from "~/components/DateInput";

export default class WorkExperience extends React.PureComponent {
    static propTypes = {
        value: WorkExperiencePropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    render() {
        return (
            <ListGroup.Item>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    component="div"
                >
                    {wrapperProps => (
                        <div {...wrapperProps} tabIndex="0">
                            {this.renderContents()}
                        </div>
                    )}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderContents() {
        const {isEditing} = this.props;
        return isEditing ? this.renderContentsEdit() : this.renderContentsDisplay();
    }

    renderContentsDisplay() {
        const {value} = this.props;

        return (
            <React.Fragment>
                <div>
                    Title: {value.title}, company: {value.company}
                </div>
                <p>{value.text}</p>
                <div>
                    From {value.dateFrom} to {value.dateTo}
                </div>
            </React.Fragment>
        );
    }

    renderContentsEdit() {
        const {value} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex">
                    <label htmlFor={`title-${value.id}`}>Title</label>
                    <input
                        id={`title-${value.id}`}
                        value={value.title || ""}
                        onChange={this.handleTitleChange}
                        ref={this.inputRef}
                    />
                    <label htmlFor={`company-${value.id}`}>Company</label>
                    <input
                        id={`company-${value.id}`}
                        value={value.company || ""}
                        onChange={this.handleCompanyChange}
                    />
                </div>
                <textarea
                    placeholder="Text"
                    value={value.text || ""}
                    onChange={this.handleTextChange}
                    className="w-100"
                    rows={3}
                />
                <div className="d-flex">
                    <label htmlFor={`date-form-${value.id}`}>From</label>
                    <DateInput
                        id={`date-form-${value.id}`}
                        value={value.dateFrom || ""}
                        onChange={this.handleDateFromChange}
                    />
                    <label htmlFor={`date-to-${value.id}`}>To</label>
                    <DateInput
                        id={`date-to-${value.id}`}
                        value={value.dateTo || ""}
                        onChange={this.handleDateToChange}
                    />
                </div>
            </React.Fragment>
        );
    }

    handleTitleChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, title: e.target.value});
    };

    handleCompanyChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, company: e.target.value});
    };

    handleTextChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, text: e.target.value});
    };

    handleDateFromChange = dateFrom => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, dateFrom});
    };

    handleDateToChange = dateTo => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, dateTo});
    };

    normalizeValue = () => {
        const {value, isEditing, onDelete} = this.props;

        if (
            isEditing &&
            onDelete &&
            value.title === "" &&
            value.company === "" &&
            value.text === ""
        ) {
            onDelete(value.id);
        }
    };
}
