import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import styles from "./candidate-list.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import MatchDetailsDialog from "~/components/MatchDetailsDialog/MatchDetailsDialog";
import {BlackWhiteList} from "~/util/black-white-list";
import {getCandidateMatchDisplayValue} from "~/util/match-display-values";
import c from "classnames";
import {getMatchDetailsTitleSubject} from "~/pages/ProfileToCandidates/ProfileToCandidates";

export class Candidate extends React.PureComponent {
    static propTypes = {
        candidate: PropTypes.shape({
            id: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showingDetails: false,
        };
    }

    render() {
        return (
            <tr>
                {this.renderName()}
                {this.renderJobFunctions()}
                {this.renderLocations()}
                <td className={styles.actionsColumn}>
                    <button
                        className={c("btn", "btn-sm", "btn-info")}
                        onClick={this.handleDetailsClick}
                    >
                        <FormattedMessage id="match.details" />
                    </button>

                    <Dropdown as={ButtonGroup} size="sm" className="ml-1" onClick={this.handleMainClick}>
                        <Button size="sm" variant="success" onClick={this.handleMatchJobs}>
                            <FormattedMessage id="match.matchJobs" />
                        </Button>

                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleMatchCandidates}>
                                <FormattedMessage id="match.matchCandidates" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {this.renderDetailsDialog()}
                </td>
            </tr>
        );
    }

    renderName() {
        const {candidate} = this.props;
        const match = {candidate, details: {}};
        return <td>{getMatchDetailsTitleSubject(match)}</td>;
    }

    renderJobFunctions() {
        const {candidate} = this.props;
        const jobFunctions = [];

        for (const jobFunction of candidate.matchProfile.aspects.jobFunctions.value) {
            jobFunctions.push(jobFunction.label);
        }

        return <td>{jobFunctions.join(", ")}</td>;
    }

    renderLocations() {
        const {candidate} = this.props;
        const locations = [];

        for (const location of candidate.matchProfile.aspects.locations.value) {
            locations.push(location.label);
        }

        return <td>{locations.join(", ")}</td>;
    }

    renderDetailsDialog() {
        const {candidate} = this.props;
        const {showingDetails} = this.state;

        if (!showingDetails) {
            return null;
        }

        const match = {candidate, details: {}};

        return (
            <MatchDetailsDialog
                match={match}
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.profileToJobs.extraProperties"
                )}
                onHide={this.handleHideDetailsDialog}
                title={getMatchDetailsTitleSubject(match)}
                hideForwardColumn={true}
                reverseLabel=""
                getMatchDisplayValue={this.getMatchDisplayValue}
            />
        );
    }

    getMatchDisplayValue = (match, section) => {
        if (section === "header") {
            return getMatchDetailsTitleSubject(match);
        } else {
            return getCandidateMatchDisplayValue(match, section);
        }
    };

    handleDetailsClick = () => {
        this.setState({showingDetails: true});
    };

    handleHideDetailsDialog = () => {
        this.setState({showingDetails: false});
    };

    handleMatchCandidates = e => {
        const {candidate, history} = this.props;
        history.push(`/profile-to-candidates/candidate/${candidate.id}`);
    }

    handleMatchJobs = e => {
        const {candidate, history} = this.props;
        history.push(`/profile-to-jobs/candidate/${candidate.id}`);
    }
}

export default withRouter(Candidate);