import {connect} from "react-redux";
import CandidateUpdate from "~/pages/CandidateUpdate";
import {fetchCandidate, createOrUpdateCandidate} from "~/actions/candidates";

function mapStateToProps(state) {
    return {
        //
    };
}

const mapDispatchToProps = {
    fetchCandidate,
    createOrUpdateCandidate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateUpdate);
