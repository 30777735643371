import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import WorkExperiencePropType from "~/prop-types/work-experience";
import updateById from "~/util/update-by-id";
import WorkExperience from "./WorkExperience";

export default class WorkExperienceEditor extends React.PureComponent {
    static propTypes = {
        value: PropTypes.arrayOf(WorkExperiencePropType),
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            newWorkExperience: createNewWorkExperience(),
        };

        this.newWorkExperienceRef = React.createRef();
    }

    render() {
        return (
            <Card>
                {this.renderHeader()}
                {this.renderExperiences()}
            </Card>
        );
    }

    renderHeader() {
        return <Card.Header className="d-flex">Work Experience</Card.Header>;
    }

    renderExperiences() {
        const {value, isEditing} = this.props;

        const workExperiences = value.map(workExperience => (
            <WorkExperience
                key={workExperience.id}
                value={workExperience}
                isEditing={isEditing}
                onChange={this.handleWorkExperienceChange}
                onDelete={this.handleWorkExperienceDelete}
            />
        ));

        this.renderNewWorkExperience(workExperiences);

        return <ListGroup variant="flush">{workExperiences}</ListGroup>;
    }

    renderNewWorkExperience(values) {
        const {isEditing} = this.props;
        if (!isEditing) return;

        const {newWorkExperience} = this.state;

        values.push(
            <WorkExperience
                key={newWorkExperience.id}
                value={newWorkExperience}
                isEditing={true}
                onChange={this.handleNewWorkExperienceChange}
                ref={this.newWorkExperienceRef}
            />
        );
    }

    handleWorkExperienceChange = (id, workExperience) => {
        const {value, onChange} = this.props;
        const nextValue = updateById(value, id, workExperience);
        onChange(nextValue);
    };

    handleNewWorkExperienceChange = (id, workExperience) => {
        if (
            workExperience.title === "" &&
            workExperience.company === "" &&
            workExperience.text === ""
        ) {
            this.setState({newWorkExperience: workExperience});
            return;
        }

        const {value, onChange} = this.props;
        const nextValue = [...value, workExperience];
        onChange(nextValue);
        this.setState({newWorkExperience: createNewWorkExperience()});
    };

    handleWorkExperienceDelete = id => {
        const {value, onChange} = this.props;
        const nextValue = value.filter(workExperience => workExperience.id !== id);
        onChange(nextValue);

        // We use setTimeout to place this block of code at the very end of the
        // event queue, because the blur event is processed before the next
        // active element is focused. And we only want to focus the new value
        // input if there's no other element focused.
        setTimeout(() => {
            if (document.activeElement !== null && document.activeElement !== document.body) {
                return;
            }

            if (this.newWorkExperienceRef.current) {
                this.newWorkExperienceRef.current.focus();
            }
        }, 0);
    };
}

function createNewWorkExperience() {
    return {
        id: uuid(),
        title: "",
        company: "",
        text: "",
        dateFrom: "",
        dateTo: "",
    };
}
