import {connect} from "react-redux";
import JobToCandidatesInput from "~/pages/job-to-candidates/JobToCandidatesInput";
import {updateJob, parseJob, setFurthestStep, requestPage} from "~/actions/job-to-candidates";

function mapStateToProps(state) {
    return {
        job: state.jobToCandidates.job,
        language: state.ui.language,
    };
}

const mapDispatchToProps = {
    updateJob,
    parseJob,
    requestPage,
    setFurthestStep,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobToCandidatesInput);
