import {connect} from "react-redux";
import config from "~/config";
import JobToCandidatesVerify from "~/pages/job-to-candidates/JobToCandidatesVerify";
import {updateJob, requestPage, setFurthestStep} from "~/actions/job-to-candidates";

function mapStateToProps(state) {
    return {
        job: state.jobToCandidates.job,
        isParsing: state.jobToCandidates.jobParseState !== config("enums.apiCallState.idle"),
        language: state.ui.language,
    };
}

const mapDispatchToProps = {
    updateJob,
    requestPage,
    setFurthestStep,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobToCandidatesVerify);
