import {connect} from "react-redux";
import JobCreate from "~/pages/JobCreate";
import {parseJob, createOrUpdateJob} from "~/actions/jobs";

function mapStateToProps(state) {
    return {
        //
    };
}

const mapDispatchToProps = {
    parseJob,
    createOrUpdateJob,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobCreate);
