import PropTypes from "prop-types";

export default PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    company: PropTypes.string,
    text: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
});
