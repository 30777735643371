import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import {FormattedMessage} from "react-intl";
import c from "classnames";
import config from "~/config";
import {ENABLED_ASPECTS} from "~/util/enabled-aspects";
import AspectRow from "./AspectRow";
import ExtraPropertyRow from "./ExtraPropertyRow";
import WorkExperiencesRow from "./WorkExperiencesRow";
import EducationsRow from "./EducationsRow";
import styles from "./styles.module.scss";
import ProgressBar from "~/components/ProgressBar";

const Sections = {
    ASPECTS: "aspects",
    EXTRA_PROPERTIES: "extraProperties",
    WORK_EXPERIENCES: "workExperiences",
    EDUCATIONS: "educations",
}

export default class MatchComparisonTable extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array, // TODO: More specific
        reverseLabel: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.string),
        getMatchDisplayValue: PropTypes.func.isRequired,
        extraPropertiesBlackWhiteList: PropTypes.object.isRequired,
    };

    static defaultProps = {
        sections: [Sections.ASPECTS, Sections.EXTRA_PROPERTIES],
    }

    render() {
        const {sections} = this.props;

        return (
            <Table size="sm" className={styles.table}>
                <thead>{this.renderHeader()}</thead>
                <tbody>
                    {sections.includes(Sections.ASPECTS) && this.renderAspects()}
                    {sections.includes(Sections.EXTRA_PROPERTIES) && this.renderExtraProperties()}
                    {sections.includes(Sections.WORK_EXPERIENCES) && this.renderWorkExperiences()}
                    {sections.includes(Sections.EDUCATIONS) && this.renderEducations()}
                </tbody>
            </Table>
        );
    }

    renderHeader() {
        const {matches, getMatchDisplayValue} = this.props;

        return (
            <tr>
                <th className={c(styles.inlineHeader)}>
                    <FormattedMessage id="matchQuality.aspectColumn" />
                </th>
                {matches.map((match, index) => (
                    <th key={index} className={c(styles.matchColumn, styles.stickyHeader)}>
                        <div className={styles.matchHeader}>
                            <span>{getMatchDisplayValue(match, "header")}</span>
                            <ProgressBar
                                className={styles.itemProgress}
                                variant="success"
                                now={match.score}
                                min={0}
                                max={1}
                            />
                        </div>
                    </th>
                ))}
            </tr>
        );
    }

    renderAspects() {
        const {matches, reverseLabel, getMatchDisplayValue} = this.props;

        return config("ui.aspectOrder")
            .filter(aspect => ENABLED_ASPECTS.includes(aspect))
            .map(aspect => (
                <AspectRow
                    key={aspect}
                    name={aspect}
                    reverseLabel={reverseLabel}
                    matches={matches}
                    getMatchDisplayValue={getMatchDisplayValue}
                />
            ));
    }

    renderExtraProperties() {
        const {matches, getMatchDisplayValue} = this.props;
        const extraProperties = this.getAllExtraProperties();

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.extraProperties" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                {extraProperties.map(property => (
                    <ExtraPropertyRow
                        name={property}
                        matches={matches}
                        getMatchDisplayValue={getMatchDisplayValue}
                    />
                ))}
            </React.Fragment>
        );
    }

    renderWorkExperiences() {
        const {matches, getMatchDisplayValue} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.workExperiences" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <WorkExperiencesRow matches={matches} getMatchDisplayValue={getMatchDisplayValue} />
            </React.Fragment>
        );
    }

    renderEducations() {
        const {matches, getMatchDisplayValue} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.educations" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <EducationsRow matches={matches} getMatchDisplayValue={getMatchDisplayValue} />
            </React.Fragment>
        );
    }

    getAllExtraProperties() {
        const {matches, getMatchDisplayValue, extraPropertiesBlackWhiteList} = this.props;
        const allExtraProperties = {};

        for (const match of matches) {
            const extraProperties = getMatchDisplayValue(match, "extraProperties");

            for (const name of Object.keys(extraProperties)) {
                allExtraProperties[name] = true;
            }
        }

        return extraPropertiesBlackWhiteList
            .apply(Object.keys(allExtraProperties))
            .sort((a, b) => a.localeCompare(b));
    }
}

MatchComparisonTable.Sections = Sections;