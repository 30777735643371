import PropTypes from "prop-types";
import MatchProfilePropType from "./match-profile";
import WorkExperiencePropType from "./work-experience";
import EducationPropType from "./education";

export default PropTypes.shape({
    description: PropTypes.string,
    matchProfile: MatchProfilePropType.isRequired,
    workExperiences: PropTypes.arrayOf(WorkExperiencePropType).isRequired,
    educations: PropTypes.arrayOf(EducationPropType).isRequired,
});
