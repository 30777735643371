import {enumMethods} from "~/enums/index";

const enumValues = {
    CORRECT: "CORRECT",
    FALSE_POSITIVE: "FALSE_POSITIVE",
    MISSED: "MISSED",
    UNKNOWN: "UNKNOWN",
};

export default {
    ...enumValues,
    ...enumMethods("ResultStatus", enumValues),
};
