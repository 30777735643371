export function removeUndefinedValues(object) {
    const result = {};

    for (const key in object) {
        if (object.hasOwnProperty(key) && object[key] !== undefined) {
            result[key] = object[key];
        }
    }

    return result;
}

export function removeDuplicateValues(array) {
    return [...new Set(array)];
}

export function isEmptyObject(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function hasEnabledFilters(filters) {
    for (const name in filters) {
        if (filters[name].enabled) {
            return true;
        }
    }

    return false;
}

export function percentageString(value) {
    return Math.round(value * 100) + "\xa0%";
}

export function combineSelection(a, b) {
    if (b === undefined) {
        return a;
    } else if (a === undefined) {
        return b;
    }

    const result = {...a};

    for (const value in b) {
        if (b[value] === true) {
            result[value] = true;
        }
    }

    return result;
}
