import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import EducationPropType from "~/prop-types/education";
import updateById from "~/util/update-by-id";
import Education from "./Education";

export default class EducationEditor extends React.PureComponent {
    static propTypes = {
        value: PropTypes.arrayOf(EducationPropType),
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            newEducation: createNewEducation(),
        };

        this.newEducationRef = React.createRef();
    }

    render() {
        return (
            <Card>
                {this.renderHeader()}
                {this.renderExperiences()}
            </Card>
        );
    }

    renderHeader() {
        return <Card.Header className="d-flex">Education</Card.Header>;
    }

    renderExperiences() {
        const {value, isEditing} = this.props;

        const educations = value.map(education => (
            <Education
                key={education.id}
                value={education}
                isEditing={isEditing}
                onChange={this.handleEducationChange}
                onDelete={this.handleEducationDelete}
            />
        ));

        this.renderNewEducation(educations);

        return <ListGroup variant="flush">{educations}</ListGroup>;
    }

    renderNewEducation(values) {
        const {isEditing} = this.props;
        if (!isEditing) return;

        const {newEducation} = this.state;

        values.push(
            <Education
                key={newEducation.id}
                value={newEducation}
                isEditing={true}
                onChange={this.handleNewEducationChange}
                ref={this.newEducationRef}
            />
        );
    }

    handleEducationChange = (id, education) => {
        const {value, onChange} = this.props;
        const nextValue = updateById(value, id, education);
        onChange(nextValue);
    };

    handleNewEducationChange = (id, education) => {
        if (
            !education.field &&
            !education.degree &&
            !education.institute &&
            !education.info
        ) {
            this.setState({newEducation: education});
            return;
        }

        const {value, onChange} = this.props;
        const nextValue = [...value, education];
        onChange(nextValue);
        this.setState({newEducation: createNewEducation()});
    };

    handleEducationDelete = id => {
        const {value, onChange} = this.props;
        const nextValue = value.filter(education => education.id !== id);
        onChange(nextValue);

        // We use setTimeout to place this block of code at the very end of the
        // event queue, because the blur event is processed before the next
        // active element is focused. And we only want to focus the new value
        // input if there's no other element focused.
        setTimeout(() => {
            if (document.activeElement !== null && document.activeElement !== document.body) {
                return;
            }

            if (this.newEducationRef.current) {
                this.newEducationRef.current.focus();
            }
        }, 0);
    };
}

function createNewEducation() {
    return {
        id: uuid(),
        field: "",
        degree: "",
        institute: "",
        info: "",
        dateFrom: "",
        dateTo: "",
    };
}
