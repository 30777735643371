import {formatMessageMaybe} from "~/util/ui-translations";

export function enumMethods(name, enumValues) {
    return {
        values() {
            return Object.values(enumValues);
        },
        selectOptions(intl) {
            return this.values().map(value => ({
                value,
                label: intl ? this.translate(intl, value) : value,
            }));
        },
        translate(intl, value) {
            return formatMessageMaybe(intl, {id: `enum.${name}.${value}`}, undefined, value);
        },
    };
}
