import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import contentDisposition from "content-disposition";
import objectPath from "object-path";
import equal from "deep-equal";
import Api from "~/api";
import config from "~/config";
import CandidateMatchList from "~/components/CandidateMatchList";
import MatchDetailsDialog from "~/components/MatchDetailsDialog";
import MatchRequestPropType from "~/prop-types/match-request";
import {mergeProfiles} from "~/util/match-profile";
import {getCandidateMatchDisplayValue} from "~/util/match-display-values";
import HorizontalMatchPageLayout from "~/components/HorizontalMatchPageLayout";
import {getJobFunctionLabel} from "~/components/CandidateMatchList/CandidateMatchList";
import {combineSelection, hasEnabledFilters} from "~/util/misc";
import {BlackWhiteList} from "~/util/black-white-list";
import {isGreenMatch} from "~/util/match";
import MatchComparisonDialog from "~/components/MatchComparisonDialog/MatchComparisonDialog";
import OnDemandEntity from "~/entities/OnDemandEntity";
import MatchResultsPaginationBar from "~/components/MatchResultsPaginationBar";
import styles from "./styles.module.scss";
import SmsCandidatesDialog from "~/components/SmsCandidatesDialog/SmsCandidatesDialog";

class ProfileToCandidates extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        query: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        jobMatchingStrategy: PropTypes.string,
        selection: PropTypes.object.isRequired,
        fixedSelection: PropTypes.object.isRequired,
        matchRequest: MatchRequestPropType.isRequired,
        lastMatchRequest: MatchRequestPropType,
        matches: PropTypes.object.isRequired,
        displayedMatches: PropTypes.array.isRequired,
        displayedPage: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalMatchCount: PropTypes.number,
        isMatching: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired, // react-router
        updateMatchProfile: PropTypes.func.isRequired,
        updateFilters: PropTypes.func.isRequired,
        updateSortMode: PropTypes.func.isRequired,
        updateQuery: PropTypes.func.isRequired,
        updateLanguage: PropTypes.func.isRequired,
        updateMatchingStrategy: PropTypes.func.isRequired,
        clearMatchProfile: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        setDisplayedPage: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        updateSelection: PropTypes.func.isRequired,
        clearSelection: PropTypes.func.isRequired,
        updateFixedSelection: PropTypes.func.isRequired,
        clearFixedSelection: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            displayedMatch: null,
            comparedMatches: null,
            smsMatches: null,
            parsingQuery: false,
            matchingEntity: undefined,
            defaultMatchProfile: undefined,
            matchClicked: false,
            makingSelection: false,
        };

        this.matchPageRef = React.createRef();
        this.matchListRef = React.createRef();
    }

    componentDidMount() {
        this.useMatchingEntity(this.createMatchingEntity());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateMatchingEntity(prevProps);
    }

    // TODO: This matchingEntity stuff should probably go into a HOC or something?
    createMatchingEntity() {
        const {match, matchingStrategy, jobMatchingStrategy} = this.props;

        return OnDemandEntity.fromMatchParams(
            {
                [OnDemandEntity.Type.JOB]: config("features.profileToJobs.jobIndex"),
                [OnDemandEntity.Type.CANDIDATE]: config(
                    "features.profileToCandidates.candidateIndex"
                ),
            },
            OnDemandEntity.Type.JOB,
            match.params,
            {
                jobMatchingStrategy,
                candidateMatchingStrategy: matchingStrategy,
            }
        );
    }

    useMatchingEntity(matchingEntity) {
        const {updateMatchProfile, clearFilters} = this.props;

        if (matchingEntity !== undefined) {
            this.matchPageRef.current.toggleMatchProfileTab(false);
            this.matchPageRef.current.toggleResultsTab(true);
            this.setState({matchingEntity});

            matchingEntity.documentPromise
                .then(document => {
                    this.setState({defaultMatchProfile: document.matchProfile});
                    updateMatchProfile(document.matchProfile);
                    clearFilters();
                    this.handleMatch();
                })
                .catch(error => {
                    this.clearMatchingEntity();
                    throw error;
                });
        } else {
            this.clearMatchingEntity();
        }
    }

    clearMatchingEntity() {
        const {clearMatchProfile, clearFilters} = this.props;

        this.setState({
            matchingEntity: undefined,
            defaultMatchProfile: undefined,
        });

        clearMatchProfile();
        clearFilters();
    }

    updateMatchingEntity(prevProps) {
        const {location} = this.props;

        if (location === prevProps.location) {
            return;
        }

        const {matchingEntity} = this.state;
        const nextMatchingEntity = this.createMatchingEntity();

        if (!OnDemandEntity.areSame(matchingEntity, nextMatchingEntity)) {
            this.useMatchingEntity(nextMatchingEntity);
        }
    }

    render() {
        const {
            intl,
            query,
            language,
            matchingStrategy,
            matchRequest,
            lastMatchRequest,
            totalMatchCount,
            isMatching,
            updateQuery,
            updateLanguage,
            updateMatchingStrategy,
        } = this.props;
        const {parsingQuery, defaultMatchProfile, matchingEntity} = this.state;
        const availableFilters = config("features.profileToCandidates.filters", []);
        const hasKeywordSearch = config("features.profileToCandidates.keywordSearch", false);

        const entityDisplayName = matchingEntity ? matchingEntity.displayName : undefined;
        const profileLabel = intl.formatMessage({id: "match.jobProfile"}) + (this.isUsingDefaultMatchProfile() && entityDisplayName ? ` (${entityDisplayName})` : "");

        return (
            <HorizontalMatchPageLayout
                keywordQuery={query}
                indexName={config("features.profileToCandidates.candidateIndex")}
                language={language}
                matchingStrategy={matchingStrategy}
                matchProfile={matchRequest.matchProfile}
                lastMatchRequest={lastMatchRequest}
                availableFilters={availableFilters}
                filters={matchRequest.filters}
                isMatching={isMatching || parsingQuery}
                resultCount={totalMatchCount}
                showResetButton={defaultMatchProfile !== undefined}
                showKeywordSearch={hasKeywordSearch}
                showSuggestions={config("features.profileToCandidates.keywordSuggestions", false)}
                sortMode={matchRequest.sortMode}
                sortModeGroup={config("features.profileToCandidates.sortModeGroup", undefined)}
                profileLabel={profileLabel}
                resultsLabel={intl.formatMessage({id: "match.candidateResults"})}
                onKeywordQueryChange={updateQuery}
                onMatchProfileChange={this.handleProfileChange}
                onFiltersChange={this.handleFiltersChange}
                onMatch={this.handleMatch}
                onClear={this.handleClear}
                onReset={this.handleReset}
                onSortModeChange={this.handleSortModeChange}
                onLanguageChange={updateLanguage}
                onMatchingStrategyChange={updateMatchingStrategy}
                ref={this.matchPageRef}
            >
                {this.renderResults()}
            </HorizontalMatchPageLayout>
        );
    }

    renderResults() {
        const {
            selection,
            fixedSelection,
            lastMatchRequest,
            displayedMatches,
            isMatching,
            updateSelection,
        } = this.props;
        const {matchClicked} = this.state;
        const showMatchButton = config("features.profileToCandidates.showMatchButton", false);

        return (
            <div className={styles.resultsWrapper}>
                <CandidateMatchList
                    ref={this.matchListRef}
                    className={styles.matchList}
                    matches={displayedMatches}
                    allowSelection={
                        config("features.profileToCandidates.allowSelection", false) ||
                        config("features.profileToCandidates.allowComparison", false)
                    }
                    wasSearched={matchClicked && !isMatching}
                    selection={selection}
                    fixedSelection={fixedSelection}
                    extraColumns={config("features.profileToCandidates.extraColumns", [])}
                    noResultsLabel={
                        !hasEnabledFilters(lastMatchRequest.filters)
                            ? "match.noResults"
                            : "match.noResultsFiltersHint"
                    }
                    isGreenMatch={this.isGreenMatch}
                    onView={this.handleView}
                    onMatch={showMatchButton ? this.handleMatchToOthers : undefined}
                    onSelectionChange={updateSelection}
                />
                {this.renderPaginationBar()}
                {this.renderDetailsDialog()}
                {this.renderComparisonDialog()}
                {this.renderSmsDialog()}
            </div>
        );
    }

    renderPaginationBar() {
        const {displayedPage, selection, pageSize, totalMatchCount, lastMatchRequest} = this.props;
        const {makingSelection} = this.state;

        const allowSelection = config("features.profileToCandidates.allowSelection", false);
        const allowDownload = config("features.profileToCandidates.allowDownload", false);
        const allowComparison = config("features.profileToCandidates.allowComparison", false);
        const selectionSize = Object.keys(selection).length;

        return (
            <MatchResultsPaginationBar
                page={displayedPage}
                pageSize={pageSize}
                totalItems={totalMatchCount || 0}
                selectionSize={selectionSize}
                makingSelection={makingSelection}
                canDownload={lastMatchRequest && allowDownload}
                canSelect={allowSelection && this.isMatchingJob()}
                canCompare={allowComparison}
                fakeButtons={
                    this.isMatchingJob()
                        ? config("features.profileToCandidates.fakeButtons", [])
                        : []
                }
                selectLabel="temporary.selectCandidates"
                compareLabel="compareCandidates"
                onPageChange={this.handlePageChange}
                onDownload={this.handleDownload}
                onSelect={this.handleSelection}
                onCompare={this.handleCompare}
                onSms={this.handleSms}
            />
        );
    }

    renderDetailsDialog = () => {
        const {intl} = this.props;
        const {displayedMatch, matchingEntity} = this.state;

        if (!displayedMatch) {
            return null;
        }

        const reverseLabel = getDetailsTitleAsConfigured(displayedMatch.candidate);
        const forwardLabel =
            matchingEntity && this.wasUsingDefaultMatchProfile()
                ? matchingEntity.displayName
                : undefined;
        const forwardSubLabel = forwardLabel
            ? intl.formatMessage({id: "matchQuality.searchProfile"})
            : undefined;

        return (
            <MatchDetailsDialog
                match={displayedMatch}
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.profileToCandidates.extraProperties"
                )}
                getMatchDisplayValue={this.getMatchDisplayValue}
                title={intl.formatMessage(
                    {id: "detailsModal.title"},
                    {subject: getMatchDetailsTitleSubject(displayedMatch)}
                )}
                forwardLabel={
                    forwardLabel || intl.formatMessage({id: "matchQuality.searchProfile"})
                }
                forwardSubLabel={forwardSubLabel}
                reverseLabel={
                    reverseLabel || intl.formatMessage({id: "matchQuality.candidateColumn"})
                }
                onHide={this.handleHideDetailsDialog}
            />
        );
    };

    renderComparisonDialog = () => {
        const {comparedMatches} = this.state;

        if (!comparedMatches) {
            return null;
        }

        return (
            <MatchComparisonDialog
                matches={comparedMatches}
                titleLabel="compareCandidates"
                reverseLabel="reverse.candidateToProfile"
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.profileToCandidates.extraProperties"
                )}
                getMatchDisplayValue={this.getMatchDisplayValue}
                onHide={this.handleHideComparisonDialog}
            />
        );
    };

    renderSmsDialog = () => {
        const {smsMatches, matchingEntity} = this.state;

        if (!smsMatches || !this.isMatchingJob()) {
            return null;
        }

        return (
            <SmsCandidatesDialog
                job={matchingEntity.document}
                matches={smsMatches}
                getMatchDisplayValue={this.getMatchDisplayValue}
                onHide={this.handleHideSmsDialog}
            />
        );
    };

    isGreenMatch = match => {
        const {lastMatchRequest} = this.props;
        return isGreenMatch(match, lastMatchRequest);
    };

    getMatchDisplayValue = (match, section) => {
        if (section === "additionalText") {
            // const {jobText} = this.state;
            //
            // return jobText
            //     ? {
            //           label: "detailsModal.jobText",
            //           value: jobText,
            //       }
            //     : undefined;
            return undefined;
        } else if (section === "header") {
            return getMatchDetailsTitleSubject(match);
        } else {
            return getCandidateMatchDisplayValue(match, section);
        }
    };

    isMatchingJob() {
        const {matchingEntity} = this.state;
        return matchingEntity && matchingEntity.type === OnDemandEntity.Type.JOB;
    }

    isUsingDefaultMatchProfile() {
        const {defaultMatchProfile} = this.state;
        const {matchRequest} = this.props;

        if (defaultMatchProfile) {
            return equal(matchRequest.matchProfile, defaultMatchProfile, {strict: true});
        } else {
            return false;
        }
    }

    wasUsingDefaultMatchProfile() {
        const {defaultMatchProfile} = this.state;
        const {lastMatchRequest} = this.props;

        if (defaultMatchProfile) {
            return equal(lastMatchRequest.matchProfile, defaultMatchProfile, {strict: true});
        } else {
            return false;
        }
    }

    fetchSelectedCandidates(ensureSelection) {
        const {matchingEntity} = this.state;
        const selectionEndpoint = config("api.selectionEndpoints.job.get", undefined);

        if (!selectionEndpoint || !this.isMatchingJob()) {
            this.props.clearFixedSelection();
            return;
        }

        return Api.fetchSelectedCandidates(matchingEntity.id, selectionEndpoint).then(selection =>
            this.props.updateFixedSelection(combineSelection(selection, ensureSelection))
        );
    }

    handleMatch = parseAndMatch => {
        if (parseAndMatch === true) {
            // TODO: Make sure no event is passed here?
            this.handleParseAndMatch();
        } else {
            const {setDisplayedPage, requestPage, clearSelection} = this.props;

            requestPage(0, true).then(() => {
                setDisplayedPage(0);
            });

            this.setState({matchClicked: true});
            clearSelection();
            this.fetchSelectedCandidates();

            this.matchPageRef.current.openResultsTab();
        }
    };

    handleReset = () => {
        const {updateMatchProfile, clearFilters} = this.props;
        const {defaultMatchProfile} = this.state;

        updateMatchProfile(defaultMatchProfile);
        clearFilters(); // TODO
    };

    handleParseAndMatch = () => {
        const {query, language, matchRequest, updateMatchProfile, updateQuery} = this.props;

        if (query.trim() !== "") {
            updateQuery("");
            this.setState({parsingQuery: true});

            Api.parseQuery(query, language).then(matchProfile => {
                updateMatchProfile(mergeProfiles(matchRequest.matchProfile, matchProfile));
                this.handleMatch();
                this.setState({parsingQuery: false});
            });
        } else {
            updateQuery("");
            this.handleMatch();
        }
    };

    handleClear = () => {
        this.props.clearMatchProfile();
        this.props.clearFilters();
        this.matchPageRef.current.resetMatchProfileEditor();
        this.matchPageRef.current.openMatchProfileTab();
    };

    handleProfileChange = matchProfile => {
        this.props.updateMatchProfile(matchProfile);
    };

    handleFiltersChange = filters => {
        this.props.updateFilters(filters);
    };

    handleSortModeChange = nextSortMode => {
        const {totalMatchCount} = this.props;
        this.props.updateSortMode(nextSortMode);
        if (totalMatchCount !== null) this.handleMatch();
    };

    handleView = id => {
        const {matches} = this.props;
        this.setState({displayedMatch: matches[id]});
    };

    handleMatchToOthers = (type, id) => {
        const {history} = this.props;

        switch (type) {
            case "jobs":
                history.push(`/profile-to-jobs/candidate/${id}`);
                break;

            case "candidates":
                history.push(`/profile-to-candidates/candidate/${id}`);
                break;

            default:
                return;
        }
    };

    handlePageChange = page => {
        const {setDisplayedPage, requestPage} = this.props;

        requestPage(page).then(() => {
            setDisplayedPage(page);
        });

        this.matchListRef.current.scrollToTop();
    };

    handleHideDetailsDialog = () => {
        this.setState({displayedMatch: null});
    };

    handleHideComparisonDialog = () => {
        this.setState({comparedMatches: null});
    };

    handleHideSmsDialog = () => {
        this.setState({smsMatches: null});
    };

    handleSelection = () => {
        const {matchingEntity} = this.state;
        const {selection, clearSelection} = this.props;
        const selectionSize = Object.keys(selection).length;
        const setSelectionEndpoint = config("api.selectionEndpoints.job.set", undefined);

        if (setSelectionEndpoint && this.isMatchingJob() && selectionSize > 0) {
            this.setState({makingSelection: true});

            Api.selectCandidatesForJob(matchingEntity.id, selection, setSelectionEndpoint)
                .then(result => {
                    if (!result.hasErrors) {
                        this.fetchSelectedCandidates(selection).then(() => {
                            this.setState({makingSelection: false});
                            clearSelection();
                        });
                    } else {
                        // Todo Show error somewhere
                        console.error(result.message);
                        this.setState({makingSelection: false});
                        clearSelection();
                    }
                })
                .catch(error => {
                    this.setState({makingSelection: false});
                    throw error;
                });
        }
    };

    handleCompare = () => {
        const {selection, matches} = this.props;

        this.setState({
            comparedMatches: Object.keys(selection)
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score),
        });
    };

    handleSms = () => {
        const {selection, matches} = this.props;

        this.setState({
            smsMatches: Object.keys(selection)
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score),
        });
    };

    handleDownload = () => {
        const {lastMatchRequest, language} = this.props;
        const candidateIndex = config("features.profileToCandidates.candidateIndex");
        const sortModeGroup = config("features.profileToCandidates.sortModeGroup", undefined);
        const scoreType = config("features.profileToCandidates.scoreType", "MUTUAL");
        const expansionType = config("features.profileToCandidates.expansionType", "FULL");
        const exportType = config("features.profileToCandidates.exportType", "csv");
        const exportSize = config("features.profileToCandidates.exportSize", 100);

        return Api.downloadProfileToCandidates({
            candidateIndex,
            matchRequest: lastMatchRequest,
            language,
            sortModeGroup,
            scoreType,
            expansionType,
            exportType,
            exportSize,
        }).then(({response, request}) => {
            const details = contentDisposition.parse(
                request.getResponseHeader("content-disposition")
            );
            const contentType = request.getResponseHeader("Content-Type");
            const blob = new Blob([response], {type: contentType});
            blob.name = details.parameters.filename;
            const reader = new FileReader();
            reader.onload = e => {
                const anchor = document.createElement("a");
                anchor.style.display = "none";
                anchor.href = e.target.result;
                anchor.download = blob.name;
                anchor.click();
            };
            reader.readAsDataURL(blob);
        });
    };
}

export function getMatchDetailsTitleSubject(match) {
    let titleSubject = getJobFunctionLabel(match);
    return getDetailsTitleAsConfigured(match.candidate) || titleSubject;
}

function getDetailsTitleAsConfigured(candidate) {
    const detailsTitleProperty = config(
        "features.profileToCandidates.detailsTitleProperty",
        undefined
    );

    if (detailsTitleProperty) {
        return objectPath.get(candidate, detailsTitleProperty);
    } else {
        return undefined;
    }
}

export default injectIntl(ProfileToCandidates, {forwardRef: true});
