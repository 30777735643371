import {combineReducers} from "redux";
import candidates from "./candidates";
import textMining from "./text-mining";
import jobToCandidates from "./job-to-candidates";
import jobs from "./jobs";
import matchResults from "./match-results";
import matchingStrategy from "./matching-strategy";
import monitoring from "./monitoring";
import profileToCandidates from "./profile-to-candidates";
import profileToJobs from "./profile-to-jobs";
import ui from "./ui";

const rootReducer = combineReducers({
    candidates,
    textMining,
    jobToCandidates,
    jobs,
    matchResults,
    matchingStrategy,
    monitoring,
    profileToCandidates,
    profileToJobs,
    ui,
});

export default rootReducer;