import React from "react";
import PropTypes from "prop-types";
import styles from "./candidate-editor.module.scss";

export default class Description extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        isEditing: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditing: false,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    focus() {
        this.inputRef.current.focus();
    }

    render() {
        const {value, isEditing} = this.props;

        return (
            <textarea
                disabled={!isEditing}
                value={value}
                className={styles.description}
                onChange={this.handleChange}
                ref={this.inputRef}
            />
        );
    }

    handleChange = e => {
        const {onChange} = this.props
        onChange(e.target.value)
    }
}
