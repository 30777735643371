import Api from "~/api";
import config from "~/config";
import {clearFilters, requestPage, updateMatchProfile} from "~/actions/profile-to-candidates";

export const UPDATE_CANDIDATE = "candidates/UPDATE_CANDIDATE";
export const SET_FETCHING = "candidates/SET_FETCHING";
export const ADD_RESULTS = "candidates/ADD_RESULTS";
export const SET_PAGE = "candidates/SET_PAGE";

export function fetchCandidate(id) {
    return dispatch => {
        const candidateIndex = "TODO";
        return Api.fetchCandidate(candidateIndex, id);
    };
}

export function fetchCandidates(from, size) {
    return dispatch => {
        const candidateIndex = config("features.candidateList.candidateIndex");
        dispatch(setFetching(true));

        return Api.fetchCandidates(candidateIndex, from, size)
            .then(({metadata, candidates}) => {
                dispatch(setFetching(false));
                dispatch(addResults(metadata, candidates));
            })
            .catch(e => {
                dispatch(setFetching(false));
                throw e;
            });
    };
}

export function matchCandidate(id) {
    return dispatch => {
        const candidateIndex = config("features.candidateList.candidateIndex");
        return Api.fetchCandidate(candidateIndex, id)
            .then(candidate => {
                dispatch(updateMatchProfile(candidate.matchProfile))
                dispatch(clearFilters());
                dispatch(requestPage(0, true));
            });
    };
}

export function createOrUpdateCandidate(id, candidate) {
    return dispatch => {
        const candidateIndex = "TODO";
        const promise = Api.createOrUpdateCandidate(candidateIndex, id, candidate);

        promise.then(() => {
            dispatch(updateCandidate(id, candidate));
        });

        return promise;
    };
}

function setFetching(isFetching) {
    return {
        type: SET_FETCHING,
        isFetching,
    };
}

function addResults(metadata, candidates) {
    return {
        type: ADD_RESULTS,
        count: metadata.count,
        candidates,
    }
}

function updateCandidate(id, candidate) {
    return {
        type: UPDATE_CANDIDATE,
        id,
        candidate,
    };
}

export function setPage(page) {
    return {
        type: SET_PAGE,
        page,
    }
}