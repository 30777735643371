import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Spinner from "react-bootstrap/Spinner";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import SortSelect from "~/components/SortSelect";

export default class Results extends React.PureComponent {
    static propTypes = {
        resultsLabel: PropTypes.string.isRequired,
        expanded: PropTypes.bool.isRequired,
        content: PropTypes.any,
        sortMode: PropTypes.string,
        sortModeGroup: PropTypes.string,
        isMatching: PropTypes.bool.isRequired,
        resultCount: PropTypes.number,
        renderExtraContent: PropTypes.func,
        onToggleExpanded: PropTypes.func.isRequired,
        onSortModeChange: PropTypes.func.isRequired,
    };

    render() {
        const {expanded, isMatching, resultsLabel, renderExtraContent, onToggleExpanded} = this.props;

        return (
            <div className={c(styles.main, {expanded})}>
                <div className={styles.titleBar}>
                    <div className={styles.resultsLabel} onClick={onToggleExpanded}>
                        <i className={c("fas", expanded ? "fa-caret-down" : "fa-caret-right")} />
                        <span className={styles.underlined}>{resultsLabel}</span>
                        <span>
                            {this.renderResultsCount()}
                            {isMatching && (
                                <span className="ml-2 text-secondary">
                                    <Spinner animation="grow" role="status" size="sm" />{" "}
                                    <FormattedMessage id="progress.matching" />
                                </span>
                            )}
                        </span>
                        <div className={styles.extraContent} onClick={stopPropagation}>
                            {this.renderSortSelect()}
                            {renderExtraContent && renderExtraContent()}
                        </div>
                    </div>
                </div>
                {this.renderContent()}
            </div>
        );
    }

    renderResultsCount() {
        const {resultCount} = this.props;

        if (resultCount === null) {
            return null;
        }

        return (
            <span className="ml-3 text-secondary">
                <FormattedMessage id="results.count" values={{count: resultCount}} />
            </span>
        );
    }

    renderSortSelect() {
        const {sortMode, sortModeGroup, onSortModeChange} = this.props;
        if (!sortModeGroup) return;

        return (
            <SortSelect
                className={styles.sortSelect}
                selected={sortMode}
                variant="small"
                sortModeGroup={sortModeGroup}
                onChange={onSortModeChange}
            />
        );
    }

    renderContent() {
        const {expanded, content} = this.props;

        if (!expanded) {
            return null;
        }

        return <div className={styles.resultsContent}>{content}</div>;
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}
