import {createSelector} from "reselect";

const displayedPageSelector = state => state.jobToCandidates.displayedPage;
const resultSetSelector = state => state.matchResults.resultSets.jobToCandidates;

export const displayedMatchesSelector = createSelector(
    resultSetSelector,
    displayedPageSelector,
    (resultSet, displayedPage) => {
        if (resultSet === undefined) {
            return [];
        }

        const pageData = resultSet.pages[displayedPage];

        if (pageData === undefined) {
            return [];
        }

        return pageData.ids.map(id => resultSet.matches[id]);
    }
);

export const isMatchingSelector = createSelector(
    resultSetSelector,
    resultSet => {
        for (const page in resultSet.pages) {
            if (resultSet.pages[page].fetching) return true;
        }

        return false;
    }
);
