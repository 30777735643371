import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import EducationPropType from "~/prop-types/education";
import ListGroup from "react-bootstrap/ListGroup";
import DateInput from "~/components/DateInput";

export default class Education extends React.PureComponent {
    static propTypes = {
        value: EducationPropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    render() {
        return (
            <ListGroup.Item>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    component="div"
                >
                    {wrapperProps => (
                        <div {...wrapperProps} tabIndex="0">
                            {this.renderContents()}
                        </div>
                    )}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderContents() {
        const {isEditing} = this.props;
        return isEditing ? this.renderContentsEdit() : this.renderContentsDisplay();
    }

    renderContentsDisplay() {
        const {value} = this.props;

        return (
            <React.Fragment>
                <div>
                    Field: {value.field}, degree: {value.degree}, institute: {value.institute}
                </div>
                <p>{value.info}</p>
                <div>
                    From {value.dateFrom} to {value.dateTo}
                </div>
            </React.Fragment>
        );
    }

    renderContentsEdit() {
        const {value} = this.props;

        return (
            <React.Fragment>
                <div className="d-flex">
                    <label htmlFor={`field-${value.id}`}>Field</label>
                    <input
                        id={`field-${value.id}`}
                        value={value.field || ""}
                        onChange={this.handleFieldChange}
                        ref={this.inputRef}
                    />
                    <label htmlFor={`degree-${value.id}`}>Degree</label>
                    <input
                        id={`degree-${value.id}`}
                        value={value.degree || ""}
                        onChange={this.handleDegreeChange}
                    />
                    <label htmlFor={`institute-${value.id}`}>Institute</label>
                    <input
                        id={`institute-${value.id}`}
                        value={value.institute || ""}
                        onChange={this.handleInstituteChange}
                    />
                </div>
                <textarea
                    placeholder="Info"
                    value={value.info || ""}
                    onChange={this.handleInfoChange}
                    className="w-100"
                    rows={3}
                />
                <div className="d-flex">
                    <label htmlFor={`date-form-${value.id}`}>From</label>
                    <DateInput
                        id={`date-form-${value.id}`}
                        value={value.dateFrom}
                        onChange={this.handleDateFromChange}
                    />
                    <label htmlFor={`date-to-${value.id}`}>To</label>
                    <DateInput
                        id={`date-to-${value.id}`}
                        value={value.dateTo}
                        onChange={this.handleDateToChange}
                    />
                </div>
            </React.Fragment>
        );
    }

    handleFieldChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, field: e.target.value});
    };

    handleDegreeChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, degree: e.target.value});
    };

    handleInstituteChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, institute: e.target.value});
    };

    handleInfoChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, info: e.target.value});
    };

    handleDateFromChange = dateFrom => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, dateFrom});
    };

    handleDateToChange = dateTo => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, dateTo});
    };

    normalizeValue = () => {
        const {value, isEditing, onDelete} = this.props;

        if (
            isEditing &&
            onDelete &&
            !value.field &&
            !value.degree &&
            !value.institute &&
            !value.info
        ) {
            onDelete(value.id);
        }
    };
}
