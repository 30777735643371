import {
    convertJobToApi,
    convertCandidateToApi,
    convertMatchRequestToApi,
} from "~/util/convert-to-api";
import {
    convertJobFromApi,
    convertCandidateFromApi,
    convertParsedQueryItemsMatchProfile,
} from "~/util/convert-from-api";
import {removeDuplicateValues, removeUndefinedValues} from "~/util/misc";

export default function addCommonCalls(Client) {
    Client.prototype.parseJob = function(job) {
        return this.post("/parsing/parseJob", {
            title: job.title,
            text: job.description,
            locale: "bel_dut",
        });
    };

    Client.prototype.matchProfileToCandidates = function({
        candidateIndex,
        matchRequest,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        matchingStrategy,
    }) {
        return this.post(
            `/hrmatching/match2candidates/${candidateIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, language, sortModeGroup),
                from,
                size,
                queryParams: {
                    scoreType,
                    expansionType,
                },
            }),
            removeUndefinedValues({
                explain: true,
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(match => ({
                id: match.id,
                score: match.score,
                candidate: convertCandidateFromApi(match.document),
                details: match.details,
                explanations: match.explanations,
            }));

            return {matches, metadata};
        });
    };

    Client.prototype.downloadProfileToCandidates = function({
        candidateIndex,
        matchRequest,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        exportType,
        exportSize,
    }) {
        return this.post(
            `/hrmatching/match2candidates/${candidateIndex}/${exportType}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, language, sortModeGroup),
                from: 0,
                size: exportSize,
                queryParams: {
                    scoreType,
                    expansionType,
                },
            }),
            {},
            {},
            true,
            "arraybuffer"
        );
    };

    Client.prototype.matchProfileToJobs = function({
        jobIndex,
        matchRequest,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        matchingStrategy,
    }) {
        return this.post(
            `/hrmatching/match2jobs/${jobIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, language, sortModeGroup),
                from,
                size,
                queryParams: {
                    scoreType,
                    expansionType,
                },
            }),
            removeUndefinedValues({
                explain: true,
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(match => ({
                id: match.id,
                score: match.score,
                job: convertJobFromApi(match.document),
                details: match.details,
                explanations: match.explanations,
            }));

            return {matches, metadata};
        });
    };

    Client.prototype.parseQuery = function(query, language) {
        return this.post(`/ontology/parseQuery`, {
            query,
            lang: language,
        }).then(({response}) => {
            return convertParsedQueryItemsMatchProfile(response.parsedQueryItems);
        });
    };

    Client.prototype.fetchJob = function(jobIndex, id, matchingStrategy) {
        return this.get(
            `/hrmatching/jobs_index/${jobIndex}/doc/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => convertJobFromApi(response));
    };

    Client.prototype.fetchSelectedCandidates = function(jobId, endpoint) {
        return this.get(`${endpoint}/${jobId}`)
            .then(({response}) => {
                let results = {};
                response.forEach(id => (results[id] = true));
                return results;
            });
    };

    Client.prototype.selectCandidatesForJob = function(jobId, selection, endpoint) {
        let body = {
            subjectId: jobId,
            selectionIds: Object.keys(selection).filter(id => selection[id]),
        };

        return this.post(endpoint, body)
            .then(({response}) => {
                if (response.hasErrors && !response.message) {
                    for (const result in response.results) {
                        if (result.errors && result.errors.length > 0) {
                            let firstError = result.errors[0];
                            return {
                                hasErrors: true,
                                message: firstError.message,
                            };
                        }
                    }

                    return {
                        hasErrors: true,
                        message: "Failed to add candidate selections.",
                    };
                } else {
                    return {
                        hasErrors: response.hasErrors,
                        message: response.message,
                    };
                }
            })
            .catch(e => {
                console.error(e);

                return {
                    hasErrors: true,
                    message: "Failed to add candidate selections.",
                };
            });
    };

    Client.prototype.fetchSelectedJobs = function(candidateId, endpoint) {
        return this.get(`${endpoint}/${candidateId}`)
            .then(({response}) => {
                let results = {};
                response.forEach(id => (results[id] = true));
                return results;
            });
    };

    Client.prototype.selectJobsForCandidate = function(candidateId, selection, endpoint) {
        let body = {
            subjectId: candidateId,
            selectionIds: Object.keys(selection).filter(id => selection[id]),
        };

        return this.post(endpoint, body)
            .then(({response}) => {
                return {
                    hasErrors: response.hasErrors,
                    message: response.message,
                };
            })
            .catch(e => {
                console.error(e);

                return {
                    hasErrors: true,
                    message: "Failed to add job selections.",
                };
            });
    };

    Client.prototype.fetchJobs = function(jobIndex, from, size) {
        return this.post(`/hrmatching/match2jobs/${jobIndex}`, {
            from,
            size,
        }).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const jobs = response.matches.map(match => {
                const job = convertJobFromApi(match.document);
                job.id = match.id;
                return job;
            });

            return {metadata, jobs};
        });
    };

    Client.prototype.createOrUpdateJob = function(jobIndex, id, job) {
        return this.put(`/hrmatching/jobs_index/${jobIndex}/doc/${id}`, convertJobToApi(job));
    };

    Client.prototype.fetchCandidate = function(candidateIndex, id, matchingStrategy) {
        return this.get(
            `/hrmatching/candidates_index/${candidateIndex}/doc/${id}`,
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => convertCandidateFromApi(response));
    };

    Client.prototype.fetchCandidates = function(candidateIndex, from, size) {
        return this.post(`/hrmatching/match2candidates/${candidateIndex}`, {
            from,
            size,
        }).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const candidates = response.matches.map(match => {
                const candidate = convertCandidateFromApi(match.document);
                candidate.id = match.id;
                return candidate;
            });

            return {metadata, candidates};
        });
    };

    Client.prototype.createOrUpdateCandidate = function(candidateIndex, id, candidate) {
        return this.put(
            `/hrmatching/candidates_index/${candidateIndex}/doc/${id}`,
            convertCandidateToApi(candidate)
        );
    };

    Client.prototype.textMiningParse = function(parser, input, inputType) {
        return this.post(
            `/parsing/parse/${parser}/toJson`,
            input,
            {inputType: inputType, features: "LEMMA_SPLIT_SPELL"},
            {"Content-Type": "text/plain"}
        );
    };

    Client.prototype.textMiningNGrams = function(input) {
        return this.post(
            "/stats/ngrams/1,2,3,4,5,6/DUT,ENG",
            input,
            {cutoff: 2, cutoffType: "MIN_FREQUENCY"},
            {"Content-Type": "text/plain"}
        );
    };

    Client.prototype.findPrefix = function(input, language, conceptTypes = ["AGENT", "PROPERTY"]) {
        return this.post(
            "/ontology/findPrefix",
            {text: input, lang: language, conceptTypes: conceptTypes},
            {filterOnIndex: false}
        );
    };

    Client.prototype.findPrefixStrings = function(input, language, conceptTypes) {
        // Reverse sort order for types (last in the list will come first in results)
        const typeSortOrder = ["PROPERTY", "AGENT"];

        return this.findPrefix(input, language, conceptTypes)
            .then(({response}) =>
                response
                    .filter(item => item.matchedTerm.language === language)
                    .sort((a, b) => typeSortOrder.indexOf(b.type) - typeSortOrder.indexOf(a.type))
                    .map(item => item.matchedTerm.text)
            )
            .then(removeDuplicateValues);
    };

    Client.prototype.keywordSuggestions = function(index, keywords, language) {
        return this.post(`/hrmatching/suggest/${index}`, {keywords, language});
    };
}
