import {
    UPDATE_MATCH_PROFILE,
    SET_DISPLAYED_PAGE,
    UPDATE_FILTERS,
    UPDATE_SORT_MODE,
    UPDATE_SELECTION,
    UPDATE_FIXED_SELECTION,
    UPDATE_QUERY,
    SET_LAST_MATCH_REQUEST,
    UPDATE_MATCHING_STRATEGY,
} from "~/actions/profile-to-jobs";
import config from "~/config";
import emptyMatchProfile from "~/data/empty-match-profile.json";
import {importProfile} from "~/util/match-profile";
import {getDefaultSort} from "~/util/sort-config";
import {getInitialFilterState} from "~/util/filters-config";

export const INITIAL_MATCH_PROFILE = importProfile(emptyMatchProfile);
export const INITIAL_FILTERS = getInitialFilterState(config("features.profileToJobs.filters", []));

const INITIAL_STATE = {
    query: "",
    matchRequest: {
        matchProfile: INITIAL_MATCH_PROFILE,
        filters: INITIAL_FILTERS,
        sortMode: getDefaultSort(config("features.profileToJobs.sortModeGroup", undefined)),
    },
    lastMatchRequest: {
        matchProfile: importProfile(emptyMatchProfile),
        filters: {},
        sortMode: getDefaultSort(config("features.profileToJobs.sortModeGroup", undefined)),
    },
    selection: {},
    fixedSelection: {},
    matchingStrategy: null,
    displayedPage: 0,
    pageSize: 20,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_MATCH_PROFILE:
            return updateMatchProfile(state, action.matchProfile);

        case UPDATE_FILTERS:
            return updateFilters(state, action.filters);

        case UPDATE_SORT_MODE:
            return updateSortMode(state, action.sortMode);

        case UPDATE_QUERY:
            return updateQuery(state, action.query);

        case SET_DISPLAYED_PAGE:
            return setDisplayedPage(state, action.displayedPage);

        case UPDATE_SELECTION:
            return updateSelection(state, action.selection);

        case UPDATE_FIXED_SELECTION:
            return updateFixedSelection(state, action.fixedSelection);

        case SET_LAST_MATCH_REQUEST:
            return setLastMatchRequest(state, action.matchRequest);

        case UPDATE_MATCHING_STRATEGY:
            return updateMatchingStrategy(state, action.matchingStrategy);

        default:
            return state;
    }
}

function updateMatchProfile(state, matchProfile) {
    return {
        ...state,
        matchRequest: {
            ...state.matchRequest,
            matchProfile,
        },
    };
}

function updateFilters(state, filters) {
    return {
        ...state,
        matchRequest: {
            ...state.matchRequest,
            filters,
        },
    };
}

function updateSortMode(state, sortMode) {
    return {
        ...state,
        matchRequest: {
            ...state.matchRequest,
            sortMode,
        },
    };
}

function updateQuery(state, query) {
    return {
        ...state,
        query,
    };
}

function setDisplayedPage(state, displayedPage) {
    return {
        ...state,
        displayedPage,
    };
}

function updateSelection(state, selection) {
    return {
        ...state,
        selection,
    };
}

function updateFixedSelection(state, fixedSelection) {
    return {
        ...state,
        fixedSelection,
    };
}

function setLastMatchRequest(state, matchRequest) {
    return {
        ...state,
        lastMatchRequest: matchRequest,
    };
}

function updateMatchingStrategy(state, matchingStrategy) {
    return {...state, matchingStrategy};
}
