import {connect} from "react-redux";
import CandidateCreate from "~/pages/CandidateCreate";
import {createOrUpdateCandidate} from "~/actions/candidates";

function mapStateToProps(state) {
    return {
        //
    };
}

const mapDispatchToProps = {
    createOrUpdateCandidate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateCreate);
