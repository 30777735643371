import Api from "~/api";
import {getDetailsTitleAsConfigured} from "~/pages/job-to-candidates/JobToCandidatesMatch/JobToCandidatesMatch";

const Type = {
    JOB: "job",
    CANDIDATE: "candidate",
};

const State = {
    NOT_LOADED: "notLoaded",
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error",
};

/**
 * Specifically meant for use on profile-to-candidates and profile-to-jobs pages to help handling loadin the match profile
 * when matching from a job/candidate.
 */
export default class OnDemandEntity {
    constructor(indices, type, id, matchingStrategies) {
        this.indices = indices; // Type => index name
        this.type = type;
        this.id = id;
        this.state = State.NOT_LOADED;
        this.matchingStrategies = matchingStrategies;
        this._documentPromise = undefined; // promise
        this._document = undefined; // actual data
    }

    static fromMatchParams(indices, defaultType, matchParams, matchingStrategies) {
        const type = matchParams.type !== undefined ? matchParams.type : defaultType;
        const id = matchParams.id;

        if (!indices || !type || !id) {
            return undefined;
        }

        return new OnDemandEntity(indices, type, id, matchingStrategies);
    }

    static areSame(a, b) {
        if (a === undefined && b === undefined) {
            return true;
        } else if (a !== undefined && b !== undefined) {
            return a.equals(b);
        } else {
            return false;
        }
    }

    get documentPromise() {
        if (this.state === State.NOT_LOADED) {
            this._documentPromise = this._fetchDocument();
            this._documentPromise.then(document => (this._document = document));
        }

        return this._documentPromise;
    }

    get document() {
        return this._document;
    }

    get displayName() {
        if (!this.document) {
            return undefined;
        }

        switch (this.type) {
            case Type.JOB:
                return this.document.title;

            case Type.CANDIDATE:
                return getDetailsTitleAsConfigured(this.document);

            default:
                return undefined;
        }
    }

    equals(other) {
        return this.type === other.type && this.id === other.id;
    }

    _fetchDocument() {
        switch (this.type) {
            case Type.JOB:
                return this._fetchJobDocument();

            case Type.CANDIDATE:
                return this._fetchCandidateDocument();

            default:
                throw new Error("Unsupported OnDemandEntity type: " + this.type);
        }
    }

    _fetchJobDocument() {
        this.state = State.LOADING;

        return Api.fetchJob(
            this.indices[Type.JOB],
            this.id,
            this.matchingStrategies ? this.matchingStrategies.jobMatchingStrategy : undefined
        )
            .then(job => {
                this.state = State.LOADED;
                return job;
            })
            .catch(error => {
                this.state = State.ERROR;
                throw error;
            });
    }

    _fetchCandidateDocument() {
        this.state = State.LOADING;

        return Api.fetchCandidate(
            this.indices[Type.CANDIDATE],
            this.id,
            this.matchingStrategies ? this.matchingStrategies.candidateMatchingStrategy : undefined
        )
            .then(candidate => {
                this.state = State.LOADED;
                return candidate;
            })
            .catch(error => {
                this.state = State.ERROR;
                throw error;
            });
    }
}

OnDemandEntity.Type = Type;
OnDemandEntity.State = State;
