import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import emptyJob from "~/data/matching-demo-job.json"; // TODO change back to empty-job
import {importJob} from "~/util/job";
import JobEditor from "~/components/JobEditor";
import Modal from "react-bootstrap/Modal";

export default class JobCreate extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        parseJob: PropTypes.func.isRequired,
        createOrUpdateJob: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            job: importJob(emptyJob),
            isParsing: false,
            wasParsed: false,
            isCreating: false,
        };

        this.jobEditorRef = React.createRef();
        this.idInputRef = React.createRef();
    }

    render() {
        const {id, job, wasParsed} = this.state;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                ID:{" "}
                                <input
                                    value={id}
                                    onChange={this.handleIdChange}
                                    ref={this.idInputRef}
                                />
                            </div>
                            <JobEditor
                                job={job}
                                isEditing={true}
                                showMatchProfileEditor={wasParsed}
                                onChange={this.handleJobChange}
                                ref={this.jobEditorRef}
                            />
                            {this.renderButtons()}
                        </Col>
                    </Row>
                </Container>
                {this.renderModals()}
            </React.Fragment>
        );
    }

    renderButtons() {
        const {wasParsed} = this.state;

        if (!wasParsed) {
            return (
                <div>
                    <button type="button" onClick={this.handleParse}>
                        Parse
                    </button>
                </div>
            );
        } else {
            return (
                <div>
                    <button type="button" onClick={this.handleSubmit}>
                        Add to index
                    </button>
                </div>
            );
        }
    }

    renderModals() {
        const {isParsing, isCreating} = this.state;

        if (isParsing) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Parsing job...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Please wait while the job details are parsed by
                            theMatchBox.
                        </p>
                    </Modal.Body>
                </Modal>
            );
        } else if (isCreating) {
            return (
                <Modal show={true} centered>
                    <Modal.Header>
                        <Modal.Title>Adding job to index...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Please wait while the new job is being added to the
                            jobs index.
                        </p>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return null;
        }
    }

    handleIdChange = e => {
        this.setState({id: e.target.value});
    };

    handleJobChange = job => {
        this.setState({job});
    };

    handleParse = () => {
        const {job} = this.state;

        if (job.title === "") {
            this.jobEditorRef.current.focusTitle();
            return;
        } else if (job.description === "") {
            this.jobEditorRef.current.focusDescription();
            return;
        }

        this.setState({isParsing: true});

        this.props
            .parseJob(job)
            .then(job => {
                this.setState({job, isParsing: false, wasParsed: true});
            })
            .catch(e => {
                console.log(e);
                this.setState({isParsing: false});
            });
    };

    handleSubmit = () => {
        const {history} = this.props;
        const {id, job} = this.state;
        const finalId = id.trim();

        if (finalId === "") {
            this.idInputRef.current.focus();
            return;
        }

        const {createOrUpdateJob} = this.props;

        this.setState({isCreating: true});

        createOrUpdateJob(finalId, job)
            .then(() => {
                history.push(`/jobs/update/${finalId}`);
                this.setState({isCreating: false});
            })
            .catch(e => {
                console.error(e);
                this.setState({isCreating: false});
            });
    };
}
