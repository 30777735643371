import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import config from "~/config";
import CandidateMatchList from "~/components/CandidateMatchList";
import MatchDetailsDialog from "~/components/MatchDetailsDialog";
import MatchRequestPropType from "~/prop-types/match-request";
import JobPropType from "~/prop-types/job";
import {getCandidateMatchDisplayValue} from "~/util/match-display-values";
import HorizontalMatchPageLayout from "~/components/HorizontalMatchPageLayout";
import {hasEnabledFilters} from "~/util/misc";
import {BlackWhiteList} from "~/util/black-white-list";
import {isGreenMatch} from "~/util/match";
import JobToCandidatesBreadcrumbs from "~/containers/components/JobToCandidatesBreadcrumbs";
import MatchComparisonDialog from "~/components/MatchComparisonDialog/MatchComparisonDialog";
import {getJobFunctionLabel} from "~/components/CandidateMatchList/CandidateMatchList";
import objectPath from "object-path";
import styles from "./styles.module.scss";
import MatchResultsPaginationBar from "~/components/MatchResultsPaginationBar";

class JobToCandidatesMatch extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        job: JobPropType.isRequired,
        language: PropTypes.string.isRequired,
        matchingStrategy: PropTypes.string,
        selection: PropTypes.object.isRequired,
        matchRequest: MatchRequestPropType.isRequired,
        matches: PropTypes.object.isRequired,
        displayedMatches: PropTypes.array.isRequired,
        displayedPage: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalMatchCount: PropTypes.number,
        isParsing: PropTypes.bool.isRequired,
        isMatching: PropTypes.bool.isRequired,
        updateJob: PropTypes.func.isRequired,
        updateFilters: PropTypes.func.isRequired,
        updateSortMode: PropTypes.func.isRequired,
        clearMatchProfile: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        setDisplayedPage: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        updateSelection: PropTypes.func.isRequired,
        clearSelection: PropTypes.func.isRequired,
        updateLanguage: PropTypes.func.isRequired,
        updateMatchingStrategy: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            displayedMatch: null,
            comparedMatches: null,
            matchClicked: false,
        };

        this.matchProfileEditorRef = React.createRef();
        this.matchPageRef = React.createRef();
        this.resultsWrapper = React.createRef();
    }

    componentDidMount() {
        this.matchPageRef.current.openResultsTab();
    }

    render() {
        const {
            intl,
            language,
            matchingStrategy,
            job,
            matchRequest,
            totalMatchCount,
            isParsing,
            isMatching,
            updateLanguage,
            updateMatchingStrategy,
        } = this.props;
        const availableFilters = config("features.jobToCandidates.filters", []);

        return (
            <HorizontalMatchPageLayout
                additionalMenuContent={<JobToCandidatesBreadcrumbs />}
                isLoading={isParsing}
                indexName={config("features.jobToCandidates.candidateIndex")}
                language={language}
                matchingStrategy={matchingStrategy}
                matchProfile={job.matchProfile}
                availableFilters={availableFilters}
                filters={matchRequest.filters}
                isMatching={isMatching}
                resultCount={totalMatchCount}
                showKeywordSearch={false}
                showSuggestions={config("features.jobToCandidates.keywordSuggestions", false)}
                sortMode={matchRequest.sortMode}
                sortModeGroup={config("features.jobToCandidates.sortModeGroup", undefined)}
                profileLabel={intl.formatMessage({id: "match.jobProfile"})}
                resultsLabel={intl.formatMessage({id: "match.candidateResults"})}
                onMatchProfileChange={this.handleProfileChange}
                onFiltersChange={this.handleFiltersChange}
                onMatch={this.handleMatch}
                onClear={this.handleClear}
                onReset={this.handleReset}
                onSortModeChange={this.handleSortModeChange}
                onLanguageChange={updateLanguage}
                onMatchingStrategyChange={updateMatchingStrategy}
                ref={this.matchPageRef}
            >
                {this.renderResults()}
            </HorizontalMatchPageLayout>
        );
    }

    renderResults() {
        const {
            selection,
            lastMatchRequest,
            displayedMatches,
            isMatching,
            updateSelection,
        } = this.props;
        const {matchClicked} = this.state;
        const showMatchButton = config("features.profileToCandidates.showMatchButton", false);

        return (
            <div className={styles.resultsWrapper}>
                <CandidateMatchList
                    ref={this.matchListRef}
                    className={styles.matchList}
                    matches={displayedMatches}
                    allowSelection={config("features.jobToCandidates.allowComparison", false)}
                    wasSearched={matchClicked && !isMatching}
                    selection={selection}
                    extraColumns={config("features.jobToCandidates.extraColumns", [])}
                    noResultsLabel={
                        !hasEnabledFilters(lastMatchRequest.filters)
                            ? "match.noResults"
                            : "match.noResultsFiltersHint"
                    }
                    isGreenMatch={this.isGreenMatch}
                    onView={this.handleView}
                    onMatch={showMatchButton ? this.handleMatchToOthers : undefined}
                    onSelectionChange={updateSelection}
                />
                {this.renderPaginationBar()}
                {this.renderDetailsDialog()}
                {this.renderComparisonDialog()}
            </div>
        );
    }

    renderPaginationBar() {
        const {displayedPage, selection, pageSize, totalMatchCount} = this.props;

        const allowComparison = config("features.jobToCandidates.allowComparison", false);
        const selectionSize = Object.keys(selection).length;

        return (
            <MatchResultsPaginationBar
                page={displayedPage}
                pageSize={pageSize}
                totalItems={totalMatchCount || 0}
                selectionSize={selectionSize}
                canCompare={allowComparison}
                fakeButtons={config("features.jobToCandidates.fakeButtons", [])}
                selectLabel="temporary.selectCandidates"
                compareLabel="compareCandidates"
                onPageChange={this.handlePageChange}
                onDownload={this.handleDownload}
                onSelect={this.handleSelection}
                onCompare={this.handleCompare}
            />
        );
    }

    renderDetailsDialog = () => {
        const {intl} = this.props;
        const {displayedMatch} = this.state;

        if (!displayedMatch) {
            return null;
        }

        const reverseLabel = getDetailsTitleAsConfigured(displayedMatch);

        return (
            <MatchDetailsDialog
                match={displayedMatch}
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.jobToCandidates.extraProperties"
                )}
                getMatchDisplayValue={this.getMatchDisplayValue}
                onHide={this.handleHideDetailsDialog}
                title={intl.formatMessage(
                    {id: "detailsModal.title"},
                    {subject: getMatchDetailsTitleSubject(displayedMatch)}
                )}
                forwardLabel={intl.formatMessage({id: "matchQuality.searchProfile"})}
                reverseLabel={
                    reverseLabel || intl.formatMessage({id: "matchQuality.candidateColumn"})
                }
            />
        );
    };

    renderComparisonDialog = () => {
        const {comparedMatches} = this.state;

        if (!comparedMatches) {
            return null;
        }

        return (
            <MatchComparisonDialog
                matches={comparedMatches}
                titleLabel="compareCandidates"
                reverseLabel="reverse.candidateToProfile"
                extraPropertiesBlackWhiteList={BlackWhiteList.fromConfig(
                    "features.jobToCandidates.extraProperties"
                )}
                getMatchDisplayValue={this.getMatchDisplayValue}
                onHide={this.handleHideComparisonDialog}
            />
        );
    };

    isGreenMatch = match => {
        const {lastMatchRequest, job} = this.props;
        return isGreenMatch(match, {...lastMatchRequest, matchProfile: job.matchProfile});
    };

    getMatchDisplayValue = (match, section) => {
        if (section === "additionalText") {
            // const {jobText} = this.state;
            //
            // return jobText
            //     ? {
            //           label: "detailsModal.jobText",
            //           value: jobText,
            //       }
            //     : undefined;
            return undefined;
        } else if (section === "header") {
            return getMatchDetailsTitleSubject(match);
        } else {
            return getCandidateMatchDisplayValue(match, section);
        }
    };

    handleMatch = () => {
        const {setDisplayedPage, requestPage, clearSelection} = this.props;

        requestPage(0, true).then(() => {
            setDisplayedPage(0);
        });

        this.setState({matchClicked: true});
        clearSelection();
        this.matchPageRef.current.openResultsTab();
    };

    handleClear = () => {
        this.props.clearMatchProfile();
        this.props.clearFilters();
        this.matchPageRef.current.resetMatchProfileEditor();
        this.matchPageRef.current.openMatchProfileTab();
    };

    handleProfileChange = matchProfile => {
        const {job, updateJob} = this.props;

        updateJob({
            ...job,
            matchProfile,
        });
    };

    handleFiltersChange = filters => {
        this.props.updateFilters(filters);
    };

    handleSortModeChange = nextSortMode => {
        const {totalMatchCount} = this.props;
        this.props.updateSortMode(nextSortMode);
        if (totalMatchCount !== null) this.handleMatch();
    };

    handleView = id => {
        const {matches} = this.props;
        this.setState({displayedMatch: matches[id]});
    };

    handleMatchToOthers = (type, id) => {
        const {history} = this.props;

        switch (type) {
            case "jobs":
                history.push(`/profile-to-jobs/candidate/${id}`);
                break;

            case "candidates":
                history.push(`/profile-to-candidates/candidate/${id}`);
                break;

            default:
                return;
        }
    };

    handlePageChange = page => {
        const {setDisplayedPage, requestPage} = this.props;

        requestPage(page).then(() => {
            setDisplayedPage(page);
        });

        this.resultsWrapper.current.parentNode.scrollTop = 0;
    };

    handleHideDetailsDialog = () => {
        this.setState({displayedMatch: null});
    };

    handleHideComparisonDialog = () => {
        this.setState({comparedMatches: null});
    };

    handleCompare = () => {
        const {selection, matches} = this.props;

        this.setState({
            comparedMatches: Object.keys(selection)
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score),
        });
    };
}

export function getMatchDetailsTitleSubject(match) {
    let titleSubject = getJobFunctionLabel(match);
    return getDetailsTitleAsConfigured(match.candidate) || titleSubject;
}

export function getDetailsTitleAsConfigured(candidate) {
    const detailsTitleProperty = config(
        "features.jobToCandidates.detailsTitleProperty",
        undefined
    );

    if (detailsTitleProperty) {
        return objectPath.get(candidate, detailsTitleProperty);
    } else {
        return undefined;
    }
}

export default injectIntl(JobToCandidatesMatch, {forwardRef: true});
