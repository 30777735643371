import {UPDATE_CANDIDATE, ADD_RESULTS, SET_PAGE} from "~/actions/candidates";

const INITIAL_STATE = {
    all: {},
    order: [],
    page: 0,
    pageSize: 20,
    count: null,
    isFetching: false,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_CANDIDATE:
            return updateCandidate(state, action.id, action.candidate);

        case ADD_RESULTS:
            return addResults(state, action.count, action.candidates);

        case SET_PAGE:
            return setPage(state, action.page);

        default:
            return state;
    }
}

function updateCandidate(state, id, candidate) {
    const nextOrder = [...state.order];

    if (!nextOrder.includes(id)) {
        nextOrder.push(id);
    }

    return {
        ...state,
        all: {
            ...state.all,
            [id]: candidate,
        },
        order: nextOrder,
    };
}

function addResults(state, count, candidates) {
    const nextAll = {...state.all};
    const nextOrder = [];

    for (const candidate of candidates) {
        nextAll[candidate.id] = candidate;

        if (!nextOrder.includes(candidate.id)) {
            nextOrder.push(candidate.id);
        }
    }

    return {
        ...state,
        all: nextAll,
        order: nextOrder,
        count,
    };
}

function setPage(state, page) {
    return {...state, page};
}
