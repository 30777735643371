import Api from "~/api";
import config from "~/config";
import applyJobParseResults from "~/util/apply-job-parse-results";
import {
    initializeResultSet,
    invalidateAllResultSetPages,
    requestResultSetPage,
} from "~/actions/match-results";
import {INITIAL_MATCH_PROFILE, INITIAL_FILTERS} from "~/reducers/job-to-candidates";

export const UPDATE_JOB = "j2c-match/UPDATE_JOB";
export const UPDATE_JOB_PARSE_STATE = "j2c-match/UPDATE_JOB_PARSE_STATE";
export const UPDATE_FILTERS = "j2c-match/UPDATE_FILTERS";
export const UPDATE_SORT_MODE = "j2c-match/UPDATE_SORT_MODE";
export const SET_DISPLAYED_PAGE = "j2c-match/SET_DISPLAYED_PAGE";
export const SET_FURTHEST_STEP = "j2c-match/SET_FURTHEST_STEP";
export const UPDATE_MATCHING_STRATEGY = "j2c-match/UPDATE_MATCHING_STRATEGY";
export const UPDATE_SELECTION = "j2c-match/UPDATE_SELECTION";
export const SET_LAST_MATCH_REQUEST = "j2c-match/SET_LAST_MATCH_REQUEST";

export function initialize() {
    return initializeResultSet(
        "jobToCandidates",
        (getState, page, isNewQuery, dispatch) => {
            const matchRequest = {
                ...getState().jobToCandidates.matchRequest,
                matchProfile: getState().jobToCandidates.job.matchProfile,
            };

            const lastMatchRequest = getState().jobToCandidates.lastMatchRequest;
            const size = getState().jobToCandidates.pageSize;
            const matchingStrategy = getState().jobToCandidates.matchingStrategy;
            const from = page * size;
            const language = getState().ui.language;
            const candidateIndex = config("features.jobToCandidates.candidateIndex");
            const sortModeGroup = config("features.jobToCandidates.sortModeGroup", undefined);
            const scoreType = config("features.jobToCandidates.scoreType", "MUTUAL");
            const expansionType = config("features.jobToCandidates.expansionType", "FULL");

            if (isNewQuery) {
                dispatch(setLastMatchRequest(matchRequest));
            }

            return Api.matchProfileToCandidates({
                candidateIndex,
                matchRequest: isNewQuery ? matchRequest : lastMatchRequest,
                from,
                size,
                language,
                sortModeGroup,
                scoreType,
                expansionType,
                matchingStrategy,
            });
        },
        ({matches, metadata}) => {
            const matchesById = {};
            const ids = [];

            for (const match of matches) {
                matchesById[match.id] = match;
                ids.push(match.id);
            }

            return {
                matches: matchesById,
                ids,
                count: metadata.count,
            };
        }
    );
}

export function parseJob(job) {
    return dispatch => {
        dispatch(updateJobParseState(config("enums.apiCallState.pending")));

        return Api.parseJob(job)
            .then(({response}) => {
                dispatch(
                    updateJob({
                        ...job,
                        matchProfile: applyJobParseResults(
                            job.matchProfile,
                            response.parsingAspects
                        ),
                    })
                );
                dispatch(updateJobParseState(config("enums.apiCallState.idle")));
            })
            .catch(e => {
                console.error(e);
                dispatch(updateJobParseState(config("enums.apiCallState.idle")));
            });
    };
}

export function updateJob(job) {
    return {
        type: UPDATE_JOB,
        job,
    };
}

export function clearMatchProfile() {
    return (dispatch, getState) => {
        dispatch(
            updateJob({
                ...getState().jobToCandidates.job,
                matchProfile: INITIAL_MATCH_PROFILE,
            })
        );
    };
}

export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        filters,
    };
}

export function clearFilters() {
    return updateFilters(INITIAL_FILTERS);
}

function updateJobParseState(state) {
    return {
        type: UPDATE_JOB_PARSE_STATE,
        jobParseState: state,
    };
}

export function setDisplayedPage(displayedPage) {
    return {
        type: SET_DISPLAYED_PAGE,
        displayedPage,
    };
}

export function requestPage(page, isNewQuery = false) {
    return requestResultSetPage("jobToCandidates", page, isNewQuery);
}

export function invalidateAllPages() {
    return invalidateAllResultSetPages("jobToCandidates");
}

export function setFurthestStep(furthestStep) {
    return {
        type: SET_FURTHEST_STEP,
        furthestStep,
    };
}

export function updateSortMode(sortMode) {
    return {
        type: UPDATE_SORT_MODE,
        sortMode,
    };
}

export function updateSelection(selection) {
    return {
        type: UPDATE_SELECTION,
        selection,
    };
}

export function clearSelection() {
    return updateSelection({});
}

export function setLastMatchRequest(matchRequest) {
    return {
        type: SET_LAST_MATCH_REQUEST,
        matchRequest,
    };
}

export function updateMatchingStrategy(matchingStrategy) {
    return {
        type: UPDATE_MATCHING_STRATEGY,
        matchingStrategy,
    };
}
